@charset "UTF-8";
/**********************************************************/
/*	Visual Formatting                                     */
/**********************************************************/
/** position Emmet abbreviation: pos */
.x-pos-a { position: absolute !important; }

.x-pos-f { position: fixed !important; }

.x-pos-r { position: relative !important; }

.x-pos-s { position: static !important; }

/** top Emmet abbreviation: t */
.x-t-a { top: auto !important; }

.x-t-0 { top: 0px !important; }

.x-t-1 { top: 1px !important; }

.x-t-2 { top: 2px !important; }

.x-t-3 { top: 3px !important; }

.x-t-4 { top: 4px !important; }

.x-t-5 { top: 5px !important; }

.x-t-6 { top: 6px !important; }

.x-t-7 { top: 7px !important; }

.x-t-8 { top: 8px !important; }

.x-t-9 { top: 9px !important; }

.x-t-10 { top: 10px !important; }

.x-t-11 { top: 11px !important; }

.x-t-12 { top: 12px !important; }

.x-t-13 { top: 13px !important; }

.x-t-14 { top: 14px !important; }

.x-t-15 { top: 15px !important; }

.x-t-16 { top: 16px !important; }

.x-t-17 { top: 17px !important; }

.x-t-18 { top: 18px !important; }

.x-t-19 { top: 19px !important; }

.x-t-20 { top: 20px !important; }

.x-t-21 { top: 21px !important; }

.x-t-22 { top: 22px !important; }

.x-t-23 { top: 23px !important; }

.x-t-24 { top: 24px !important; }

.x-t-25 { top: 25px !important; }

.x-t-26 { top: 26px !important; }

.x-t-27 { top: 27px !important; }

.x-t-28 { top: 28px !important; }

.x-t-29 { top: 29px !important; }

.x-t-30 { top: 30px !important; }

.x-t-31 { top: 31px !important; }

.x-t-32 { top: 32px !important; }

.x-t-33 { top: 33px !important; }

.x-t-34 { top: 34px !important; }

.x-t-35 { top: 35px !important; }

.x-t-36 { top: 36px !important; }

.x-t-37 { top: 37px !important; }

.x-t-38 { top: 38px !important; }

.x-t-39 { top: 39px !important; }

.x-t-40 { top: 40px !important; }

.x-t-41 { top: 41px !important; }

.x-t-42 { top: 42px !important; }

.x-t-43 { top: 43px !important; }

.x-t-44 { top: 44px !important; }

.x-t-45 { top: 45px !important; }

.x-t-46 { top: 46px !important; }

.x-t-47 { top: 47px !important; }

.x-t-48 { top: 48px !important; }

.x-t-49 { top: 49px !important; }

.x-t-50 { top: 50px !important; }

.x-t-55 { top: 55px !important; }

.x-t-60 { top: 60px !important; }

.x-t-65 { top: 65px !important; }

.x-t-70 { top: 70px !important; }

.x-t-75 { top: 75px !important; }

.x-t-80 { top: 80px !important; }

.x-t-85 { top: 85px !important; }

.x-t-90 { top: 90px !important; }

.x-t-95 { top: 95px !important; }

.x-t-100 { top: 100px !important; }

.x-t-105 { top: 105px !important; }

.x-t-110 { top: 110px !important; }

.x-t-115 { top: 115px !important; }

.x-t-120 { top: 120px !important; }

.x-t-125 { top: 125px !important; }

.x-t-130 { top: 130px !important; }

.x-t-135 { top: 135px !important; }

.x-t-140 { top: 140px !important; }

.x-t-145 { top: 145px !important; }

.x-t-150 { top: 150px !important; }

.x-t-155 { top: 155px !important; }

.x-t-160 { top: 160px !important; }

.x-t-165 { top: 165px !important; }

.x-t-170 { top: 170px !important; }

.x-t-175 { top: 175px !important; }

.x-t-180 { top: 180px !important; }

.x-t-185 { top: 185px !important; }

.x-t-190 { top: 190px !important; }

.x-t-195 { top: 195px !important; }

.x-t-200 { top: 200px !important; }

.x-t-205 { top: 205px !important; }

.x-t-210 { top: 210px !important; }

.x-t-215 { top: 215px !important; }

.x-t-220 { top: 220px !important; }

.x-t-225 { top: 225px !important; }

.x-t-230 { top: 230px !important; }

.x-t-235 { top: 235px !important; }

.x-t-240 { top: 240px !important; }

.x-t-245 { top: 245px !important; }

.x-t-250 { top: 250px !important; }

/** bottom Emmet abbreviation: b */
.x-b-a { bottom: auto !important; }

.x-b-0 { bottom: 0px !important; }

.x-b-1 { bottom: 1px !important; }

.x-b-2 { bottom: 2px !important; }

.x-b-3 { bottom: 3px !important; }

.x-b-4 { bottom: 4px !important; }

.x-b-5 { bottom: 5px !important; }

.x-b-6 { bottom: 6px !important; }

.x-b-7 { bottom: 7px !important; }

.x-b-8 { bottom: 8px !important; }

.x-b-9 { bottom: 9px !important; }

.x-b-10 { bottom: 10px !important; }

.x-b-11 { bottom: 11px !important; }

.x-b-12 { bottom: 12px !important; }

.x-b-13 { bottom: 13px !important; }

.x-b-14 { bottom: 14px !important; }

.x-b-15 { bottom: 15px !important; }

.x-b-16 { bottom: 16px !important; }

.x-b-17 { bottom: 17px !important; }

.x-b-18 { bottom: 18px !important; }

.x-b-19 { bottom: 19px !important; }

.x-b-20 { bottom: 20px !important; }

.x-b-21 { bottom: 21px !important; }

.x-b-22 { bottom: 22px !important; }

.x-b-23 { bottom: 23px !important; }

.x-b-24 { bottom: 24px !important; }

.x-b-25 { bottom: 25px !important; }

.x-b-26 { bottom: 26px !important; }

.x-b-27 { bottom: 27px !important; }

.x-b-28 { bottom: 28px !important; }

.x-b-29 { bottom: 29px !important; }

.x-b-30 { bottom: 30px !important; }

.x-b-31 { bottom: 31px !important; }

.x-b-32 { bottom: 32px !important; }

.x-b-33 { bottom: 33px !important; }

.x-b-34 { bottom: 34px !important; }

.x-b-35 { bottom: 35px !important; }

.x-b-36 { bottom: 36px !important; }

.x-b-37 { bottom: 37px !important; }

.x-b-38 { bottom: 38px !important; }

.x-b-39 { bottom: 39px !important; }

.x-b-40 { bottom: 40px !important; }

.x-b-41 { bottom: 41px !important; }

.x-b-42 { bottom: 42px !important; }

.x-b-43 { bottom: 43px !important; }

.x-b-44 { bottom: 44px !important; }

.x-b-45 { bottom: 45px !important; }

.x-b-46 { bottom: 46px !important; }

.x-b-47 { bottom: 47px !important; }

.x-b-48 { bottom: 48px !important; }

.x-b-49 { bottom: 49px !important; }

.x-b-50 { bottom: 50px !important; }

.x-b-55 { bottom: 55px !important; }

.x-b-60 { bottom: 60px !important; }

.x-b-65 { bottom: 65px !important; }

.x-b-70 { bottom: 70px !important; }

.x-b-75 { bottom: 75px !important; }

.x-b-80 { bottom: 80px !important; }

.x-b-85 { bottom: 85px !important; }

.x-b-90 { bottom: 90px !important; }

.x-b-95 { bottom: 95px !important; }

.x-b-100 { bottom: 100px !important; }

.x-b-105 { bottom: 105px !important; }

.x-b-110 { bottom: 110px !important; }

.x-b-115 { bottom: 115px !important; }

.x-b-120 { bottom: 120px !important; }

.x-b-125 { bottom: 125px !important; }

.x-b-130 { bottom: 130px !important; }

.x-b-135 { bottom: 135px !important; }

.x-b-140 { bottom: 140px !important; }

.x-b-145 { bottom: 145px !important; }

.x-b-150 { bottom: 150px !important; }

.x-b-155 { bottom: 155px !important; }

.x-b-160 { bottom: 160px !important; }

.x-b-165 { bottom: 165px !important; }

.x-b-170 { bottom: 170px !important; }

.x-b-175 { bottom: 175px !important; }

.x-b-180 { bottom: 180px !important; }

.x-b-185 { bottom: 185px !important; }

.x-b-190 { bottom: 190px !important; }

.x-b-195 { bottom: 195px !important; }

.x-b-200 { bottom: 200px !important; }

.x-b-205 { bottom: 205px !important; }

.x-b-210 { bottom: 210px !important; }

.x-b-215 { bottom: 215px !important; }

.x-b-220 { bottom: 220px !important; }

.x-b-225 { bottom: 225px !important; }

.x-b-230 { bottom: 230px !important; }

.x-b-235 { bottom: 235px !important; }

.x-b-240 { bottom: 240px !important; }

.x-b-245 { bottom: 245px !important; }

.x-b-250 { bottom: 250px !important; }

/** left Emmet abbreviation: l */
.x-l-a { left: auto !important; }

.x-l-0 { left: 0px !important; }

.x-l-1 { left: 1px !important; }

.x-l-2 { left: 2px !important; }

.x-l-3 { left: 3px !important; }

.x-l-4 { left: 4px !important; }

.x-l-5 { left: 5px !important; }

.x-l-6 { left: 6px !important; }

.x-l-7 { left: 7px !important; }

.x-l-8 { left: 8px !important; }

.x-l-9 { left: 9px !important; }

.x-l-10 { left: 10px !important; }

.x-l-11 { left: 11px !important; }

.x-l-12 { left: 12px !important; }

.x-l-13 { left: 13px !important; }

.x-l-14 { left: 14px !important; }

.x-l-15 { left: 15px !important; }

.x-l-16 { left: 16px !important; }

.x-l-17 { left: 17px !important; }

.x-l-18 { left: 18px !important; }

.x-l-19 { left: 19px !important; }

.x-l-20 { left: 20px !important; }

.x-l-21 { left: 21px !important; }

.x-l-22 { left: 22px !important; }

.x-l-23 { left: 23px !important; }

.x-l-24 { left: 24px !important; }

.x-l-25 { left: 25px !important; }

.x-l-26 { left: 26px !important; }

.x-l-27 { left: 27px !important; }

.x-l-28 { left: 28px !important; }

.x-l-29 { left: 29px !important; }

.x-l-30 { left: 30px !important; }

.x-l-31 { left: 31px !important; }

.x-l-32 { left: 32px !important; }

.x-l-33 { left: 33px !important; }

.x-l-34 { left: 34px !important; }

.x-l-35 { left: 35px !important; }

.x-l-36 { left: 36px !important; }

.x-l-37 { left: 37px !important; }

.x-l-38 { left: 38px !important; }

.x-l-39 { left: 39px !important; }

.x-l-40 { left: 40px !important; }

.x-l-41 { left: 41px !important; }

.x-l-42 { left: 42px !important; }

.x-l-43 { left: 43px !important; }

.x-l-44 { left: 44px !important; }

.x-l-45 { left: 45px !important; }

.x-l-46 { left: 46px !important; }

.x-l-47 { left: 47px !important; }

.x-l-48 { left: 48px !important; }

.x-l-49 { left: 49px !important; }

.x-l-50 { left: 50px !important; }

.x-l-55 { left: 55px !important; }

.x-l-60 { left: 60px !important; }

.x-l-65 { left: 65px !important; }

.x-l-70 { left: 70px !important; }

.x-l-75 { left: 75px !important; }

.x-l-80 { left: 80px !important; }

.x-l-85 { left: 85px !important; }

.x-l-90 { left: 90px !important; }

.x-l-95 { left: 95px !important; }

.x-l-100 { left: 100px !important; }

.x-l-105 { left: 105px !important; }

.x-l-110 { left: 110px !important; }

.x-l-115 { left: 115px !important; }

.x-l-120 { left: 120px !important; }

.x-l-125 { left: 125px !important; }

.x-l-130 { left: 130px !important; }

.x-l-135 { left: 135px !important; }

.x-l-140 { left: 140px !important; }

.x-l-145 { left: 145px !important; }

.x-l-150 { left: 150px !important; }

.x-l-155 { left: 155px !important; }

.x-l-160 { left: 160px !important; }

.x-l-165 { left: 165px !important; }

.x-l-170 { left: 170px !important; }

.x-l-175 { left: 175px !important; }

.x-l-180 { left: 180px !important; }

.x-l-185 { left: 185px !important; }

.x-l-190 { left: 190px !important; }

.x-l-195 { left: 195px !important; }

.x-l-200 { left: 200px !important; }

.x-l-205 { left: 205px !important; }

.x-l-210 { left: 210px !important; }

.x-l-215 { left: 215px !important; }

.x-l-220 { left: 220px !important; }

.x-l-225 { left: 225px !important; }

.x-l-230 { left: 230px !important; }

.x-l-235 { left: 235px !important; }

.x-l-240 { left: 240px !important; }

.x-l-245 { left: 245px !important; }

.x-l-250 { left: 250px !important; }

/** right Emmet abbreviation: r */
.x-r-a { right: auto !important; }

.x-r-0 { right: 0px !important; }

.x-r-1 { right: 1px !important; }

.x-r-2 { right: 2px !important; }

.x-r-3 { right: 3px !important; }

.x-r-4 { right: 4px !important; }

.x-r-5 { right: 5px !important; }

.x-r-6 { right: 6px !important; }

.x-r-7 { right: 7px !important; }

.x-r-8 { right: 8px !important; }

.x-r-9 { right: 9px !important; }

.x-r-10 { right: 10px !important; }

.x-r-11 { right: 11px !important; }

.x-r-12 { right: 12px !important; }

.x-r-13 { right: 13px !important; }

.x-r-14 { right: 14px !important; }

.x-r-15 { right: 15px !important; }

.x-r-16 { right: 16px !important; }

.x-r-17 { right: 17px !important; }

.x-r-18 { right: 18px !important; }

.x-r-19 { right: 19px !important; }

.x-r-20 { right: 20px !important; }

.x-r-21 { right: 21px !important; }

.x-r-22 { right: 22px !important; }

.x-r-23 { right: 23px !important; }

.x-r-24 { right: 24px !important; }

.x-r-25 { right: 25px !important; }

.x-r-26 { right: 26px !important; }

.x-r-27 { right: 27px !important; }

.x-r-28 { right: 28px !important; }

.x-r-29 { right: 29px !important; }

.x-r-30 { right: 30px !important; }

.x-r-31 { right: 31px !important; }

.x-r-32 { right: 32px !important; }

.x-r-33 { right: 33px !important; }

.x-r-34 { right: 34px !important; }

.x-r-35 { right: 35px !important; }

.x-r-36 { right: 36px !important; }

.x-r-37 { right: 37px !important; }

.x-r-38 { right: 38px !important; }

.x-r-39 { right: 39px !important; }

.x-r-40 { right: 40px !important; }

.x-r-41 { right: 41px !important; }

.x-r-42 { right: 42px !important; }

.x-r-43 { right: 43px !important; }

.x-r-44 { right: 44px !important; }

.x-r-45 { right: 45px !important; }

.x-r-46 { right: 46px !important; }

.x-r-47 { right: 47px !important; }

.x-r-48 { right: 48px !important; }

.x-r-49 { right: 49px !important; }

.x-r-50 { right: 50px !important; }

.x-r-55 { right: 55px !important; }

.x-r-60 { right: 60px !important; }

.x-r-65 { right: 65px !important; }

.x-r-70 { right: 70px !important; }

.x-r-75 { right: 75px !important; }

.x-r-80 { right: 80px !important; }

.x-r-85 { right: 85px !important; }

.x-r-90 { right: 90px !important; }

.x-r-95 { right: 95px !important; }

.x-r-100 { right: 100px !important; }

.x-r-105 { right: 105px !important; }

.x-r-110 { right: 110px !important; }

.x-r-115 { right: 115px !important; }

.x-r-120 { right: 120px !important; }

.x-r-125 { right: 125px !important; }

.x-r-130 { right: 130px !important; }

.x-r-135 { right: 135px !important; }

.x-r-140 { right: 140px !important; }

.x-r-145 { right: 145px !important; }

.x-r-150 { right: 150px !important; }

.x-r-155 { right: 155px !important; }

.x-r-160 { right: 160px !important; }

.x-r-165 { right: 165px !important; }

.x-r-170 { right: 170px !important; }

.x-r-175 { right: 175px !important; }

.x-r-180 { right: 180px !important; }

.x-r-185 { right: 185px !important; }

.x-r-190 { right: 190px !important; }

.x-r-195 { right: 195px !important; }

.x-r-200 { right: 200px !important; }

.x-r-205 { right: 205px !important; }

.x-r-210 { right: 210px !important; }

.x-r-215 { right: 215px !important; }

.x-r-220 { right: 220px !important; }

.x-r-225 { right: 225px !important; }

.x-r-230 { right: 230px !important; }

.x-r-235 { right: 235px !important; }

.x-r-240 { right: 240px !important; }

.x-r-245 { right: 245px !important; }

.x-r-250 { right: 250px !important; }

/** z-index Emmet abbreviation: z */
.x-z-a { z-index: auto !important; }

.x-z-0 { z-index: 0 !important; }

.x-z-10 { z-index: 10 !important; }

.x-z-20 { z-index: 20 !important; }

.x-z-30 { z-index: 30 !important; }

.x-z-40 { z-index: 40 !important; }

.x-z-50 { z-index: 50 !important; }

.x-z-60 { z-index: 60 !important; }

.x-z-70 { z-index: 70 !important; }

.x-z-80 { z-index: 80 !important; }

.x-z-90 { z-index: 90 !important; }

.x-z-100 { z-index: 100 !important; }

.x-z-110 { z-index: 110 !important; }

.x-z-120 { z-index: 120 !important; }

.x-z-130 { z-index: 130 !important; }

.x-z-140 { z-index: 140 !important; }

.x-z-150 { z-index: 150 !important; }

.x-z-160 { z-index: 160 !important; }

.x-z-170 { z-index: 170 !important; }

.x-z-180 { z-index: 180 !important; }

.x-z-190 { z-index: 190 !important; }

.x-z-200 { z-index: 200 !important; }

.x-z-210 { z-index: 210 !important; }

.x-z-220 { z-index: 220 !important; }

.x-z-230 { z-index: 230 !important; }

.x-z-240 { z-index: 240 !important; }

.x-z-250 { z-index: 250 !important; }

.x-z-260 { z-index: 260 !important; }

.x-z-270 { z-index: 270 !important; }

.x-z-280 { z-index: 280 !important; }

.x-z-290 { z-index: 290 !important; }

.x-z-300 { z-index: 300 !important; }

.x-z-310 { z-index: 310 !important; }

.x-z-320 { z-index: 320 !important; }

.x-z-330 { z-index: 330 !important; }

.x-z-340 { z-index: 340 !important; }

.x-z-350 { z-index: 350 !important; }

.x-z-360 { z-index: 360 !important; }

.x-z-370 { z-index: 370 !important; }

.x-z-380 { z-index: 380 !important; }

.x-z-390 { z-index: 390 !important; }

.x-z-400 { z-index: 400 !important; }

.x-z-410 { z-index: 410 !important; }

.x-z-420 { z-index: 420 !important; }

.x-z-430 { z-index: 430 !important; }

.x-z-440 { z-index: 440 !important; }

.x-z-450 { z-index: 450 !important; }

.x-z-460 { z-index: 460 !important; }

.x-z-470 { z-index: 470 !important; }

.x-z-480 { z-index: 480 !important; }

.x-z-490 { z-index: 490 !important; }

.x-z-500 { z-index: 500 !important; }

/** float Emmet abbreviation: fl */
.x-fl-l { float: left !important; }

.x-fl-r { float: right !important; }

.x-fl-n { float: none !important; }

/** clear Emmet abbreviation: cl */
.x-cl-l { clear: left !important; }

.x-cl-r { clear: right !important; }

.x-cl-b { clear: both !important; }

.x-cl-n { clear: none !important; }

/** display Emmet abbreviation: d */
.x-d-n { display: none !important; }

.x-d-b { display: block !important; }

.x-d-f { display: flex !important; }

.x-d-if { display: inline-flex !important; }

.x-d-i { display: inline !important; }

.x-d-ib { display: inline-block !important; }

.x-d-li { display: list-item !important; }

.x-d-ri { display: run-in !important; }

.x-d-cp { display: compact !important; }

.x-d-tb { display: table !important; }

.x-d-itb { display: inline-table !important; }

.x-d-tbcp { display: table-caption !important; }

.x-d-tbcl { display: table-column !important; }

.x-d-tbclg { display: table-column-group !important; }

.x-d-tbhg { display: table-header-group !important; }

.x-d-tbfg { display: table-footer-group !important; }

.x-d-tbr { display: table-row !important; }

.x-d-tbrg { display: table-row-group !important; }

.x-d-tbc { display: table-cell !important; }

.x-d-rb { display: ruby !important; }

.x-d-rbb { display: ruby-base !important; }

.x-d-rbbg { display: ruby-base-group !important; }

.x-d-rbt { display: ruby-text !important; }

.x-d-rbtg { display: ruby-text-group !important; }

/** visibility Emmet abbreviation: v */
.x-v-v { visibility: visible !important; }

.x-v-h { visibility: hidden !important; }

.x-v-c { visibility: collapse !important; }

/** overflow Emmet abbreviation: ov */
.x-ov-v { overflow: visible !important; }

.x-ov-h { overflow: hidden !important; }

.x-ov-s { overflow: scroll !important; }

.x-ov-a { overflow: auto !important; }

/** overflow-x Emmet abbreviation: ovx */
.x-ovx-v { overflow-x: visible !important; }

.x-ovx-h { overflow-x: hidden !important; }

.x-ovx-s { overflow-x: scroll !important; }

.x-ovx-a { overflow-x: auto !important; }

/** overflow-y Emmet abbreviation: ovy */
.x-ovy-v { overflow-y: visible !important; }

.x-ovy-h { overflow-y: hidden !important; }

.x-ovy-s { overflow-y: scroll !important; }

.x-ovy-a { overflow-y: auto !important; }

/** overflow-style Emmet abbreviation: ovs */
.x-ovs-a { overflow-style: auto !important; }

.x-ovs-s { overflow-style: scrollbar !important; }

.x-ovs-p { overflow-style: panner !important; }

.x-ovs-m { overflow-style: move !important; }

.x-ovs-mq { overflow-style: marquee !important; }

/** zoom Emmet abbreviation: zoo, zm */
.x-zoo-0 { zoom: 0 !important; }

.x-zoo-1 { zoom: 1 !important; }

.x-zoo-2 { zoom: 2 !important; }

.x-zoo-3 { zoom: 3 !important; }

.x-zoo-4 { zoom: 4 !important; }

.x-zoo-5 { zoom: 5 !important; }

.x-zoo-6 { zoom: 6 !important; }

.x-zoo-7 { zoom: 7 !important; }

.x-zoo-8 { zoom: 8 !important; }

.x-zoo-9 { zoom: 9 !important; }

.x-zoo-10 { zoom: 10 !important; }

.x-zm-0 { zoom: 0 !important; }

.x-zm-1 { zoom: 1 !important; }

.x-zm-2 { zoom: 2 !important; }

.x-zm-3 { zoom: 3 !important; }

.x-zm-4 { zoom: 4 !important; }

.x-zm-5 { zoom: 5 !important; }

.x-zm-6 { zoom: 6 !important; }

.x-zm-7 { zoom: 7 !important; }

.x-zm-8 { zoom: 8 !important; }

.x-zm-9 { zoom: 9 !important; }

.x-zm-10 { zoom: 10 !important; }

/** clip Emmet abbreviation: cp */
.x-cp-a { clip: auto !important; }

/** resize Emmet abbreviation: rsz */
.x-rsz-n { resize: none !important; }

.x-rsz-b { resize: both !important; }

.x-rsz-h { resize: horizontal !important; }

.x-rsz-v { resize: vertical !important; }

/** cursor Emmet abbreviation: cur */
.x-cur-a { cursor: auto !important; }

.x-cur-d { cursor: default !important; }

.x-cur-c { cursor: crosshair !important; }

.x-cur-ha { cursor: hand !important; }

.x-cur-he { cursor: help !important; }

.x-cur-m { cursor: move !important; }

.x-cur-p { cursor: pointer !important; }

.x-cur-t { cursor: text !important; }

/**********************************************************/
/*	Margin & Padding                                      */
/**********************************************************/
/** margin Emmet abbreviation: m */
.x-m-a { margin: auto !important; }

.x-m-0 { margin: 0px !important; }

.x-m-1 { margin: 1px !important; }

.x-m-2 { margin: 2px !important; }

.x-m-3 { margin: 3px !important; }

.x-m-4 { margin: 4px !important; }

.x-m-5 { margin: 5px !important; }

.x-m-6 { margin: 6px !important; }

.x-m-7 { margin: 7px !important; }

.x-m-8 { margin: 8px !important; }

.x-m-9 { margin: 9px !important; }

.x-m-10 { margin: 10px !important; }

.x-m-11 { margin: 11px !important; }

.x-m-12 { margin: 12px !important; }

.x-m-13 { margin: 13px !important; }

.x-m-14 { margin: 14px !important; }

.x-m-15 { margin: 15px !important; }

.x-m-16 { margin: 16px !important; }

.x-m-17 { margin: 17px !important; }

.x-m-18 { margin: 18px !important; }

.x-m-19 { margin: 19px !important; }

.x-m-20 { margin: 20px !important; }

.x-m-21 { margin: 21px !important; }

.x-m-22 { margin: 22px !important; }

.x-m-23 { margin: 23px !important; }

.x-m-24 { margin: 24px !important; }

.x-m-25 { margin: 25px !important; }

.x-m-26 { margin: 26px !important; }

.x-m-27 { margin: 27px !important; }

.x-m-28 { margin: 28px !important; }

.x-m-29 { margin: 29px !important; }

.x-m-30 { margin: 30px !important; }

.x-m-31 { margin: 31px !important; }

.x-m-32 { margin: 32px !important; }

.x-m-33 { margin: 33px !important; }

.x-m-34 { margin: 34px !important; }

.x-m-35 { margin: 35px !important; }

.x-m-36 { margin: 36px !important; }

.x-m-37 { margin: 37px !important; }

.x-m-38 { margin: 38px !important; }

.x-m-39 { margin: 39px !important; }

.x-m-40 { margin: 40px !important; }

.x-m-41 { margin: 41px !important; }

.x-m-42 { margin: 42px !important; }

.x-m-43 { margin: 43px !important; }

.x-m-44 { margin: 44px !important; }

.x-m-45 { margin: 45px !important; }

.x-m-46 { margin: 46px !important; }

.x-m-47 { margin: 47px !important; }

.x-m-48 { margin: 48px !important; }

.x-m-49 { margin: 49px !important; }

.x-m-50 { margin: 50px !important; }

.x-m-55 { margin: 55px !important; }

.x-m-60 { margin: 60px !important; }

.x-m-65 { margin: 65px !important; }

.x-m-70 { margin: 70px !important; }

.x-m-75 { margin: 75px !important; }

.x-m-80 { margin: 80px !important; }

.x-m-85 { margin: 85px !important; }

.x-m-90 { margin: 90px !important; }

.x-m-95 { margin: 95px !important; }

.x-m-100 { margin: 100px !important; }

.x-m-105 { margin: 105px !important; }

.x-m-110 { margin: 110px !important; }

.x-m-115 { margin: 115px !important; }

.x-m-120 { margin: 120px !important; }

.x-m-125 { margin: 125px !important; }

.x-m-130 { margin: 130px !important; }

.x-m-135 { margin: 135px !important; }

.x-m-140 { margin: 140px !important; }

.x-m-145 { margin: 145px !important; }

.x-m-150 { margin: 150px !important; }

.x-m-155 { margin: 155px !important; }

.x-m-160 { margin: 160px !important; }

.x-m-165 { margin: 165px !important; }

.x-m-170 { margin: 170px !important; }

.x-m-175 { margin: 175px !important; }

.x-m-180 { margin: 180px !important; }

.x-m-185 { margin: 185px !important; }

.x-m-190 { margin: 190px !important; }

.x-m-195 { margin: 195px !important; }

.x-m-200 { margin: 200px !important; }

.x-m-205 { margin: 205px !important; }

.x-m-210 { margin: 210px !important; }

.x-m-215 { margin: 215px !important; }

.x-m-220 { margin: 220px !important; }

.x-m-225 { margin: 225px !important; }

.x-m-230 { margin: 230px !important; }

.x-m-235 { margin: 235px !important; }

.x-m-240 { margin: 240px !important; }

.x-m-245 { margin: 245px !important; }

.x-m-250 { margin: 250px !important; }

/** margin-top Emmet abbreviation: mt */
.x-mt-a { margin-top: auto !important; }

.x-mt-0 { margin-top: 0px !important; }

.x-mt-1 { margin-top: 1px !important; }

.x-mt-2 { margin-top: 2px !important; }

.x-mt-3 { margin-top: 3px !important; }

.x-mt-4 { margin-top: 4px !important; }

.x-mt-5 { margin-top: 5px !important; }

.x-mt-6 { margin-top: 6px !important; }

.x-mt-7 { margin-top: 7px !important; }

.x-mt-8 { margin-top: 8px !important; }

.x-mt-9 { margin-top: 9px !important; }

.x-mt-10 { margin-top: 10px !important; }

.x-mt-11 { margin-top: 11px !important; }

.x-mt-12 { margin-top: 12px !important; }

.x-mt-13 { margin-top: 13px !important; }

.x-mt-14 { margin-top: 14px !important; }

.x-mt-15 { margin-top: 15px !important; }

.x-mt-16 { margin-top: 16px !important; }

.x-mt-17 { margin-top: 17px !important; }

.x-mt-18 { margin-top: 18px !important; }

.x-mt-19 { margin-top: 19px !important; }

.x-mt-20 { margin-top: 20px !important; }

.x-mt-21 { margin-top: 21px !important; }

.x-mt-22 { margin-top: 22px !important; }

.x-mt-23 { margin-top: 23px !important; }

.x-mt-24 { margin-top: 24px !important; }

.x-mt-25 { margin-top: 25px !important; }

.x-mt-26 { margin-top: 26px !important; }

.x-mt-27 { margin-top: 27px !important; }

.x-mt-28 { margin-top: 28px !important; }

.x-mt-29 { margin-top: 29px !important; }

.x-mt-30 { margin-top: 30px !important; }

.x-mt-31 { margin-top: 31px !important; }

.x-mt-32 { margin-top: 32px !important; }

.x-mt-33 { margin-top: 33px !important; }

.x-mt-34 { margin-top: 34px !important; }

.x-mt-35 { margin-top: 35px !important; }

.x-mt-36 { margin-top: 36px !important; }

.x-mt-37 { margin-top: 37px !important; }

.x-mt-38 { margin-top: 38px !important; }

.x-mt-39 { margin-top: 39px !important; }

.x-mt-40 { margin-top: 40px !important; }

.x-mt-41 { margin-top: 41px !important; }

.x-mt-42 { margin-top: 42px !important; }

.x-mt-43 { margin-top: 43px !important; }

.x-mt-44 { margin-top: 44px !important; }

.x-mt-45 { margin-top: 45px !important; }

.x-mt-46 { margin-top: 46px !important; }

.x-mt-47 { margin-top: 47px !important; }

.x-mt-48 { margin-top: 48px !important; }

.x-mt-49 { margin-top: 49px !important; }

.x-mt-50 { margin-top: 50px !important; }

.x-mt-55 { margin-top: 55px !important; }

.x-mt-60 { margin-top: 60px !important; }

.x-mt-65 { margin-top: 65px !important; }

.x-mt-70 { margin-top: 70px !important; }

.x-mt-75 { margin-top: 75px !important; }

.x-mt-80 { margin-top: 80px !important; }

.x-mt-85 { margin-top: 85px !important; }

.x-mt-90 { margin-top: 90px !important; }

.x-mt-95 { margin-top: 95px !important; }

.x-mt-100 { margin-top: 100px !important; }

.x-mt-105 { margin-top: 105px !important; }

.x-mt-110 { margin-top: 110px !important; }

.x-mt-115 { margin-top: 115px !important; }

.x-mt-120 { margin-top: 120px !important; }

.x-mt-125 { margin-top: 125px !important; }

.x-mt-130 { margin-top: 130px !important; }

.x-mt-135 { margin-top: 135px !important; }

.x-mt-140 { margin-top: 140px !important; }

.x-mt-145 { margin-top: 145px !important; }

.x-mt-150 { margin-top: 150px !important; }

.x-mt-155 { margin-top: 155px !important; }

.x-mt-160 { margin-top: 160px !important; }

.x-mt-165 { margin-top: 165px !important; }

.x-mt-170 { margin-top: 170px !important; }

.x-mt-175 { margin-top: 175px !important; }

.x-mt-180 { margin-top: 180px !important; }

.x-mt-185 { margin-top: 185px !important; }

.x-mt-190 { margin-top: 190px !important; }

.x-mt-195 { margin-top: 195px !important; }

.x-mt-200 { margin-top: 200px !important; }

.x-mt-205 { margin-top: 205px !important; }

.x-mt-210 { margin-top: 210px !important; }

.x-mt-215 { margin-top: 215px !important; }

.x-mt-220 { margin-top: 220px !important; }

.x-mt-225 { margin-top: 225px !important; }

.x-mt-230 { margin-top: 230px !important; }

.x-mt-235 { margin-top: 235px !important; }

.x-mt-240 { margin-top: 240px !important; }

.x-mt-245 { margin-top: 245px !important; }

.x-mt-250 { margin-top: 250px !important; }

/** margin-right Emmet abbreviation: mr */
.x-mr-a { margin-right: auto !important; }

.x-mr-0 { margin-right: 0px !important; }

.x-mr-1 { margin-right: 1px !important; }

.x-mr-2 { margin-right: 2px !important; }

.x-mr-3 { margin-right: 3px !important; }

.x-mr-4 { margin-right: 4px !important; }

.x-mr-5 { margin-right: 5px !important; }

.x-mr-6 { margin-right: 6px !important; }

.x-mr-7 { margin-right: 7px !important; }

.x-mr-8 { margin-right: 8px !important; }

.x-mr-9 { margin-right: 9px !important; }

.x-mr-10 { margin-right: 10px !important; }

.x-mr-11 { margin-right: 11px !important; }

.x-mr-12 { margin-right: 12px !important; }

.x-mr-13 { margin-right: 13px !important; }

.x-mr-14 { margin-right: 14px !important; }

.x-mr-15 { margin-right: 15px !important; }

.x-mr-16 { margin-right: 16px !important; }

.x-mr-17 { margin-right: 17px !important; }

.x-mr-18 { margin-right: 18px !important; }

.x-mr-19 { margin-right: 19px !important; }

.x-mr-20 { margin-right: 20px !important; }

.x-mr-21 { margin-right: 21px !important; }

.x-mr-22 { margin-right: 22px !important; }

.x-mr-23 { margin-right: 23px !important; }

.x-mr-24 { margin-right: 24px !important; }

.x-mr-25 { margin-right: 25px !important; }

.x-mr-26 { margin-right: 26px !important; }

.x-mr-27 { margin-right: 27px !important; }

.x-mr-28 { margin-right: 28px !important; }

.x-mr-29 { margin-right: 29px !important; }

.x-mr-30 { margin-right: 30px !important; }

.x-mr-31 { margin-right: 31px !important; }

.x-mr-32 { margin-right: 32px !important; }

.x-mr-33 { margin-right: 33px !important; }

.x-mr-34 { margin-right: 34px !important; }

.x-mr-35 { margin-right: 35px !important; }

.x-mr-36 { margin-right: 36px !important; }

.x-mr-37 { margin-right: 37px !important; }

.x-mr-38 { margin-right: 38px !important; }

.x-mr-39 { margin-right: 39px !important; }

.x-mr-40 { margin-right: 40px !important; }

.x-mr-41 { margin-right: 41px !important; }

.x-mr-42 { margin-right: 42px !important; }

.x-mr-43 { margin-right: 43px !important; }

.x-mr-44 { margin-right: 44px !important; }

.x-mr-45 { margin-right: 45px !important; }

.x-mr-46 { margin-right: 46px !important; }

.x-mr-47 { margin-right: 47px !important; }

.x-mr-48 { margin-right: 48px !important; }

.x-mr-49 { margin-right: 49px !important; }

.x-mr-50 { margin-right: 50px !important; }

.x-mr-55 { margin-right: 55px !important; }

.x-mr-60 { margin-right: 60px !important; }

.x-mr-65 { margin-right: 65px !important; }

.x-mr-70 { margin-right: 70px !important; }

.x-mr-75 { margin-right: 75px !important; }

.x-mr-80 { margin-right: 80px !important; }

.x-mr-85 { margin-right: 85px !important; }

.x-mr-90 { margin-right: 90px !important; }

.x-mr-95 { margin-right: 95px !important; }

.x-mr-100 { margin-right: 100px !important; }

.x-mr-105 { margin-right: 105px !important; }

.x-mr-110 { margin-right: 110px !important; }

.x-mr-115 { margin-right: 115px !important; }

.x-mr-120 { margin-right: 120px !important; }

.x-mr-125 { margin-right: 125px !important; }

.x-mr-130 { margin-right: 130px !important; }

.x-mr-135 { margin-right: 135px !important; }

.x-mr-140 { margin-right: 140px !important; }

.x-mr-145 { margin-right: 145px !important; }

.x-mr-150 { margin-right: 150px !important; }

.x-mr-155 { margin-right: 155px !important; }

.x-mr-160 { margin-right: 160px !important; }

.x-mr-165 { margin-right: 165px !important; }

.x-mr-170 { margin-right: 170px !important; }

.x-mr-175 { margin-right: 175px !important; }

.x-mr-180 { margin-right: 180px !important; }

.x-mr-185 { margin-right: 185px !important; }

.x-mr-190 { margin-right: 190px !important; }

.x-mr-195 { margin-right: 195px !important; }

.x-mr-200 { margin-right: 200px !important; }

.x-mr-205 { margin-right: 205px !important; }

.x-mr-210 { margin-right: 210px !important; }

.x-mr-215 { margin-right: 215px !important; }

.x-mr-220 { margin-right: 220px !important; }

.x-mr-225 { margin-right: 225px !important; }

.x-mr-230 { margin-right: 230px !important; }

.x-mr-235 { margin-right: 235px !important; }

.x-mr-240 { margin-right: 240px !important; }

.x-mr-245 { margin-right: 245px !important; }

.x-mr-250 { margin-right: 250px !important; }

/** margin-bottom Emmet abbreviation: mb */
.x-mb-a { margin-bottom: auto !important; }

.x-mb-0 { margin-bottom: 0px !important; }

.x-mb-1 { margin-bottom: 1px !important; }

.x-mb-2 { margin-bottom: 2px !important; }

.x-mb-3 { margin-bottom: 3px !important; }

.x-mb-4 { margin-bottom: 4px !important; }

.x-mb-5 { margin-bottom: 5px !important; }

.x-mb-6 { margin-bottom: 6px !important; }

.x-mb-7 { margin-bottom: 7px !important; }

.x-mb-8 { margin-bottom: 8px !important; }

.x-mb-9 { margin-bottom: 9px !important; }

.x-mb-10 { margin-bottom: 10px !important; }

.x-mb-11 { margin-bottom: 11px !important; }

.x-mb-12 { margin-bottom: 12px !important; }

.x-mb-13 { margin-bottom: 13px !important; }

.x-mb-14 { margin-bottom: 14px !important; }

.x-mb-15 { margin-bottom: 15px !important; }

.x-mb-16 { margin-bottom: 16px !important; }

.x-mb-17 { margin-bottom: 17px !important; }

.x-mb-18 { margin-bottom: 18px !important; }

.x-mb-19 { margin-bottom: 19px !important; }

.x-mb-20 { margin-bottom: 20px !important; }

.x-mb-21 { margin-bottom: 21px !important; }

.x-mb-22 { margin-bottom: 22px !important; }

.x-mb-23 { margin-bottom: 23px !important; }

.x-mb-24 { margin-bottom: 24px !important; }

.x-mb-25 { margin-bottom: 25px !important; }

.x-mb-26 { margin-bottom: 26px !important; }

.x-mb-27 { margin-bottom: 27px !important; }

.x-mb-28 { margin-bottom: 28px !important; }

.x-mb-29 { margin-bottom: 29px !important; }

.x-mb-30 { margin-bottom: 30px !important; }

.x-mb-31 { margin-bottom: 31px !important; }

.x-mb-32 { margin-bottom: 32px !important; }

.x-mb-33 { margin-bottom: 33px !important; }

.x-mb-34 { margin-bottom: 34px !important; }

.x-mb-35 { margin-bottom: 35px !important; }

.x-mb-36 { margin-bottom: 36px !important; }

.x-mb-37 { margin-bottom: 37px !important; }

.x-mb-38 { margin-bottom: 38px !important; }

.x-mb-39 { margin-bottom: 39px !important; }

.x-mb-40 { margin-bottom: 40px !important; }

.x-mb-41 { margin-bottom: 41px !important; }

.x-mb-42 { margin-bottom: 42px !important; }

.x-mb-43 { margin-bottom: 43px !important; }

.x-mb-44 { margin-bottom: 44px !important; }

.x-mb-45 { margin-bottom: 45px !important; }

.x-mb-46 { margin-bottom: 46px !important; }

.x-mb-47 { margin-bottom: 47px !important; }

.x-mb-48 { margin-bottom: 48px !important; }

.x-mb-49 { margin-bottom: 49px !important; }

.x-mb-50 { margin-bottom: 50px !important; }

.x-mb-55 { margin-bottom: 55px !important; }

.x-mb-60 { margin-bottom: 60px !important; }

.x-mb-65 { margin-bottom: 65px !important; }

.x-mb-70 { margin-bottom: 70px !important; }

.x-mb-75 { margin-bottom: 75px !important; }

.x-mb-80 { margin-bottom: 80px !important; }

.x-mb-85 { margin-bottom: 85px !important; }

.x-mb-90 { margin-bottom: 90px !important; }

.x-mb-95 { margin-bottom: 95px !important; }

.x-mb-100 { margin-bottom: 100px !important; }

.x-mb-105 { margin-bottom: 105px !important; }

.x-mb-110 { margin-bottom: 110px !important; }

.x-mb-115 { margin-bottom: 115px !important; }

.x-mb-120 { margin-bottom: 120px !important; }

.x-mb-125 { margin-bottom: 125px !important; }

.x-mb-130 { margin-bottom: 130px !important; }

.x-mb-135 { margin-bottom: 135px !important; }

.x-mb-140 { margin-bottom: 140px !important; }

.x-mb-145 { margin-bottom: 145px !important; }

.x-mb-150 { margin-bottom: 150px !important; }

.x-mb-155 { margin-bottom: 155px !important; }

.x-mb-160 { margin-bottom: 160px !important; }

.x-mb-165 { margin-bottom: 165px !important; }

.x-mb-170 { margin-bottom: 170px !important; }

.x-mb-175 { margin-bottom: 175px !important; }

.x-mb-180 { margin-bottom: 180px !important; }

.x-mb-185 { margin-bottom: 185px !important; }

.x-mb-190 { margin-bottom: 190px !important; }

.x-mb-195 { margin-bottom: 195px !important; }

.x-mb-200 { margin-bottom: 200px !important; }

.x-mb-205 { margin-bottom: 205px !important; }

.x-mb-210 { margin-bottom: 210px !important; }

.x-mb-215 { margin-bottom: 215px !important; }

.x-mb-220 { margin-bottom: 220px !important; }

.x-mb-225 { margin-bottom: 225px !important; }

.x-mb-230 { margin-bottom: 230px !important; }

.x-mb-235 { margin-bottom: 235px !important; }

.x-mb-240 { margin-bottom: 240px !important; }

.x-mb-245 { margin-bottom: 245px !important; }

.x-mb-250 { margin-bottom: 250px !important; }

/** margin-left Emmet abbreviation: ml */
.x-ml-a { margin-left: auto !important; }

.x-ml-0 { margin-left: 0px !important; }

.x-ml-1 { margin-left: 1px !important; }

.x-ml-2 { margin-left: 2px !important; }

.x-ml-3 { margin-left: 3px !important; }

.x-ml-4 { margin-left: 4px !important; }

.x-ml-5 { margin-left: 5px !important; }

.x-ml-6 { margin-left: 6px !important; }

.x-ml-7 { margin-left: 7px !important; }

.x-ml-8 { margin-left: 8px !important; }

.x-ml-9 { margin-left: 9px !important; }

.x-ml-10 { margin-left: 10px !important; }

.x-ml-11 { margin-left: 11px !important; }

.x-ml-12 { margin-left: 12px !important; }

.x-ml-13 { margin-left: 13px !important; }

.x-ml-14 { margin-left: 14px !important; }

.x-ml-15 { margin-left: 15px !important; }

.x-ml-16 { margin-left: 16px !important; }

.x-ml-17 { margin-left: 17px !important; }

.x-ml-18 { margin-left: 18px !important; }

.x-ml-19 { margin-left: 19px !important; }

.x-ml-20 { margin-left: 20px !important; }

.x-ml-21 { margin-left: 21px !important; }

.x-ml-22 { margin-left: 22px !important; }

.x-ml-23 { margin-left: 23px !important; }

.x-ml-24 { margin-left: 24px !important; }

.x-ml-25 { margin-left: 25px !important; }

.x-ml-26 { margin-left: 26px !important; }

.x-ml-27 { margin-left: 27px !important; }

.x-ml-28 { margin-left: 28px !important; }

.x-ml-29 { margin-left: 29px !important; }

.x-ml-30 { margin-left: 30px !important; }

.x-ml-31 { margin-left: 31px !important; }

.x-ml-32 { margin-left: 32px !important; }

.x-ml-33 { margin-left: 33px !important; }

.x-ml-34 { margin-left: 34px !important; }

.x-ml-35 { margin-left: 35px !important; }

.x-ml-36 { margin-left: 36px !important; }

.x-ml-37 { margin-left: 37px !important; }

.x-ml-38 { margin-left: 38px !important; }

.x-ml-39 { margin-left: 39px !important; }

.x-ml-40 { margin-left: 40px !important; }

.x-ml-41 { margin-left: 41px !important; }

.x-ml-42 { margin-left: 42px !important; }

.x-ml-43 { margin-left: 43px !important; }

.x-ml-44 { margin-left: 44px !important; }

.x-ml-45 { margin-left: 45px !important; }

.x-ml-46 { margin-left: 46px !important; }

.x-ml-47 { margin-left: 47px !important; }

.x-ml-48 { margin-left: 48px !important; }

.x-ml-49 { margin-left: 49px !important; }

.x-ml-50 { margin-left: 50px !important; }

.x-ml-55 { margin-left: 55px !important; }

.x-ml-60 { margin-left: 60px !important; }

.x-ml-65 { margin-left: 65px !important; }

.x-ml-70 { margin-left: 70px !important; }

.x-ml-75 { margin-left: 75px !important; }

.x-ml-80 { margin-left: 80px !important; }

.x-ml-85 { margin-left: 85px !important; }

.x-ml-90 { margin-left: 90px !important; }

.x-ml-95 { margin-left: 95px !important; }

.x-ml-100 { margin-left: 100px !important; }

.x-ml-105 { margin-left: 105px !important; }

.x-ml-110 { margin-left: 110px !important; }

.x-ml-115 { margin-left: 115px !important; }

.x-ml-120 { margin-left: 120px !important; }

.x-ml-125 { margin-left: 125px !important; }

.x-ml-130 { margin-left: 130px !important; }

.x-ml-135 { margin-left: 135px !important; }

.x-ml-140 { margin-left: 140px !important; }

.x-ml-145 { margin-left: 145px !important; }

.x-ml-150 { margin-left: 150px !important; }

.x-ml-155 { margin-left: 155px !important; }

.x-ml-160 { margin-left: 160px !important; }

.x-ml-165 { margin-left: 165px !important; }

.x-ml-170 { margin-left: 170px !important; }

.x-ml-175 { margin-left: 175px !important; }

.x-ml-180 { margin-left: 180px !important; }

.x-ml-185 { margin-left: 185px !important; }

.x-ml-190 { margin-left: 190px !important; }

.x-ml-195 { margin-left: 195px !important; }

.x-ml-200 { margin-left: 200px !important; }

.x-ml-205 { margin-left: 205px !important; }

.x-ml-210 { margin-left: 210px !important; }

.x-ml-215 { margin-left: 215px !important; }

.x-ml-220 { margin-left: 220px !important; }

.x-ml-225 { margin-left: 225px !important; }

.x-ml-230 { margin-left: 230px !important; }

.x-ml-235 { margin-left: 235px !important; }

.x-ml-240 { margin-left: 240px !important; }

.x-ml-245 { margin-left: 245px !important; }

.x-ml-250 { margin-left: 250px !important; }

/** padding Emmet abbreviation: p */
.x-p-0 { padding: 0px !important; }

.x-p-1 { padding: 1px !important; }

.x-p-2 { padding: 2px !important; }

.x-p-3 { padding: 3px !important; }

.x-p-4 { padding: 4px !important; }

.x-p-5 { padding: 5px !important; }

.x-p-6 { padding: 6px !important; }

.x-p-7 { padding: 7px !important; }

.x-p-8 { padding: 8px !important; }

.x-p-9 { padding: 9px !important; }

.x-p-10 { padding: 10px !important; }

.x-p-11 { padding: 11px !important; }

.x-p-12 { padding: 12px !important; }

.x-p-13 { padding: 13px !important; }

.x-p-14 { padding: 14px !important; }

.x-p-15 { padding: 15px !important; }

.x-p-16 { padding: 16px !important; }

.x-p-17 { padding: 17px !important; }

.x-p-18 { padding: 18px !important; }

.x-p-19 { padding: 19px !important; }

.x-p-20 { padding: 20px !important; }

.x-p-21 { padding: 21px !important; }

.x-p-22 { padding: 22px !important; }

.x-p-23 { padding: 23px !important; }

.x-p-24 { padding: 24px !important; }

.x-p-25 { padding: 25px !important; }

.x-p-26 { padding: 26px !important; }

.x-p-27 { padding: 27px !important; }

.x-p-28 { padding: 28px !important; }

.x-p-29 { padding: 29px !important; }

.x-p-30 { padding: 30px !important; }

.x-p-31 { padding: 31px !important; }

.x-p-32 { padding: 32px !important; }

.x-p-33 { padding: 33px !important; }

.x-p-34 { padding: 34px !important; }

.x-p-35 { padding: 35px !important; }

.x-p-36 { padding: 36px !important; }

.x-p-37 { padding: 37px !important; }

.x-p-38 { padding: 38px !important; }

.x-p-39 { padding: 39px !important; }

.x-p-40 { padding: 40px !important; }

.x-p-41 { padding: 41px !important; }

.x-p-42 { padding: 42px !important; }

.x-p-43 { padding: 43px !important; }

.x-p-44 { padding: 44px !important; }

.x-p-45 { padding: 45px !important; }

.x-p-46 { padding: 46px !important; }

.x-p-47 { padding: 47px !important; }

.x-p-48 { padding: 48px !important; }

.x-p-49 { padding: 49px !important; }

.x-p-50 { padding: 50px !important; }

.x-p-55 { padding: 55px !important; }

.x-p-60 { padding: 60px !important; }

.x-p-65 { padding: 65px !important; }

.x-p-70 { padding: 70px !important; }

.x-p-75 { padding: 75px !important; }

.x-p-80 { padding: 80px !important; }

.x-p-85 { padding: 85px !important; }

.x-p-90 { padding: 90px !important; }

.x-p-95 { padding: 95px !important; }

.x-p-100 { padding: 100px !important; }

.x-p-105 { padding: 105px !important; }

.x-p-110 { padding: 110px !important; }

.x-p-115 { padding: 115px !important; }

.x-p-120 { padding: 120px !important; }

.x-p-125 { padding: 125px !important; }

.x-p-130 { padding: 130px !important; }

.x-p-135 { padding: 135px !important; }

.x-p-140 { padding: 140px !important; }

.x-p-145 { padding: 145px !important; }

.x-p-150 { padding: 150px !important; }

.x-p-155 { padding: 155px !important; }

.x-p-160 { padding: 160px !important; }

.x-p-165 { padding: 165px !important; }

.x-p-170 { padding: 170px !important; }

.x-p-175 { padding: 175px !important; }

.x-p-180 { padding: 180px !important; }

.x-p-185 { padding: 185px !important; }

.x-p-190 { padding: 190px !important; }

.x-p-195 { padding: 195px !important; }

.x-p-200 { padding: 200px !important; }

.x-p-205 { padding: 205px !important; }

.x-p-210 { padding: 210px !important; }

.x-p-215 { padding: 215px !important; }

.x-p-220 { padding: 220px !important; }

.x-p-225 { padding: 225px !important; }

.x-p-230 { padding: 230px !important; }

.x-p-235 { padding: 235px !important; }

.x-p-240 { padding: 240px !important; }

.x-p-245 { padding: 245px !important; }

.x-p-250 { padding: 250px !important; }

/** padding-top Emmet abbreviation: pt */
.x-pt-0 { padding-top: 0px !important; }

.x-pt-1 { padding-top: 1px !important; }

.x-pt-2 { padding-top: 2px !important; }

.x-pt-3 { padding-top: 3px !important; }

.x-pt-4 { padding-top: 4px !important; }

.x-pt-5 { padding-top: 5px !important; }

.x-pt-6 { padding-top: 6px !important; }

.x-pt-7 { padding-top: 7px !important; }

.x-pt-8 { padding-top: 8px !important; }

.x-pt-9 { padding-top: 9px !important; }

.x-pt-10 { padding-top: 10px !important; }

.x-pt-11 { padding-top: 11px !important; }

.x-pt-12 { padding-top: 12px !important; }

.x-pt-13 { padding-top: 13px !important; }

.x-pt-14 { padding-top: 14px !important; }

.x-pt-15 { padding-top: 15px !important; }

.x-pt-16 { padding-top: 16px !important; }

.x-pt-17 { padding-top: 17px !important; }

.x-pt-18 { padding-top: 18px !important; }

.x-pt-19 { padding-top: 19px !important; }

.x-pt-20 { padding-top: 20px !important; }

.x-pt-21 { padding-top: 21px !important; }

.x-pt-22 { padding-top: 22px !important; }

.x-pt-23 { padding-top: 23px !important; }

.x-pt-24 { padding-top: 24px !important; }

.x-pt-25 { padding-top: 25px !important; }

.x-pt-26 { padding-top: 26px !important; }

.x-pt-27 { padding-top: 27px !important; }

.x-pt-28 { padding-top: 28px !important; }

.x-pt-29 { padding-top: 29px !important; }

.x-pt-30 { padding-top: 30px !important; }

.x-pt-31 { padding-top: 31px !important; }

.x-pt-32 { padding-top: 32px !important; }

.x-pt-33 { padding-top: 33px !important; }

.x-pt-34 { padding-top: 34px !important; }

.x-pt-35 { padding-top: 35px !important; }

.x-pt-36 { padding-top: 36px !important; }

.x-pt-37 { padding-top: 37px !important; }

.x-pt-38 { padding-top: 38px !important; }

.x-pt-39 { padding-top: 39px !important; }

.x-pt-40 { padding-top: 40px !important; }

.x-pt-41 { padding-top: 41px !important; }

.x-pt-42 { padding-top: 42px !important; }

.x-pt-43 { padding-top: 43px !important; }

.x-pt-44 { padding-top: 44px !important; }

.x-pt-45 { padding-top: 45px !important; }

.x-pt-46 { padding-top: 46px !important; }

.x-pt-47 { padding-top: 47px !important; }

.x-pt-48 { padding-top: 48px !important; }

.x-pt-49 { padding-top: 49px !important; }

.x-pt-50 { padding-top: 50px !important; }

.x-pt-55 { padding-top: 55px !important; }

.x-pt-60 { padding-top: 60px !important; }

.x-pt-65 { padding-top: 65px !important; }

.x-pt-70 { padding-top: 70px !important; }

.x-pt-75 { padding-top: 75px !important; }

.x-pt-80 { padding-top: 80px !important; }

.x-pt-85 { padding-top: 85px !important; }

.x-pt-90 { padding-top: 90px !important; }

.x-pt-95 { padding-top: 95px !important; }

.x-pt-100 { padding-top: 100px !important; }

.x-pt-105 { padding-top: 105px !important; }

.x-pt-110 { padding-top: 110px !important; }

.x-pt-115 { padding-top: 115px !important; }

.x-pt-120 { padding-top: 120px !important; }

.x-pt-125 { padding-top: 125px !important; }

.x-pt-130 { padding-top: 130px !important; }

.x-pt-135 { padding-top: 135px !important; }

.x-pt-140 { padding-top: 140px !important; }

.x-pt-145 { padding-top: 145px !important; }

.x-pt-150 { padding-top: 150px !important; }

.x-pt-155 { padding-top: 155px !important; }

.x-pt-160 { padding-top: 160px !important; }

.x-pt-165 { padding-top: 165px !important; }

.x-pt-170 { padding-top: 170px !important; }

.x-pt-175 { padding-top: 175px !important; }

.x-pt-180 { padding-top: 180px !important; }

.x-pt-185 { padding-top: 185px !important; }

.x-pt-190 { padding-top: 190px !important; }

.x-pt-195 { padding-top: 195px !important; }

.x-pt-200 { padding-top: 200px !important; }

.x-pt-205 { padding-top: 205px !important; }

.x-pt-210 { padding-top: 210px !important; }

.x-pt-215 { padding-top: 215px !important; }

.x-pt-220 { padding-top: 220px !important; }

.x-pt-225 { padding-top: 225px !important; }

.x-pt-230 { padding-top: 230px !important; }

.x-pt-235 { padding-top: 235px !important; }

.x-pt-240 { padding-top: 240px !important; }

.x-pt-245 { padding-top: 245px !important; }

.x-pt-250 { padding-top: 250px !important; }

/** padding-right Emmet abbreviation: pr */
.x-pr-0 { padding-right: 0px !important; }

.x-pr-1 { padding-right: 1px !important; }

.x-pr-2 { padding-right: 2px !important; }

.x-pr-3 { padding-right: 3px !important; }

.x-pr-4 { padding-right: 4px !important; }

.x-pr-5 { padding-right: 5px !important; }

.x-pr-6 { padding-right: 6px !important; }

.x-pr-7 { padding-right: 7px !important; }

.x-pr-8 { padding-right: 8px !important; }

.x-pr-9 { padding-right: 9px !important; }

.x-pr-10 { padding-right: 10px !important; }

.x-pr-11 { padding-right: 11px !important; }

.x-pr-12 { padding-right: 12px !important; }

.x-pr-13 { padding-right: 13px !important; }

.x-pr-14 { padding-right: 14px !important; }

.x-pr-15 { padding-right: 15px !important; }

.x-pr-16 { padding-right: 16px !important; }

.x-pr-17 { padding-right: 17px !important; }

.x-pr-18 { padding-right: 18px !important; }

.x-pr-19 { padding-right: 19px !important; }

.x-pr-20 { padding-right: 20px !important; }

.x-pr-21 { padding-right: 21px !important; }

.x-pr-22 { padding-right: 22px !important; }

.x-pr-23 { padding-right: 23px !important; }

.x-pr-24 { padding-right: 24px !important; }

.x-pr-25 { padding-right: 25px !important; }

.x-pr-26 { padding-right: 26px !important; }

.x-pr-27 { padding-right: 27px !important; }

.x-pr-28 { padding-right: 28px !important; }

.x-pr-29 { padding-right: 29px !important; }

.x-pr-30 { padding-right: 30px !important; }

.x-pr-31 { padding-right: 31px !important; }

.x-pr-32 { padding-right: 32px !important; }

.x-pr-33 { padding-right: 33px !important; }

.x-pr-34 { padding-right: 34px !important; }

.x-pr-35 { padding-right: 35px !important; }

.x-pr-36 { padding-right: 36px !important; }

.x-pr-37 { padding-right: 37px !important; }

.x-pr-38 { padding-right: 38px !important; }

.x-pr-39 { padding-right: 39px !important; }

.x-pr-40 { padding-right: 40px !important; }

.x-pr-41 { padding-right: 41px !important; }

.x-pr-42 { padding-right: 42px !important; }

.x-pr-43 { padding-right: 43px !important; }

.x-pr-44 { padding-right: 44px !important; }

.x-pr-45 { padding-right: 45px !important; }

.x-pr-46 { padding-right: 46px !important; }

.x-pr-47 { padding-right: 47px !important; }

.x-pr-48 { padding-right: 48px !important; }

.x-pr-49 { padding-right: 49px !important; }

.x-pr-50 { padding-right: 50px !important; }

.x-pr-55 { padding-right: 55px !important; }

.x-pr-60 { padding-right: 60px !important; }

.x-pr-65 { padding-right: 65px !important; }

.x-pr-70 { padding-right: 70px !important; }

.x-pr-75 { padding-right: 75px !important; }

.x-pr-80 { padding-right: 80px !important; }

.x-pr-85 { padding-right: 85px !important; }

.x-pr-90 { padding-right: 90px !important; }

.x-pr-95 { padding-right: 95px !important; }

.x-pr-100 { padding-right: 100px !important; }

.x-pr-105 { padding-right: 105px !important; }

.x-pr-110 { padding-right: 110px !important; }

.x-pr-115 { padding-right: 115px !important; }

.x-pr-120 { padding-right: 120px !important; }

.x-pr-125 { padding-right: 125px !important; }

.x-pr-130 { padding-right: 130px !important; }

.x-pr-135 { padding-right: 135px !important; }

.x-pr-140 { padding-right: 140px !important; }

.x-pr-145 { padding-right: 145px !important; }

.x-pr-150 { padding-right: 150px !important; }

.x-pr-155 { padding-right: 155px !important; }

.x-pr-160 { padding-right: 160px !important; }

.x-pr-165 { padding-right: 165px !important; }

.x-pr-170 { padding-right: 170px !important; }

.x-pr-175 { padding-right: 175px !important; }

.x-pr-180 { padding-right: 180px !important; }

.x-pr-185 { padding-right: 185px !important; }

.x-pr-190 { padding-right: 190px !important; }

.x-pr-195 { padding-right: 195px !important; }

.x-pr-200 { padding-right: 200px !important; }

.x-pr-205 { padding-right: 205px !important; }

.x-pr-210 { padding-right: 210px !important; }

.x-pr-215 { padding-right: 215px !important; }

.x-pr-220 { padding-right: 220px !important; }

.x-pr-225 { padding-right: 225px !important; }

.x-pr-230 { padding-right: 230px !important; }

.x-pr-235 { padding-right: 235px !important; }

.x-pr-240 { padding-right: 240px !important; }

.x-pr-245 { padding-right: 245px !important; }

.x-pr-250 { padding-right: 250px !important; }

/** padding-bottom Emmet abbreviation: pb */
.x-pb-0 { padding-bottom: 0px !important; }

.x-pb-1 { padding-bottom: 1px !important; }

.x-pb-2 { padding-bottom: 2px !important; }

.x-pb-3 { padding-bottom: 3px !important; }

.x-pb-4 { padding-bottom: 4px !important; }

.x-pb-5 { padding-bottom: 5px !important; }

.x-pb-6 { padding-bottom: 6px !important; }

.x-pb-7 { padding-bottom: 7px !important; }

.x-pb-8 { padding-bottom: 8px !important; }

.x-pb-9 { padding-bottom: 9px !important; }

.x-pb-10 { padding-bottom: 10px !important; }

.x-pb-11 { padding-bottom: 11px !important; }

.x-pb-12 { padding-bottom: 12px !important; }

.x-pb-13 { padding-bottom: 13px !important; }

.x-pb-14 { padding-bottom: 14px !important; }

.x-pb-15 { padding-bottom: 15px !important; }

.x-pb-16 { padding-bottom: 16px !important; }

.x-pb-17 { padding-bottom: 17px !important; }

.x-pb-18 { padding-bottom: 18px !important; }

.x-pb-19 { padding-bottom: 19px !important; }

.x-pb-20 { padding-bottom: 20px !important; }

.x-pb-21 { padding-bottom: 21px !important; }

.x-pb-22 { padding-bottom: 22px !important; }

.x-pb-23 { padding-bottom: 23px !important; }

.x-pb-24 { padding-bottom: 24px !important; }

.x-pb-25 { padding-bottom: 25px !important; }

.x-pb-26 { padding-bottom: 26px !important; }

.x-pb-27 { padding-bottom: 27px !important; }

.x-pb-28 { padding-bottom: 28px !important; }

.x-pb-29 { padding-bottom: 29px !important; }

.x-pb-30 { padding-bottom: 30px !important; }

.x-pb-31 { padding-bottom: 31px !important; }

.x-pb-32 { padding-bottom: 32px !important; }

.x-pb-33 { padding-bottom: 33px !important; }

.x-pb-34 { padding-bottom: 34px !important; }

.x-pb-35 { padding-bottom: 35px !important; }

.x-pb-36 { padding-bottom: 36px !important; }

.x-pb-37 { padding-bottom: 37px !important; }

.x-pb-38 { padding-bottom: 38px !important; }

.x-pb-39 { padding-bottom: 39px !important; }

.x-pb-40 { padding-bottom: 40px !important; }

.x-pb-41 { padding-bottom: 41px !important; }

.x-pb-42 { padding-bottom: 42px !important; }

.x-pb-43 { padding-bottom: 43px !important; }

.x-pb-44 { padding-bottom: 44px !important; }

.x-pb-45 { padding-bottom: 45px !important; }

.x-pb-46 { padding-bottom: 46px !important; }

.x-pb-47 { padding-bottom: 47px !important; }

.x-pb-48 { padding-bottom: 48px !important; }

.x-pb-49 { padding-bottom: 49px !important; }

.x-pb-50 { padding-bottom: 50px !important; }

.x-pb-55 { padding-bottom: 55px !important; }

.x-pb-60 { padding-bottom: 60px !important; }

.x-pb-65 { padding-bottom: 65px !important; }

.x-pb-70 { padding-bottom: 70px !important; }

.x-pb-75 { padding-bottom: 75px !important; }

.x-pb-80 { padding-bottom: 80px !important; }

.x-pb-85 { padding-bottom: 85px !important; }

.x-pb-90 { padding-bottom: 90px !important; }

.x-pb-95 { padding-bottom: 95px !important; }

.x-pb-100 { padding-bottom: 100px !important; }

.x-pb-105 { padding-bottom: 105px !important; }

.x-pb-110 { padding-bottom: 110px !important; }

.x-pb-115 { padding-bottom: 115px !important; }

.x-pb-120 { padding-bottom: 120px !important; }

.x-pb-125 { padding-bottom: 125px !important; }

.x-pb-130 { padding-bottom: 130px !important; }

.x-pb-135 { padding-bottom: 135px !important; }

.x-pb-140 { padding-bottom: 140px !important; }

.x-pb-145 { padding-bottom: 145px !important; }

.x-pb-150 { padding-bottom: 150px !important; }

.x-pb-155 { padding-bottom: 155px !important; }

.x-pb-160 { padding-bottom: 160px !important; }

.x-pb-165 { padding-bottom: 165px !important; }

.x-pb-170 { padding-bottom: 170px !important; }

.x-pb-175 { padding-bottom: 175px !important; }

.x-pb-180 { padding-bottom: 180px !important; }

.x-pb-185 { padding-bottom: 185px !important; }

.x-pb-190 { padding-bottom: 190px !important; }

.x-pb-195 { padding-bottom: 195px !important; }

.x-pb-200 { padding-bottom: 200px !important; }

.x-pb-205 { padding-bottom: 205px !important; }

.x-pb-210 { padding-bottom: 210px !important; }

.x-pb-215 { padding-bottom: 215px !important; }

.x-pb-220 { padding-bottom: 220px !important; }

.x-pb-225 { padding-bottom: 225px !important; }

.x-pb-230 { padding-bottom: 230px !important; }

.x-pb-235 { padding-bottom: 235px !important; }

.x-pb-240 { padding-bottom: 240px !important; }

.x-pb-245 { padding-bottom: 245px !important; }

.x-pb-250 { padding-bottom: 250px !important; }

/** padding-left Emmet abbreviation: pl */
.x-pl-0 { padding-left: 0px !important; }

.x-pl-1 { padding-left: 1px !important; }

.x-pl-2 { padding-left: 2px !important; }

.x-pl-3 { padding-left: 3px !important; }

.x-pl-4 { padding-left: 4px !important; }

.x-pl-5 { padding-left: 5px !important; }

.x-pl-6 { padding-left: 6px !important; }

.x-pl-7 { padding-left: 7px !important; }

.x-pl-8 { padding-left: 8px !important; }

.x-pl-9 { padding-left: 9px !important; }

.x-pl-10 { padding-left: 10px !important; }

.x-pl-11 { padding-left: 11px !important; }

.x-pl-12 { padding-left: 12px !important; }

.x-pl-13 { padding-left: 13px !important; }

.x-pl-14 { padding-left: 14px !important; }

.x-pl-15 { padding-left: 15px !important; }

.x-pl-16 { padding-left: 16px !important; }

.x-pl-17 { padding-left: 17px !important; }

.x-pl-18 { padding-left: 18px !important; }

.x-pl-19 { padding-left: 19px !important; }

.x-pl-20 { padding-left: 20px !important; }

.x-pl-21 { padding-left: 21px !important; }

.x-pl-22 { padding-left: 22px !important; }

.x-pl-23 { padding-left: 23px !important; }

.x-pl-24 { padding-left: 24px !important; }

.x-pl-25 { padding-left: 25px !important; }

.x-pl-26 { padding-left: 26px !important; }

.x-pl-27 { padding-left: 27px !important; }

.x-pl-28 { padding-left: 28px !important; }

.x-pl-29 { padding-left: 29px !important; }

.x-pl-30 { padding-left: 30px !important; }

.x-pl-31 { padding-left: 31px !important; }

.x-pl-32 { padding-left: 32px !important; }

.x-pl-33 { padding-left: 33px !important; }

.x-pl-34 { padding-left: 34px !important; }

.x-pl-35 { padding-left: 35px !important; }

.x-pl-36 { padding-left: 36px !important; }

.x-pl-37 { padding-left: 37px !important; }

.x-pl-38 { padding-left: 38px !important; }

.x-pl-39 { padding-left: 39px !important; }

.x-pl-40 { padding-left: 40px !important; }

.x-pl-41 { padding-left: 41px !important; }

.x-pl-42 { padding-left: 42px !important; }

.x-pl-43 { padding-left: 43px !important; }

.x-pl-44 { padding-left: 44px !important; }

.x-pl-45 { padding-left: 45px !important; }

.x-pl-46 { padding-left: 46px !important; }

.x-pl-47 { padding-left: 47px !important; }

.x-pl-48 { padding-left: 48px !important; }

.x-pl-49 { padding-left: 49px !important; }

.x-pl-50 { padding-left: 50px !important; }

.x-pl-55 { padding-left: 55px !important; }

.x-pl-60 { padding-left: 60px !important; }

.x-pl-65 { padding-left: 65px !important; }

.x-pl-70 { padding-left: 70px !important; }

.x-pl-75 { padding-left: 75px !important; }

.x-pl-80 { padding-left: 80px !important; }

.x-pl-85 { padding-left: 85px !important; }

.x-pl-90 { padding-left: 90px !important; }

.x-pl-95 { padding-left: 95px !important; }

.x-pl-100 { padding-left: 100px !important; }

.x-pl-105 { padding-left: 105px !important; }

.x-pl-110 { padding-left: 110px !important; }

.x-pl-115 { padding-left: 115px !important; }

.x-pl-120 { padding-left: 120px !important; }

.x-pl-125 { padding-left: 125px !important; }

.x-pl-130 { padding-left: 130px !important; }

.x-pl-135 { padding-left: 135px !important; }

.x-pl-140 { padding-left: 140px !important; }

.x-pl-145 { padding-left: 145px !important; }

.x-pl-150 { padding-left: 150px !important; }

.x-pl-155 { padding-left: 155px !important; }

.x-pl-160 { padding-left: 160px !important; }

.x-pl-165 { padding-left: 165px !important; }

.x-pl-170 { padding-left: 170px !important; }

.x-pl-175 { padding-left: 175px !important; }

.x-pl-180 { padding-left: 180px !important; }

.x-pl-185 { padding-left: 185px !important; }

.x-pl-190 { padding-left: 190px !important; }

.x-pl-195 { padding-left: 195px !important; }

.x-pl-200 { padding-left: 200px !important; }

.x-pl-205 { padding-left: 205px !important; }

.x-pl-210 { padding-left: 210px !important; }

.x-pl-215 { padding-left: 215px !important; }

.x-pl-220 { padding-left: 220px !important; }

.x-pl-225 { padding-left: 225px !important; }

.x-pl-230 { padding-left: 230px !important; }

.x-pl-235 { padding-left: 235px !important; }

.x-pl-240 { padding-left: 240px !important; }

.x-pl-245 { padding-left: 245px !important; }

.x-pl-250 { padding-left: 250px !important; }

/**********************************************************/
/*	Box Sizing                                            */
/**********************************************************/
/** box-sizing Emmet abbreviation: bxz */
.x-bxz-cb { box-sizing: content-box !important; }

.x-bxz-bb { box-sizing: border-box !important; }

/** box-shadow Emmet abbreviation: bxsh */
.x-bxsh-n { box-shadow: none !important; }

/** width Emmet abbreviation: w */
.x-w-a { width: auto !important; }

.x-w-0 { width: 0px !important; }

.x-w-1 { width: 1px !important; }

.x-w-2 { width: 2px !important; }

.x-w-3 { width: 3px !important; }

.x-w-4 { width: 4px !important; }

.x-w-5 { width: 5px !important; }

.x-w-6 { width: 6px !important; }

.x-w-7 { width: 7px !important; }

.x-w-8 { width: 8px !important; }

.x-w-9 { width: 9px !important; }

.x-w-10 { width: 10px !important; }

.x-w-11 { width: 11px !important; }

.x-w-12 { width: 12px !important; }

.x-w-13 { width: 13px !important; }

.x-w-14 { width: 14px !important; }

.x-w-15 { width: 15px !important; }

.x-w-16 { width: 16px !important; }

.x-w-17 { width: 17px !important; }

.x-w-18 { width: 18px !important; }

.x-w-19 { width: 19px !important; }

.x-w-20 { width: 20px !important; }

.x-w-21 { width: 21px !important; }

.x-w-22 { width: 22px !important; }

.x-w-23 { width: 23px !important; }

.x-w-24 { width: 24px !important; }

.x-w-25 { width: 25px !important; }

.x-w-26 { width: 26px !important; }

.x-w-27 { width: 27px !important; }

.x-w-28 { width: 28px !important; }

.x-w-29 { width: 29px !important; }

.x-w-30 { width: 30px !important; }

.x-w-31 { width: 31px !important; }

.x-w-32 { width: 32px !important; }

.x-w-33 { width: 33px !important; }

.x-w-34 { width: 34px !important; }

.x-w-35 { width: 35px !important; }

.x-w-36 { width: 36px !important; }

.x-w-37 { width: 37px !important; }

.x-w-38 { width: 38px !important; }

.x-w-39 { width: 39px !important; }

.x-w-40 { width: 40px !important; }

.x-w-41 { width: 41px !important; }

.x-w-42 { width: 42px !important; }

.x-w-43 { width: 43px !important; }

.x-w-44 { width: 44px !important; }

.x-w-45 { width: 45px !important; }

.x-w-46 { width: 46px !important; }

.x-w-47 { width: 47px !important; }

.x-w-48 { width: 48px !important; }

.x-w-49 { width: 49px !important; }

.x-w-50 { width: 50px !important; }

.x-w-55 { width: 55px !important; }

.x-w-60 { width: 60px !important; }

.x-w-65 { width: 65px !important; }

.x-w-70 { width: 70px !important; }

.x-w-75 { width: 75px !important; }

.x-w-80 { width: 80px !important; }

.x-w-85 { width: 85px !important; }

.x-w-90 { width: 90px !important; }

.x-w-95 { width: 95px !important; }

.x-w-100 { width: 100px !important; }

.x-w-105 { width: 105px !important; }

.x-w-110 { width: 110px !important; }

.x-w-115 { width: 115px !important; }

.x-w-120 { width: 120px !important; }

.x-w-125 { width: 125px !important; }

.x-w-130 { width: 130px !important; }

.x-w-135 { width: 135px !important; }

.x-w-140 { width: 140px !important; }

.x-w-145 { width: 145px !important; }

.x-w-150 { width: 150px !important; }

.x-w-155 { width: 155px !important; }

.x-w-160 { width: 160px !important; }

.x-w-165 { width: 165px !important; }

.x-w-170 { width: 170px !important; }

.x-w-175 { width: 175px !important; }

.x-w-180 { width: 180px !important; }

.x-w-185 { width: 185px !important; }

.x-w-190 { width: 190px !important; }

.x-w-195 { width: 195px !important; }

.x-w-200 { width: 200px !important; }

.x-w-205 { width: 205px !important; }

.x-w-210 { width: 210px !important; }

.x-w-215 { width: 215px !important; }

.x-w-220 { width: 220px !important; }

.x-w-225 { width: 225px !important; }

.x-w-230 { width: 230px !important; }

.x-w-235 { width: 235px !important; }

.x-w-240 { width: 240px !important; }

.x-w-245 { width: 245px !important; }

.x-w-250 { width: 250px !important; }

/** height Emmet abbreviation: h */
.x-h-a { height: auto !important; }

.x-h-0 { height: 0px !important; }

.x-h-1 { height: 1px !important; }

.x-h-2 { height: 2px !important; }

.x-h-3 { height: 3px !important; }

.x-h-4 { height: 4px !important; }

.x-h-5 { height: 5px !important; }

.x-h-6 { height: 6px !important; }

.x-h-7 { height: 7px !important; }

.x-h-8 { height: 8px !important; }

.x-h-9 { height: 9px !important; }

.x-h-10 { height: 10px !important; }

.x-h-11 { height: 11px !important; }

.x-h-12 { height: 12px !important; }

.x-h-13 { height: 13px !important; }

.x-h-14 { height: 14px !important; }

.x-h-15 { height: 15px !important; }

.x-h-16 { height: 16px !important; }

.x-h-17 { height: 17px !important; }

.x-h-18 { height: 18px !important; }

.x-h-19 { height: 19px !important; }

.x-h-20 { height: 20px !important; }

.x-h-21 { height: 21px !important; }

.x-h-22 { height: 22px !important; }

.x-h-23 { height: 23px !important; }

.x-h-24 { height: 24px !important; }

.x-h-25 { height: 25px !important; }

.x-h-26 { height: 26px !important; }

.x-h-27 { height: 27px !important; }

.x-h-28 { height: 28px !important; }

.x-h-29 { height: 29px !important; }

.x-h-30 { height: 30px !important; }

.x-h-31 { height: 31px !important; }

.x-h-32 { height: 32px !important; }

.x-h-33 { height: 33px !important; }

.x-h-34 { height: 34px !important; }

.x-h-35 { height: 35px !important; }

.x-h-36 { height: 36px !important; }

.x-h-37 { height: 37px !important; }

.x-h-38 { height: 38px !important; }

.x-h-39 { height: 39px !important; }

.x-h-40 { height: 40px !important; }

.x-h-41 { height: 41px !important; }

.x-h-42 { height: 42px !important; }

.x-h-43 { height: 43px !important; }

.x-h-44 { height: 44px !important; }

.x-h-45 { height: 45px !important; }

.x-h-46 { height: 46px !important; }

.x-h-47 { height: 47px !important; }

.x-h-48 { height: 48px !important; }

.x-h-49 { height: 49px !important; }

.x-h-50 { height: 50px !important; }

.x-h-55 { height: 55px !important; }

.x-h-60 { height: 60px !important; }

.x-h-65 { height: 65px !important; }

.x-h-70 { height: 70px !important; }

.x-h-75 { height: 75px !important; }

.x-h-80 { height: 80px !important; }

.x-h-85 { height: 85px !important; }

.x-h-90 { height: 90px !important; }

.x-h-95 { height: 95px !important; }

.x-h-100 { height: 100px !important; }

.x-h-105 { height: 105px !important; }

.x-h-110 { height: 110px !important; }

.x-h-115 { height: 115px !important; }

.x-h-120 { height: 120px !important; }

.x-h-125 { height: 125px !important; }

.x-h-130 { height: 130px !important; }

.x-h-135 { height: 135px !important; }

.x-h-140 { height: 140px !important; }

.x-h-145 { height: 145px !important; }

.x-h-150 { height: 150px !important; }

.x-h-155 { height: 155px !important; }

.x-h-160 { height: 160px !important; }

.x-h-165 { height: 165px !important; }

.x-h-170 { height: 170px !important; }

.x-h-175 { height: 175px !important; }

.x-h-180 { height: 180px !important; }

.x-h-185 { height: 185px !important; }

.x-h-190 { height: 190px !important; }

.x-h-195 { height: 195px !important; }

.x-h-200 { height: 200px !important; }

.x-h-205 { height: 205px !important; }

.x-h-210 { height: 210px !important; }

.x-h-215 { height: 215px !important; }

.x-h-220 { height: 220px !important; }

.x-h-225 { height: 225px !important; }

.x-h-230 { height: 230px !important; }

.x-h-235 { height: 235px !important; }

.x-h-240 { height: 240px !important; }

.x-h-245 { height: 245px !important; }

.x-h-250 { height: 250px !important; }

/** max-width Emmet abbreviation: maw */
.x-maw-n { max-width: none !important; }

.x-maw-0 { max-width: 0px !important; }

.x-maw-1 { max-width: 1px !important; }

.x-maw-2 { max-width: 2px !important; }

.x-maw-3 { max-width: 3px !important; }

.x-maw-4 { max-width: 4px !important; }

.x-maw-5 { max-width: 5px !important; }

.x-maw-6 { max-width: 6px !important; }

.x-maw-7 { max-width: 7px !important; }

.x-maw-8 { max-width: 8px !important; }

.x-maw-9 { max-width: 9px !important; }

.x-maw-10 { max-width: 10px !important; }

.x-maw-11 { max-width: 11px !important; }

.x-maw-12 { max-width: 12px !important; }

.x-maw-13 { max-width: 13px !important; }

.x-maw-14 { max-width: 14px !important; }

.x-maw-15 { max-width: 15px !important; }

.x-maw-16 { max-width: 16px !important; }

.x-maw-17 { max-width: 17px !important; }

.x-maw-18 { max-width: 18px !important; }

.x-maw-19 { max-width: 19px !important; }

.x-maw-20 { max-width: 20px !important; }

.x-maw-21 { max-width: 21px !important; }

.x-maw-22 { max-width: 22px !important; }

.x-maw-23 { max-width: 23px !important; }

.x-maw-24 { max-width: 24px !important; }

.x-maw-25 { max-width: 25px !important; }

.x-maw-26 { max-width: 26px !important; }

.x-maw-27 { max-width: 27px !important; }

.x-maw-28 { max-width: 28px !important; }

.x-maw-29 { max-width: 29px !important; }

.x-maw-30 { max-width: 30px !important; }

.x-maw-31 { max-width: 31px !important; }

.x-maw-32 { max-width: 32px !important; }

.x-maw-33 { max-width: 33px !important; }

.x-maw-34 { max-width: 34px !important; }

.x-maw-35 { max-width: 35px !important; }

.x-maw-36 { max-width: 36px !important; }

.x-maw-37 { max-width: 37px !important; }

.x-maw-38 { max-width: 38px !important; }

.x-maw-39 { max-width: 39px !important; }

.x-maw-40 { max-width: 40px !important; }

.x-maw-41 { max-width: 41px !important; }

.x-maw-42 { max-width: 42px !important; }

.x-maw-43 { max-width: 43px !important; }

.x-maw-44 { max-width: 44px !important; }

.x-maw-45 { max-width: 45px !important; }

.x-maw-46 { max-width: 46px !important; }

.x-maw-47 { max-width: 47px !important; }

.x-maw-48 { max-width: 48px !important; }

.x-maw-49 { max-width: 49px !important; }

.x-maw-50 { max-width: 50px !important; }

.x-maw-55 { max-width: 55px !important; }

.x-maw-60 { max-width: 60px !important; }

.x-maw-65 { max-width: 65px !important; }

.x-maw-70 { max-width: 70px !important; }

.x-maw-75 { max-width: 75px !important; }

.x-maw-80 { max-width: 80px !important; }

.x-maw-85 { max-width: 85px !important; }

.x-maw-90 { max-width: 90px !important; }

.x-maw-95 { max-width: 95px !important; }

.x-maw-100 { max-width: 100px !important; }

.x-maw-105 { max-width: 105px !important; }

.x-maw-110 { max-width: 110px !important; }

.x-maw-115 { max-width: 115px !important; }

.x-maw-120 { max-width: 120px !important; }

.x-maw-125 { max-width: 125px !important; }

.x-maw-130 { max-width: 130px !important; }

.x-maw-135 { max-width: 135px !important; }

.x-maw-140 { max-width: 140px !important; }

.x-maw-145 { max-width: 145px !important; }

.x-maw-150 { max-width: 150px !important; }

.x-maw-155 { max-width: 155px !important; }

.x-maw-160 { max-width: 160px !important; }

.x-maw-165 { max-width: 165px !important; }

.x-maw-170 { max-width: 170px !important; }

.x-maw-175 { max-width: 175px !important; }

.x-maw-180 { max-width: 180px !important; }

.x-maw-185 { max-width: 185px !important; }

.x-maw-190 { max-width: 190px !important; }

.x-maw-195 { max-width: 195px !important; }

.x-maw-200 { max-width: 200px !important; }

.x-maw-205 { max-width: 205px !important; }

.x-maw-210 { max-width: 210px !important; }

.x-maw-215 { max-width: 215px !important; }

.x-maw-220 { max-width: 220px !important; }

.x-maw-225 { max-width: 225px !important; }

.x-maw-230 { max-width: 230px !important; }

.x-maw-235 { max-width: 235px !important; }

.x-maw-240 { max-width: 240px !important; }

.x-maw-245 { max-width: 245px !important; }

.x-maw-250 { max-width: 250px !important; }

/** max-height Emmet abbreviation: mah */
.x-mah-n { max-height: none !important; }

.x-mah-0 { max-height: 0px !important; }

.x-mah-1 { max-height: 1px !important; }

.x-mah-2 { max-height: 2px !important; }

.x-mah-3 { max-height: 3px !important; }

.x-mah-4 { max-height: 4px !important; }

.x-mah-5 { max-height: 5px !important; }

.x-mah-6 { max-height: 6px !important; }

.x-mah-7 { max-height: 7px !important; }

.x-mah-8 { max-height: 8px !important; }

.x-mah-9 { max-height: 9px !important; }

.x-mah-10 { max-height: 10px !important; }

.x-mah-11 { max-height: 11px !important; }

.x-mah-12 { max-height: 12px !important; }

.x-mah-13 { max-height: 13px !important; }

.x-mah-14 { max-height: 14px !important; }

.x-mah-15 { max-height: 15px !important; }

.x-mah-16 { max-height: 16px !important; }

.x-mah-17 { max-height: 17px !important; }

.x-mah-18 { max-height: 18px !important; }

.x-mah-19 { max-height: 19px !important; }

.x-mah-20 { max-height: 20px !important; }

.x-mah-21 { max-height: 21px !important; }

.x-mah-22 { max-height: 22px !important; }

.x-mah-23 { max-height: 23px !important; }

.x-mah-24 { max-height: 24px !important; }

.x-mah-25 { max-height: 25px !important; }

.x-mah-26 { max-height: 26px !important; }

.x-mah-27 { max-height: 27px !important; }

.x-mah-28 { max-height: 28px !important; }

.x-mah-29 { max-height: 29px !important; }

.x-mah-30 { max-height: 30px !important; }

.x-mah-31 { max-height: 31px !important; }

.x-mah-32 { max-height: 32px !important; }

.x-mah-33 { max-height: 33px !important; }

.x-mah-34 { max-height: 34px !important; }

.x-mah-35 { max-height: 35px !important; }

.x-mah-36 { max-height: 36px !important; }

.x-mah-37 { max-height: 37px !important; }

.x-mah-38 { max-height: 38px !important; }

.x-mah-39 { max-height: 39px !important; }

.x-mah-40 { max-height: 40px !important; }

.x-mah-41 { max-height: 41px !important; }

.x-mah-42 { max-height: 42px !important; }

.x-mah-43 { max-height: 43px !important; }

.x-mah-44 { max-height: 44px !important; }

.x-mah-45 { max-height: 45px !important; }

.x-mah-46 { max-height: 46px !important; }

.x-mah-47 { max-height: 47px !important; }

.x-mah-48 { max-height: 48px !important; }

.x-mah-49 { max-height: 49px !important; }

.x-mah-50 { max-height: 50px !important; }

.x-mah-55 { max-height: 55px !important; }

.x-mah-60 { max-height: 60px !important; }

.x-mah-65 { max-height: 65px !important; }

.x-mah-70 { max-height: 70px !important; }

.x-mah-75 { max-height: 75px !important; }

.x-mah-80 { max-height: 80px !important; }

.x-mah-85 { max-height: 85px !important; }

.x-mah-90 { max-height: 90px !important; }

.x-mah-95 { max-height: 95px !important; }

.x-mah-100 { max-height: 100px !important; }

.x-mah-105 { max-height: 105px !important; }

.x-mah-110 { max-height: 110px !important; }

.x-mah-115 { max-height: 115px !important; }

.x-mah-120 { max-height: 120px !important; }

.x-mah-125 { max-height: 125px !important; }

.x-mah-130 { max-height: 130px !important; }

.x-mah-135 { max-height: 135px !important; }

.x-mah-140 { max-height: 140px !important; }

.x-mah-145 { max-height: 145px !important; }

.x-mah-150 { max-height: 150px !important; }

.x-mah-155 { max-height: 155px !important; }

.x-mah-160 { max-height: 160px !important; }

.x-mah-165 { max-height: 165px !important; }

.x-mah-170 { max-height: 170px !important; }

.x-mah-175 { max-height: 175px !important; }

.x-mah-180 { max-height: 180px !important; }

.x-mah-185 { max-height: 185px !important; }

.x-mah-190 { max-height: 190px !important; }

.x-mah-195 { max-height: 195px !important; }

.x-mah-200 { max-height: 200px !important; }

.x-mah-205 { max-height: 205px !important; }

.x-mah-210 { max-height: 210px !important; }

.x-mah-215 { max-height: 215px !important; }

.x-mah-220 { max-height: 220px !important; }

.x-mah-225 { max-height: 225px !important; }

.x-mah-230 { max-height: 230px !important; }

.x-mah-235 { max-height: 235px !important; }

.x-mah-240 { max-height: 240px !important; }

.x-mah-245 { max-height: 245px !important; }

.x-mah-250 { max-height: 250px !important; }

/** min-width Emmet abbreviation: miw */
.x-miw-0 { min-width: 0px !important; }

.x-miw-1 { min-width: 1px !important; }

.x-miw-2 { min-width: 2px !important; }

.x-miw-3 { min-width: 3px !important; }

.x-miw-4 { min-width: 4px !important; }

.x-miw-5 { min-width: 5px !important; }

.x-miw-6 { min-width: 6px !important; }

.x-miw-7 { min-width: 7px !important; }

.x-miw-8 { min-width: 8px !important; }

.x-miw-9 { min-width: 9px !important; }

.x-miw-10 { min-width: 10px !important; }

.x-miw-11 { min-width: 11px !important; }

.x-miw-12 { min-width: 12px !important; }

.x-miw-13 { min-width: 13px !important; }

.x-miw-14 { min-width: 14px !important; }

.x-miw-15 { min-width: 15px !important; }

.x-miw-16 { min-width: 16px !important; }

.x-miw-17 { min-width: 17px !important; }

.x-miw-18 { min-width: 18px !important; }

.x-miw-19 { min-width: 19px !important; }

.x-miw-20 { min-width: 20px !important; }

.x-miw-21 { min-width: 21px !important; }

.x-miw-22 { min-width: 22px !important; }

.x-miw-23 { min-width: 23px !important; }

.x-miw-24 { min-width: 24px !important; }

.x-miw-25 { min-width: 25px !important; }

.x-miw-26 { min-width: 26px !important; }

.x-miw-27 { min-width: 27px !important; }

.x-miw-28 { min-width: 28px !important; }

.x-miw-29 { min-width: 29px !important; }

.x-miw-30 { min-width: 30px !important; }

.x-miw-31 { min-width: 31px !important; }

.x-miw-32 { min-width: 32px !important; }

.x-miw-33 { min-width: 33px !important; }

.x-miw-34 { min-width: 34px !important; }

.x-miw-35 { min-width: 35px !important; }

.x-miw-36 { min-width: 36px !important; }

.x-miw-37 { min-width: 37px !important; }

.x-miw-38 { min-width: 38px !important; }

.x-miw-39 { min-width: 39px !important; }

.x-miw-40 { min-width: 40px !important; }

.x-miw-41 { min-width: 41px !important; }

.x-miw-42 { min-width: 42px !important; }

.x-miw-43 { min-width: 43px !important; }

.x-miw-44 { min-width: 44px !important; }

.x-miw-45 { min-width: 45px !important; }

.x-miw-46 { min-width: 46px !important; }

.x-miw-47 { min-width: 47px !important; }

.x-miw-48 { min-width: 48px !important; }

.x-miw-49 { min-width: 49px !important; }

.x-miw-50 { min-width: 50px !important; }

.x-miw-55 { min-width: 55px !important; }

.x-miw-60 { min-width: 60px !important; }

.x-miw-65 { min-width: 65px !important; }

.x-miw-70 { min-width: 70px !important; }

.x-miw-75 { min-width: 75px !important; }

.x-miw-80 { min-width: 80px !important; }

.x-miw-85 { min-width: 85px !important; }

.x-miw-90 { min-width: 90px !important; }

.x-miw-95 { min-width: 95px !important; }

.x-miw-100 { min-width: 100px !important; }

.x-miw-105 { min-width: 105px !important; }

.x-miw-110 { min-width: 110px !important; }

.x-miw-115 { min-width: 115px !important; }

.x-miw-120 { min-width: 120px !important; }

.x-miw-125 { min-width: 125px !important; }

.x-miw-130 { min-width: 130px !important; }

.x-miw-135 { min-width: 135px !important; }

.x-miw-140 { min-width: 140px !important; }

.x-miw-145 { min-width: 145px !important; }

.x-miw-150 { min-width: 150px !important; }

.x-miw-155 { min-width: 155px !important; }

.x-miw-160 { min-width: 160px !important; }

.x-miw-165 { min-width: 165px !important; }

.x-miw-170 { min-width: 170px !important; }

.x-miw-175 { min-width: 175px !important; }

.x-miw-180 { min-width: 180px !important; }

.x-miw-185 { min-width: 185px !important; }

.x-miw-190 { min-width: 190px !important; }

.x-miw-195 { min-width: 195px !important; }

.x-miw-200 { min-width: 200px !important; }

.x-miw-205 { min-width: 205px !important; }

.x-miw-210 { min-width: 210px !important; }

.x-miw-215 { min-width: 215px !important; }

.x-miw-220 { min-width: 220px !important; }

.x-miw-225 { min-width: 225px !important; }

.x-miw-230 { min-width: 230px !important; }

.x-miw-235 { min-width: 235px !important; }

.x-miw-240 { min-width: 240px !important; }

.x-miw-245 { min-width: 245px !important; }

.x-miw-250 { min-width: 250px !important; }

/** min-height Emmet abbreviation: mih */
.x-mih-0 { min-height: 0px !important; }

.x-mih-1 { min-height: 1px !important; }

.x-mih-2 { min-height: 2px !important; }

.x-mih-3 { min-height: 3px !important; }

.x-mih-4 { min-height: 4px !important; }

.x-mih-5 { min-height: 5px !important; }

.x-mih-6 { min-height: 6px !important; }

.x-mih-7 { min-height: 7px !important; }

.x-mih-8 { min-height: 8px !important; }

.x-mih-9 { min-height: 9px !important; }

.x-mih-10 { min-height: 10px !important; }

.x-mih-11 { min-height: 11px !important; }

.x-mih-12 { min-height: 12px !important; }

.x-mih-13 { min-height: 13px !important; }

.x-mih-14 { min-height: 14px !important; }

.x-mih-15 { min-height: 15px !important; }

.x-mih-16 { min-height: 16px !important; }

.x-mih-17 { min-height: 17px !important; }

.x-mih-18 { min-height: 18px !important; }

.x-mih-19 { min-height: 19px !important; }

.x-mih-20 { min-height: 20px !important; }

.x-mih-21 { min-height: 21px !important; }

.x-mih-22 { min-height: 22px !important; }

.x-mih-23 { min-height: 23px !important; }

.x-mih-24 { min-height: 24px !important; }

.x-mih-25 { min-height: 25px !important; }

.x-mih-26 { min-height: 26px !important; }

.x-mih-27 { min-height: 27px !important; }

.x-mih-28 { min-height: 28px !important; }

.x-mih-29 { min-height: 29px !important; }

.x-mih-30 { min-height: 30px !important; }

.x-mih-31 { min-height: 31px !important; }

.x-mih-32 { min-height: 32px !important; }

.x-mih-33 { min-height: 33px !important; }

.x-mih-34 { min-height: 34px !important; }

.x-mih-35 { min-height: 35px !important; }

.x-mih-36 { min-height: 36px !important; }

.x-mih-37 { min-height: 37px !important; }

.x-mih-38 { min-height: 38px !important; }

.x-mih-39 { min-height: 39px !important; }

.x-mih-40 { min-height: 40px !important; }

.x-mih-41 { min-height: 41px !important; }

.x-mih-42 { min-height: 42px !important; }

.x-mih-43 { min-height: 43px !important; }

.x-mih-44 { min-height: 44px !important; }

.x-mih-45 { min-height: 45px !important; }

.x-mih-46 { min-height: 46px !important; }

.x-mih-47 { min-height: 47px !important; }

.x-mih-48 { min-height: 48px !important; }

.x-mih-49 { min-height: 49px !important; }

.x-mih-50 { min-height: 50px !important; }

.x-mih-55 { min-height: 55px !important; }

.x-mih-60 { min-height: 60px !important; }

.x-mih-65 { min-height: 65px !important; }

.x-mih-70 { min-height: 70px !important; }

.x-mih-75 { min-height: 75px !important; }

.x-mih-80 { min-height: 80px !important; }

.x-mih-85 { min-height: 85px !important; }

.x-mih-90 { min-height: 90px !important; }

.x-mih-95 { min-height: 95px !important; }

.x-mih-100 { min-height: 100px !important; }

.x-mih-105 { min-height: 105px !important; }

.x-mih-110 { min-height: 110px !important; }

.x-mih-115 { min-height: 115px !important; }

.x-mih-120 { min-height: 120px !important; }

.x-mih-125 { min-height: 125px !important; }

.x-mih-130 { min-height: 130px !important; }

.x-mih-135 { min-height: 135px !important; }

.x-mih-140 { min-height: 140px !important; }

.x-mih-145 { min-height: 145px !important; }

.x-mih-150 { min-height: 150px !important; }

.x-mih-155 { min-height: 155px !important; }

.x-mih-160 { min-height: 160px !important; }

.x-mih-165 { min-height: 165px !important; }

.x-mih-170 { min-height: 170px !important; }

.x-mih-175 { min-height: 175px !important; }

.x-mih-180 { min-height: 180px !important; }

.x-mih-185 { min-height: 185px !important; }

.x-mih-190 { min-height: 190px !important; }

.x-mih-195 { min-height: 195px !important; }

.x-mih-200 { min-height: 200px !important; }

.x-mih-205 { min-height: 205px !important; }

.x-mih-210 { min-height: 210px !important; }

.x-mih-215 { min-height: 215px !important; }

.x-mih-220 { min-height: 220px !important; }

.x-mih-225 { min-height: 225px !important; }

.x-mih-230 { min-height: 230px !important; }

.x-mih-235 { min-height: 235px !important; }

.x-mih-240 { min-height: 240px !important; }

.x-mih-245 { min-height: 245px !important; }

.x-mih-250 { min-height: 250px !important; }

/**********************************************************/
/*	Font                                                  */
/**********************************************************/
/** font-weight Emmet abbreviation: fw */
.x-fw-n { font-weight: normal !important; }

.x-fw-b { font-weight: bold !important; }

.x-fw-br { font-weight: bolder !important; }

.x-fw-lr { font-weight: lighter !important; }

.x-fw-100 { font-weight: 100 !important; }

.x-fw-200 { font-weight: 200 !important; }

.x-fw-300 { font-weight: 300 !important; }

.x-fw-400 { font-weight: 400 !important; }

.x-fw-500 { font-weight: 500 !important; }

.x-fw-600 { font-weight: 600 !important; }

.x-fw-700 { font-weight: 700 !important; }

.x-fw-800 { font-weight: 800 !important; }

.x-fw-900 { font-weight: 900 !important; }

/** font-style Emmet abbreviation: fs */
.x-fs-n { font-style: normal !important; }

.x-fs-i { font-style: italic !important; }

.x-fs-o { font-style: oblique !important; }

/** font-variant Emmet abbreviation: fv */
.x-fv-n { font-variant: normal !important; }

.x-fv-sc { font-variant: small-caps !important; }

/** font-size Emmet abbreviation: fz */
.x-fz-0 { font-size: 0px !important; }

.x-fz-1 { font-size: 1px !important; }

.x-fz-2 { font-size: 2px !important; }

.x-fz-3 { font-size: 3px !important; }

.x-fz-4 { font-size: 4px !important; }

.x-fz-5 { font-size: 5px !important; }

.x-fz-6 { font-size: 6px !important; }

.x-fz-7 { font-size: 7px !important; }

.x-fz-8 { font-size: 8px !important; }

.x-fz-9 { font-size: 9px !important; }

.x-fz-10 { font-size: 10px !important; }

.x-fz-11 { font-size: 11px !important; }

.x-fz-12 { font-size: 12px !important; }

.x-fz-13 { font-size: 13px !important; }

.x-fz-14 { font-size: 14px !important; }

.x-fz-15 { font-size: 15px !important; }

.x-fz-16 { font-size: 16px !important; }

.x-fz-17 { font-size: 17px !important; }

.x-fz-18 { font-size: 18px !important; }

.x-fz-19 { font-size: 19px !important; }

.x-fz-20 { font-size: 20px !important; }

.x-fz-21 { font-size: 21px !important; }

.x-fz-22 { font-size: 22px !important; }

.x-fz-23 { font-size: 23px !important; }

.x-fz-24 { font-size: 24px !important; }

.x-fz-25 { font-size: 25px !important; }

.x-fz-26 { font-size: 26px !important; }

.x-fz-27 { font-size: 27px !important; }

.x-fz-28 { font-size: 28px !important; }

.x-fz-29 { font-size: 29px !important; }

.x-fz-30 { font-size: 30px !important; }

.x-fz-31 { font-size: 31px !important; }

.x-fz-32 { font-size: 32px !important; }

.x-fz-33 { font-size: 33px !important; }

.x-fz-34 { font-size: 34px !important; }

.x-fz-35 { font-size: 35px !important; }

.x-fz-36 { font-size: 36px !important; }

.x-fz-37 { font-size: 37px !important; }

.x-fz-38 { font-size: 38px !important; }

.x-fz-39 { font-size: 39px !important; }

.x-fz-40 { font-size: 40px !important; }

.x-fz-41 { font-size: 41px !important; }

.x-fz-42 { font-size: 42px !important; }

.x-fz-43 { font-size: 43px !important; }

.x-fz-44 { font-size: 44px !important; }

.x-fz-45 { font-size: 45px !important; }

.x-fz-46 { font-size: 46px !important; }

.x-fz-47 { font-size: 47px !important; }

.x-fz-48 { font-size: 48px !important; }

.x-fz-49 { font-size: 49px !important; }

.x-fz-50 { font-size: 50px !important; }

.x-fz-51 { font-size: 51px !important; }

.x-fz-52 { font-size: 52px !important; }

.x-fz-53 { font-size: 53px !important; }

.x-fz-54 { font-size: 54px !important; }

.x-fz-55 { font-size: 55px !important; }

.x-fz-56 { font-size: 56px !important; }

.x-fz-57 { font-size: 57px !important; }

.x-fz-58 { font-size: 58px !important; }

.x-fz-59 { font-size: 59px !important; }

.x-fz-60 { font-size: 60px !important; }

.x-fz-61 { font-size: 61px !important; }

.x-fz-62 { font-size: 62px !important; }

.x-fz-63 { font-size: 63px !important; }

.x-fz-64 { font-size: 64px !important; }

.x-fz-65 { font-size: 65px !important; }

.x-fz-66 { font-size: 66px !important; }

.x-fz-67 { font-size: 67px !important; }

.x-fz-68 { font-size: 68px !important; }

.x-fz-69 { font-size: 69px !important; }

.x-fz-70 { font-size: 70px !important; }

.x-fz-71 { font-size: 71px !important; }

.x-fz-72 { font-size: 72px !important; }

.x-fz-73 { font-size: 73px !important; }

.x-fz-74 { font-size: 74px !important; }

.x-fz-75 { font-size: 75px !important; }

.x-fz-76 { font-size: 76px !important; }

.x-fz-77 { font-size: 77px !important; }

.x-fz-78 { font-size: 78px !important; }

.x-fz-79 { font-size: 79px !important; }

.x-fz-80 { font-size: 80px !important; }

.x-fz-81 { font-size: 81px !important; }

.x-fz-82 { font-size: 82px !important; }

.x-fz-83 { font-size: 83px !important; }

.x-fz-84 { font-size: 84px !important; }

.x-fz-85 { font-size: 85px !important; }

.x-fz-86 { font-size: 86px !important; }

.x-fz-87 { font-size: 87px !important; }

.x-fz-88 { font-size: 88px !important; }

.x-fz-89 { font-size: 89px !important; }

.x-fz-90 { font-size: 90px !important; }

.x-fz-91 { font-size: 91px !important; }

.x-fz-92 { font-size: 92px !important; }

.x-fz-93 { font-size: 93px !important; }

.x-fz-94 { font-size: 94px !important; }

.x-fz-95 { font-size: 95px !important; }

.x-fz-96 { font-size: 96px !important; }

.x-fz-97 { font-size: 97px !important; }

.x-fz-98 { font-size: 98px !important; }

.x-fz-99 { font-size: 99px !important; }

.x-fz-100 { font-size: 100px !important; }

.x-fz-0-em { font-size: 0em !important; }

.x-fz-1-em { font-size: 1em !important; }

.x-fz-2-em { font-size: 2em !important; }

.x-fz-3-em { font-size: 3em !important; }

.x-fz-4-em { font-size: 4em !important; }

.x-fz-5-em { font-size: 5em !important; }

.x-fz-6-em { font-size: 6em !important; }

.x-fz-7-em { font-size: 7em !important; }

.x-fz-8-em { font-size: 8em !important; }

.x-fz-9-em { font-size: 9em !important; }

.x-fz-10-em { font-size: 10em !important; }

/** font-size-adjust Emmet abbreviation: fza */
.x-fza-n { font-size-adjust: none !important; }

/** font-family Emmet abbreviation: ff */
.x-ff-s { font-family: serif !important; }

.x-ff-ss { font-family: sans-serif !important; }

.x-ff-c { font-family: cursive !important; }

.x-ff-f { font-family: fantasy !important; }

.x-ff-m { font-family: monospace !important; }

.x-ff-a { font-family: Arial,'Helvetica Neue',Helvetica,sans-serif !important; }

.x-ff-t { font-family: 'Times New Roman',Times,Baskerville,Georgia,serif !important; }

.x-ff-v { font-family: Verdana,Geneva,sans-serif !important; }

/** font-effect Emmet abbreviation: fef */
.x-fef-n { font-effect: none !important; }

.x-fef-eg { font-effect: engrave !important; }

.x-fef-eb { font-effect: emboss !important; }

.x-fef-o { font-effect: outline !important; }

/** font-emphasize-position Emmet abbreviation: femp */
.x-femp-b { font-emphasize-position: before !important; }

.x-femp-a { font-emphasize-position: after !important; }

/** font-emphasize-style Emmet abbreviation: fems */
.x-fems-n { font-emphasize-style: none !important; }

.x-fems-ac { font-emphasize-style: accent !important; }

.x-fems-dt { font-emphasize-style: dot !important; }

.x-fems-c { font-emphasize-style: circle !important; }

.x-fems-ds { font-emphasize-style: disc !important; }

/** font-smooth Emmet abbreviation: fsm */
.x-fsm-a { font-smooth: auto !important; }

.x-fsm-n { font-smooth: never !important; }

.x-fsm-aw { font-smooth: always !important; }

/** font-stretch Emmet abbreviation: fst */
.x-fst-n { font-stretch: normal !important; }

.x-fst-uc { font-stretch: ultra-condensed !important; }

.x-fst-ec { font-stretch: extra-condensed !important; }

.x-fst-c { font-stretch: condensed !important; }

.x-fst-sc { font-stretch: semi-condensed !important; }

.x-fst-se { font-stretch: semi-expanded !important; }

.x-fst-e { font-stretch: expanded !important; }

.x-fst-ee { font-stretch: extra-expanded !important; }

.x-fst-ue { font-stretch: ultra-expanded !important; }

/**********************************************************/
/*	Text                                                  */
/**********************************************************/
/** vertical-align Emmet abbreviation: va */
.x-va-sup { vertical-align: super !important; }

.x-va-t { vertical-align: top !important; }

.x-va-tt { vertical-align: text-top !important; }

.x-va-m { vertical-align: middle !important; }

.x-va-bl { vertical-align: baseline !important; }

.x-va-b { vertical-align: bottom !important; }

.x-va-tb { vertical-align: text-bottom !important; }

.x-va-sub { vertical-align: sub !important; }

/** text-align Emmet abbreviation: ta */
.x-ta-l { text-align: left !important; }

.x-ta-c { text-align: center !important; }

.x-ta-r { text-align: right !important; }

.x-ta-j { text-align: justify !important; }

/** text-align-last Emmet abbreviation: tal */
.x-tal-a { text-align-last: auto !important; }

.x-tal-l { text-align-last: left !important; }

.x-tal-c { text-align-last: center !important; }

.x-tal-r { text-align-last: right !important; }

/** text-decoration Emmet abbreviation: td */
.x-td-n { text-decoration: none !important; }

.x-td-u { text-decoration: underline !important; }

.x-td-o { text-decoration: overline !important; }

.x-td-l { text-decoration: line-through !important; }

/** text-emphasis Emmet abbreviation: te */
.x-te-n { text-emphasis: none !important; }

.x-te-ac { text-emphasis: accent !important; }

.x-te-dt { text-emphasis: dot !important; }

.x-te-c { text-emphasis: circle !important; }

.x-te-ds { text-emphasis: disc !important; }

.x-te-b { text-emphasis: before !important; }

.x-te-a { text-emphasis: after !important; }

/** text-height Emmet abbreviation: th */
.x-th-a { text-height: auto !important; }

.x-th-f { text-height: font-size !important; }

.x-th-t { text-height: text-size !important; }

.x-th-m { text-height: max-size !important; }

/** text-indent Emmet abbreviation: ti */
.x-ti-0 { text-indent: 0px !important; }

.x-ti-5 { text-indent: 5px !important; }

.x-ti-10 { text-indent: 10px !important; }

.x-ti-15 { text-indent: 15px !important; }

.x-ti-20 { text-indent: 20px !important; }

.x-ti-25 { text-indent: 25px !important; }

.x-ti-30 { text-indent: 30px !important; }

.x-ti-35 { text-indent: 35px !important; }

.x-ti-40 { text-indent: 40px !important; }

.x-ti-45 { text-indent: 45px !important; }

.x-ti-50 { text-indent: 50px !important; }

.x-ti-0-em { text-indent: 0em !important; }

.x-ti-1-em { text-indent: 1em !important; }

.x-ti-2-em { text-indent: 2em !important; }

.x-ti-3-em { text-indent: 3em !important; }

.x-ti-4-em { text-indent: 4em !important; }

.x-ti-5-em { text-indent: 5em !important; }

.x-ti-6-em { text-indent: 6em !important; }

.x-ti-7-em { text-indent: 7em !important; }

.x-ti-8-em { text-indent: 8em !important; }

.x-ti-9-em { text-indent: 9em !important; }

.x-ti-10-em { text-indent: 10em !important; }

/** text-justify Emmet abbreviation: tj */
.x-tj-a { text-justify: auto !important; }

.x-tj-iw { text-justify: inter-word !important; }

.x-tj-ii { text-justify: inter-ideograph !important; }

.x-tj-ic { text-justify: inter-cluster !important; }

.x-tj-d { text-justify: distribute !important; }

.x-tj-k { text-justify: kashida !important; }

.x-tj-t { text-justify: tibetan !important; }

/** text-outline Emmet abbreviation: to */
.x-to-n { text-outline: none !important; }

/** text-replace Emmet abbreviation: tr */
.x-tr-n { text-replace: none !important; }

/** text-transform Emmet abbreviation: tt */
.x-tt-n { text-transform: none !important; }

.x-tt-c { text-transform: capitalize !important; }

.x-tt-u { text-transform: uppercase !important; }

.x-tt-l { text-transform: lowercase !important; }

/** text-wrap Emmet abbreviation: tw */
.x-tw-n { text-wrap: normal !important; }

.x-tw-no { text-wrap: none !important; }

.x-tw-u { text-wrap: unrestricted !important; }

.x-tw-s { text-wrap: suppress !important; }

/** text-shadow Emmet abbreviation: tsh */
.x-tsh-n { text-shadow: none !important; }

/** text-overflow Emmet abbreviation: tov */
.x-tov-c { text-overflow: clip !important; }

.x-tov-e { text-overflow: ellipsis !important; }

/** line-height Emmet abbreviation: lh */
.x-lh-0 { line-height: 0px !important; }

.x-lh-1 { line-height: 1px !important; }

.x-lh-2 { line-height: 2px !important; }

.x-lh-3 { line-height: 3px !important; }

.x-lh-4 { line-height: 4px !important; }

.x-lh-5 { line-height: 5px !important; }

.x-lh-6 { line-height: 6px !important; }

.x-lh-7 { line-height: 7px !important; }

.x-lh-8 { line-height: 8px !important; }

.x-lh-9 { line-height: 9px !important; }

.x-lh-10 { line-height: 10px !important; }

.x-lh-11 { line-height: 11px !important; }

.x-lh-12 { line-height: 12px !important; }

.x-lh-13 { line-height: 13px !important; }

.x-lh-14 { line-height: 14px !important; }

.x-lh-15 { line-height: 15px !important; }

.x-lh-16 { line-height: 16px !important; }

.x-lh-17 { line-height: 17px !important; }

.x-lh-18 { line-height: 18px !important; }

.x-lh-19 { line-height: 19px !important; }

.x-lh-20 { line-height: 20px !important; }

.x-lh-21 { line-height: 21px !important; }

.x-lh-22 { line-height: 22px !important; }

.x-lh-23 { line-height: 23px !important; }

.x-lh-24 { line-height: 24px !important; }

.x-lh-25 { line-height: 25px !important; }

.x-lh-26 { line-height: 26px !important; }

.x-lh-27 { line-height: 27px !important; }

.x-lh-28 { line-height: 28px !important; }

.x-lh-29 { line-height: 29px !important; }

.x-lh-30 { line-height: 30px !important; }

.x-lh-31 { line-height: 31px !important; }

.x-lh-32 { line-height: 32px !important; }

.x-lh-33 { line-height: 33px !important; }

.x-lh-34 { line-height: 34px !important; }

.x-lh-35 { line-height: 35px !important; }

.x-lh-36 { line-height: 36px !important; }

.x-lh-37 { line-height: 37px !important; }

.x-lh-38 { line-height: 38px !important; }

.x-lh-39 { line-height: 39px !important; }

.x-lh-40 { line-height: 40px !important; }

.x-lh-41 { line-height: 41px !important; }

.x-lh-42 { line-height: 42px !important; }

.x-lh-43 { line-height: 43px !important; }

.x-lh-44 { line-height: 44px !important; }

.x-lh-45 { line-height: 45px !important; }

.x-lh-46 { line-height: 46px !important; }

.x-lh-47 { line-height: 47px !important; }

.x-lh-48 { line-height: 48px !important; }

.x-lh-49 { line-height: 49px !important; }

.x-lh-50 { line-height: 50px !important; }

.x-lh-55 { line-height: 55px !important; }

.x-lh-60 { line-height: 60px !important; }

.x-lh-65 { line-height: 65px !important; }

.x-lh-70 { line-height: 70px !important; }

.x-lh-75 { line-height: 75px !important; }

.x-lh-80 { line-height: 80px !important; }

.x-lh-85 { line-height: 85px !important; }

.x-lh-90 { line-height: 90px !important; }

.x-lh-95 { line-height: 95px !important; }

.x-lh-100 { line-height: 100px !important; }

.x-lh-105 { line-height: 105px !important; }

.x-lh-110 { line-height: 110px !important; }

.x-lh-115 { line-height: 115px !important; }

.x-lh-120 { line-height: 120px !important; }

.x-lh-125 { line-height: 125px !important; }

.x-lh-130 { line-height: 130px !important; }

.x-lh-135 { line-height: 135px !important; }

.x-lh-140 { line-height: 140px !important; }

.x-lh-145 { line-height: 145px !important; }

.x-lh-150 { line-height: 150px !important; }

.x-lh-155 { line-height: 155px !important; }

.x-lh-160 { line-height: 160px !important; }

.x-lh-165 { line-height: 165px !important; }

.x-lh-170 { line-height: 170px !important; }

.x-lh-175 { line-height: 175px !important; }

.x-lh-180 { line-height: 180px !important; }

.x-lh-185 { line-height: 185px !important; }

.x-lh-190 { line-height: 190px !important; }

.x-lh-195 { line-height: 195px !important; }

.x-lh-200 { line-height: 200px !important; }

.x-lh-205 { line-height: 205px !important; }

.x-lh-210 { line-height: 210px !important; }

.x-lh-215 { line-height: 215px !important; }

.x-lh-220 { line-height: 220px !important; }

.x-lh-225 { line-height: 225px !important; }

.x-lh-230 { line-height: 230px !important; }

.x-lh-235 { line-height: 235px !important; }

.x-lh-240 { line-height: 240px !important; }

.x-lh-245 { line-height: 245px !important; }

.x-lh-250 { line-height: 250px !important; }

/** letter-spacing Emmet abbreviation: lts */
.x-lts-n { letter-spacing: normal !important; }

.x-lts-0 { letter-spacing: 0px !important; }

.x-lts-1 { letter-spacing: 1px !important; }

.x-lts-2 { letter-spacing: 2px !important; }

.x-lts-3 { letter-spacing: 3px !important; }

.x-lts-4 { letter-spacing: 4px !important; }

.x-lts-5 { letter-spacing: 5px !important; }

.x-lts-6 { letter-spacing: 6px !important; }

.x-lts-7 { letter-spacing: 7px !important; }

.x-lts-8 { letter-spacing: 8px !important; }

.x-lts-9 { letter-spacing: 9px !important; }

.x-lts-10 { letter-spacing: 10px !important; }

/** white-space Emmet abbreviation: whs */
.x-whs-n { white-space: normal !important; }

.x-whs-p { white-space: pre !important; }

.x-whs-nw { white-space: nowrap !important; }

.x-whs-pw { white-space: pre-wrap !important; }

.x-whs-pl { white-space: pre-line !important; }

/** white-space-collapse Emmet abbreviation: whsc */
.x-whsc-n { white-space-collapse: normal !important; }

.x-whsc-k { white-space-collapse: keep-all !important; }

.x-whsc-l { white-space-collapse: loose !important; }

.x-whsc-bs { white-space-collapse: break-strict !important; }

.x-whsc-ba { white-space-collapse: break-all !important; }

/** word-break Emmet abbreviation: wob */
.x-wob-n { word-break: normal !important; }

.x-wob-k { word-break: keep-all !important; }

.x-wob-ba { word-break: break-all !important; }

/** word-spacing Emmet abbreviation: wos */
.x-wos-0 { word-spacing: 0px !important; }

.x-wos-1 { word-spacing: 1px !important; }

.x-wos-2 { word-spacing: 2px !important; }

.x-wos-3 { word-spacing: 3px !important; }

.x-wos-4 { word-spacing: 4px !important; }

.x-wos-5 { word-spacing: 5px !important; }

.x-wos-6 { word-spacing: 6px !important; }

.x-wos-7 { word-spacing: 7px !important; }

.x-wos-8 { word-spacing: 8px !important; }

.x-wos-9 { word-spacing: 9px !important; }

.x-wos-10 { word-spacing: 10px !important; }

.x-wos-11 { word-spacing: 11px !important; }

.x-wos-12 { word-spacing: 12px !important; }

.x-wos-13 { word-spacing: 13px !important; }

.x-wos-14 { word-spacing: 14px !important; }

.x-wos-15 { word-spacing: 15px !important; }

.x-wos-16 { word-spacing: 16px !important; }

.x-wos-17 { word-spacing: 17px !important; }

.x-wos-18 { word-spacing: 18px !important; }

.x-wos-19 { word-spacing: 19px !important; }

.x-wos-20 { word-spacing: 20px !important; }

.x-wos-21 { word-spacing: 21px !important; }

.x-wos-22 { word-spacing: 22px !important; }

.x-wos-23 { word-spacing: 23px !important; }

.x-wos-24 { word-spacing: 24px !important; }

.x-wos-25 { word-spacing: 25px !important; }

/** word-wrap Emmet abbreviation: wow */
.x-wow-nm { word-wrap: normal !important; }

.x-wow-n { word-wrap: none !important; }

.x-wow-u { word-wrap: unrestricted !important; }

.x-wow-s { word-wrap: suppress !important; }

.x-wow-b { word-wrap: break-word !important; }

/**********************************************************/
/*	Background                                            */
/**********************************************************/
/** background Emmet abbreviation: bg */
.x-bg-n { background: none !important; }

/** background-color Emmet abbreviation: bgc */
.x-bgc-000 { background-color: #000 !important; }

.x-bgc-111 { background-color: #111 !important; }

.x-bgc-222 { background-color: #222 !important; }

.x-bgc-333 { background-color: #333 !important; }

.x-bgc-444 { background-color: #444 !important; }

.x-bgc-555 { background-color: #555 !important; }

.x-bgc-666 { background-color: #666 !important; }

.x-bgc-777 { background-color: #777 !important; }

.x-bgc-888 { background-color: #888 !important; }

.x-bgc-999 { background-color: #999 !important; }

.x-bgc-aaa { background-color: #aaa !important; }

.x-bgc-bbb { background-color: #bbb !important; }

.x-bgc-ccc { background-color: #ccc !important; }

.x-bgc-ddd { background-color: #ddd !important; }

.x-bgc-eee { background-color: #eee !important; }

.x-bgc-fff { background-color: #fff !important; }

.x-bgc-t { background-color: transparent !important; }

.x-bgc-transparent { background-color: transparent !important; }

.x-bgc-aqua { background-color: aqua !important; }

.x-bgc-black { background-color: black !important; }

.x-bgc-blue { background-color: blue !important; }

.x-bgc-brown { background-color: brown !important; }

.x-bgc-crimson { background-color: crimson !important; }

.x-bgc-cyan { background-color: cyan !important; }

.x-bgc-darkblue { background-color: darkblue !important; }

.x-bgc-darkgreen { background-color: darkgreen !important; }

.x-bgc-darkorange { background-color: darkorange !important; }

.x-bgc-darkred { background-color: darkred !important; }

.x-bgc-fuchsia { background-color: fuchsia !important; }

.x-bgc-gold { background-color: gold !important; }

.x-bgc-gray { background-color: gray !important; }

.x-bgc-green { background-color: green !important; }

.x-bgc-lavender { background-color: lavender !important; }

.x-bgc-magenta { background-color: magenta !important; }

.x-bgc-maroon { background-color: maroon !important; }

.x-bgc-navy { background-color: navy !important; }

.x-bgc-olive { background-color: olive !important; }

.x-bgc-orange { background-color: orange !important; }

.x-bgc-pink { background-color: pink !important; }

.x-bgc-purple { background-color: purple !important; }

.x-bgc-red { background-color: red !important; }

.x-bgc-silver { background-color: silver !important; }

.x-bgc-turquoise { background-color: turquoise !important; }

.x-bgc-white { background-color: white !important; }

.x-bgc-yellow { background-color: yellow !important; }

/** background-image Emmet abbreviation: bgi */
.x-bgi-n { background-image: none !important; }

/** background-repeat Emmet abbreviation: bgr */
.x-bgr-n { background-repeat: no-repeat !important; }

.x-bgr-x { background-repeat: repeat-x !important; }

.x-bgr-y { background-repeat: repeat-y !important; }

.x-bgr-sp { background-repeat: space !important; }

.x-bgr-rd { background-repeat: round !important; }

/** background-attachment Emmet abbreviation: bga */
.x-bga-f { background-attachment: fixed !important; }

.x-bga-s { background-attachment: scroll !important; }

/** background-position-x Emmet abbreviation: bgpx */
/** background-position-y Emmet abbreviation: bgpy */
/** background-break Emmet abbreviation: bgbk */
.x-bgbk-bb { background-break: bounding-box !important; }

.x-bgbk-eb { background-break: each-box !important; }

.x-bgbk-c { background-break: continuous !important; }

/** background-clip Emmet abbreviation: bgcp */
.x-bgcp-bb { background-clip: border-box !important; }

.x-bgcp-pb { background-clip: padding-box !important; }

.x-bgcp-cb { background-clip: content-box !important; }

.x-bgcp-nc { background-clip: no-clip !important; }

/** background-origin Emmet abbreviation: bgo */
.x-bgo-pb { background-origin: padding-box !important; }

.x-bgo-bb { background-origin: border-box !important; }

.x-bgo-cb { background-origin: content-box !important; }

/** background-size Emmet abbreviation: bgsz */
.x-bgsz-a { background-size: auto !important; }

.x-bgsz-ct { background-size: contain !important; }

.x-bgsz-cv { background-size: cover !important; }

/**********************************************************/
/*	Color                                                 */
/**********************************************************/
/** color Emmet abbreviation: c */
.x-c-000 { color: #000 !important; }

.x-c-111 { color: #111 !important; }

.x-c-222 { color: #222 !important; }

.x-c-333 { color: #333 !important; }

.x-c-444 { color: #444 !important; }

.x-c-555 { color: #555 !important; }

.x-c-666 { color: #666 !important; }

.x-c-777 { color: #777 !important; }

.x-c-888 { color: #888 !important; }

.x-c-999 { color: #999 !important; }

.x-c-aaa { color: #aaa !important; }

.x-c-bbb { color: #bbb !important; }

.x-c-ccc { color: #ccc !important; }

.x-c-ddd { color: #ddd !important; }

.x-c-eee { color: #eee !important; }

.x-c-fff { color: #fff !important; }

.x-c-t { color: transparent !important; }

.x-c-transparent { color: transparent !important; }

.x-c-aqua { color: aqua !important; }

.x-c-black { color: black !important; }

.x-c-blue { color: blue !important; }

.x-c-brown { color: brown !important; }

.x-c-crimson { color: crimson !important; }

.x-c-cyan { color: cyan !important; }

.x-c-darkblue { color: darkblue !important; }

.x-c-darkgreen { color: darkgreen !important; }

.x-c-darkorange { color: darkorange !important; }

.x-c-darkred { color: darkred !important; }

.x-c-fuchsia { color: fuchsia !important; }

.x-c-gold { color: gold !important; }

.x-c-gray { color: gray !important; }

.x-c-green { color: green !important; }

.x-c-lavender { color: lavender !important; }

.x-c-magenta { color: magenta !important; }

.x-c-maroon { color: maroon !important; }

.x-c-navy { color: navy !important; }

.x-c-olive { color: olive !important; }

.x-c-orange { color: orange !important; }

.x-c-pink { color: pink !important; }

.x-c-purple { color: purple !important; }

.x-c-red { color: red !important; }

.x-c-silver { color: silver !important; }

.x-c-turquoise { color: turquoise !important; }

.x-c-white { color: white !important; }

.x-c-yellow { color: yellow !important; }

/** opacity Emmet abbreviation: op */
.x-op-0 { opacity: 0 !important; }

.x-op-1 { opacity: 1 !important; }

.x-op-01 { opacity: 0.1 !important; }

.x-op-02 { opacity: 0.2 !important; }

.x-op-03 { opacity: 0.3 !important; }

.x-op-04 { opacity: 0.4 !important; }

.x-op-05 { opacity: 0.5 !important; }

.x-op-06 { opacity: 0.6 !important; }

.x-op-07 { opacity: 0.7 !important; }

.x-op-08 { opacity: 0.8 !important; }

.x-op-09 { opacity: 0.9 !important; }

/**********************************************************/
/*	Generated content                                     */
/**********************************************************/
/** content Emmet abbreviation: cnt, ct */
.x-cnt-n { content: normal !important; }

.x-cnt-oq { content: open-quote !important; }

.x-cnt-noq { content: no-open-quote !important; }

.x-cnt-cq { content: close-quote !important; }

.x-cnt-ncq { content: no-close-quote !important; }

.x-ct-n { content: normal !important; }

.x-ct-oq { content: open-quote !important; }

.x-ct-noq { content: no-open-quote !important; }

.x-ct-cq { content: close-quote !important; }

.x-ct-ncq { content: no-close-quote !important; }

/** quotes Emmet abbreviation: q */
.x-q-n { quotes: none !important; }

.x-q-ru { quotes: '«' '»' '„' '“' !important; }

.x-q-en { quotes: '“' '”' '‘' '’' !important; }

/**********************************************************/
/*	Outline                                               */
/**********************************************************/
/** outline Emmet abbreviation: ol */
.x-ol-n { outline: none !important; }

/** outline-offset Emmet abbreviation: olo */
.x-olo-0 { outline-offset: 0px !important; }

.x-olo-1 { outline-offset: 1px !important; }

.x-olo-2 { outline-offset: 2px !important; }

.x-olo-3 { outline-offset: 3px !important; }

.x-olo-4 { outline-offset: 4px !important; }

.x-olo-5 { outline-offset: 5px !important; }

.x-olo-6 { outline-offset: 6px !important; }

.x-olo-7 { outline-offset: 7px !important; }

.x-olo-8 { outline-offset: 8px !important; }

.x-olo-9 { outline-offset: 9px !important; }

.x-olo-10 { outline-offset: 10px !important; }

.x-olo-11 { outline-offset: 11px !important; }

.x-olo-12 { outline-offset: 12px !important; }

.x-olo-13 { outline-offset: 13px !important; }

.x-olo-14 { outline-offset: 14px !important; }

.x-olo-15 { outline-offset: 15px !important; }

.x-olo-16 { outline-offset: 16px !important; }

.x-olo-17 { outline-offset: 17px !important; }

.x-olo-18 { outline-offset: 18px !important; }

.x-olo-19 { outline-offset: 19px !important; }

.x-olo-20 { outline-offset: 20px !important; }

.x-olo-21 { outline-offset: 21px !important; }

.x-olo-22 { outline-offset: 22px !important; }

.x-olo-23 { outline-offset: 23px !important; }

.x-olo-24 { outline-offset: 24px !important; }

.x-olo-25 { outline-offset: 25px !important; }

/** outline-width Emmet abbreviation: olw */
.x-olw-tn { outline-width: thin !important; }

.x-olw-m { outline-width: medium !important; }

.x-olw-tc { outline-width: thick !important; }

.x-olw-0 { outline-width: 0px !important; }

.x-olw-1 { outline-width: 1px !important; }

.x-olw-2 { outline-width: 2px !important; }

.x-olw-3 { outline-width: 3px !important; }

.x-olw-4 { outline-width: 4px !important; }

.x-olw-5 { outline-width: 5px !important; }

.x-olw-6 { outline-width: 6px !important; }

.x-olw-7 { outline-width: 7px !important; }

.x-olw-8 { outline-width: 8px !important; }

.x-olw-9 { outline-width: 9px !important; }

.x-olw-10 { outline-width: 10px !important; }

.x-olw-11 { outline-width: 11px !important; }

.x-olw-12 { outline-width: 12px !important; }

.x-olw-13 { outline-width: 13px !important; }

.x-olw-14 { outline-width: 14px !important; }

.x-olw-15 { outline-width: 15px !important; }

.x-olw-16 { outline-width: 16px !important; }

.x-olw-17 { outline-width: 17px !important; }

.x-olw-18 { outline-width: 18px !important; }

.x-olw-19 { outline-width: 19px !important; }

.x-olw-20 { outline-width: 20px !important; }

.x-olw-21 { outline-width: 21px !important; }

.x-olw-22 { outline-width: 22px !important; }

.x-olw-23 { outline-width: 23px !important; }

.x-olw-24 { outline-width: 24px !important; }

.x-olw-25 { outline-width: 25px !important; }

/** outline-style Emmet abbreviation: ols */
.x-ols-n { outline-style: none !important; }

.x-ols-dt { outline-style: dotted !important; }

.x-ols-ds { outline-style: dashed !important; }

.x-ols-s { outline-style: solid !important; }

.x-ols-db { outline-style: double !important; }

.x-ols-g { outline-style: groove !important; }

.x-ols-r { outline-style: ridge !important; }

.x-ols-i { outline-style: inset !important; }

.x-ols-o { outline-style: outset !important; }

/** outline-color Emmet abbreviation: olc */
.x-olc-i { outline-color: invert !important; }

.x-olc-000 { outline-color: #000 !important; }

.x-olc-111 { outline-color: #111 !important; }

.x-olc-222 { outline-color: #222 !important; }

.x-olc-333 { outline-color: #333 !important; }

.x-olc-444 { outline-color: #444 !important; }

.x-olc-555 { outline-color: #555 !important; }

.x-olc-666 { outline-color: #666 !important; }

.x-olc-777 { outline-color: #777 !important; }

.x-olc-888 { outline-color: #888 !important; }

.x-olc-999 { outline-color: #999 !important; }

.x-olc-aaa { outline-color: #aaa !important; }

.x-olc-bbb { outline-color: #bbb !important; }

.x-olc-ccc { outline-color: #ccc !important; }

.x-olc-ddd { outline-color: #ddd !important; }

.x-olc-eee { outline-color: #eee !important; }

.x-olc-fff { outline-color: #fff !important; }

.x-olc-t { outline-color: transparent !important; }

.x-olc-transparent { outline-color: transparent !important; }

.x-olc-aqua { outline-color: aqua !important; }

.x-olc-black { outline-color: black !important; }

.x-olc-blue { outline-color: blue !important; }

.x-olc-brown { outline-color: brown !important; }

.x-olc-crimson { outline-color: crimson !important; }

.x-olc-cyan { outline-color: cyan !important; }

.x-olc-darkblue { outline-color: darkblue !important; }

.x-olc-darkgreen { outline-color: darkgreen !important; }

.x-olc-darkorange { outline-color: darkorange !important; }

.x-olc-darkred { outline-color: darkred !important; }

.x-olc-fuchsia { outline-color: fuchsia !important; }

.x-olc-gold { outline-color: gold !important; }

.x-olc-gray { outline-color: gray !important; }

.x-olc-green { outline-color: green !important; }

.x-olc-lavender { outline-color: lavender !important; }

.x-olc-magenta { outline-color: magenta !important; }

.x-olc-maroon { outline-color: maroon !important; }

.x-olc-navy { outline-color: navy !important; }

.x-olc-olive { outline-color: olive !important; }

.x-olc-orange { outline-color: orange !important; }

.x-olc-pink { outline-color: pink !important; }

.x-olc-purple { outline-color: purple !important; }

.x-olc-red { outline-color: red !important; }

.x-olc-silver { outline-color: silver !important; }

.x-olc-turquoise { outline-color: turquoise !important; }

.x-olc-white { outline-color: white !important; }

.x-olc-yellow { outline-color: yellow !important; }

/**********************************************************/
/*	Tables                                                */
/**********************************************************/
/** table-layout Emmet abbreviation: tbl */
.x-tbl-a { table-layout: auto !important; }

.x-tbl-f { table-layout: fixed !important; }

/** caption-side Emmet abbreviation: cps */
.x-cps-t { caption-side: top !important; }

.x-cps-b { caption-side: bottom !important; }

/** empty-cells Emmet abbreviation: ec */
.x-ec-s { empty-cells: show !important; }

.x-ec-h { empty-cells: hide !important; }

/**********************************************************/
/*	Border                                                */
/**********************************************************/
/** border Emmet abbreviation: bd */
.x-bd-n { border: none !important; }

/** border-break Emmet abbreviation: bdbk */
.x-bdbk-c { border-break: close !important; }

/** border-collapse Emmet abbreviation: bdcl */
.x-bdcl-c { border-collapse: collapse !important; }

.x-bdcl-s { border-collapse: separate !important; }

/** border-color Emmet abbreviation: bdc */
.x-bdc-000 { border-color: #000 !important; }

.x-bdc-111 { border-color: #111 !important; }

.x-bdc-222 { border-color: #222 !important; }

.x-bdc-333 { border-color: #333 !important; }

.x-bdc-444 { border-color: #444 !important; }

.x-bdc-555 { border-color: #555 !important; }

.x-bdc-666 { border-color: #666 !important; }

.x-bdc-777 { border-color: #777 !important; }

.x-bdc-888 { border-color: #888 !important; }

.x-bdc-999 { border-color: #999 !important; }

.x-bdc-aaa { border-color: #aaa !important; }

.x-bdc-bbb { border-color: #bbb !important; }

.x-bdc-ccc { border-color: #ccc !important; }

.x-bdc-ddd { border-color: #ddd !important; }

.x-bdc-eee { border-color: #eee !important; }

.x-bdc-fff { border-color: #fff !important; }

.x-bdc-t { border-color: transparent !important; }

.x-bdc-transparent { border-color: transparent !important; }

.x-bdc-aqua { border-color: aqua !important; }

.x-bdc-black { border-color: black !important; }

.x-bdc-blue { border-color: blue !important; }

.x-bdc-brown { border-color: brown !important; }

.x-bdc-crimson { border-color: crimson !important; }

.x-bdc-cyan { border-color: cyan !important; }

.x-bdc-darkblue { border-color: darkblue !important; }

.x-bdc-darkgreen { border-color: darkgreen !important; }

.x-bdc-darkorange { border-color: darkorange !important; }

.x-bdc-darkred { border-color: darkred !important; }

.x-bdc-fuchsia { border-color: fuchsia !important; }

.x-bdc-gold { border-color: gold !important; }

.x-bdc-gray { border-color: gray !important; }

.x-bdc-green { border-color: green !important; }

.x-bdc-lavender { border-color: lavender !important; }

.x-bdc-magenta { border-color: magenta !important; }

.x-bdc-maroon { border-color: maroon !important; }

.x-bdc-navy { border-color: navy !important; }

.x-bdc-olive { border-color: olive !important; }

.x-bdc-orange { border-color: orange !important; }

.x-bdc-pink { border-color: pink !important; }

.x-bdc-purple { border-color: purple !important; }

.x-bdc-red { border-color: red !important; }

.x-bdc-silver { border-color: silver !important; }

.x-bdc-turquoise { border-color: turquoise !important; }

.x-bdc-white { border-color: white !important; }

.x-bdc-yellow { border-color: yellow !important; }

/** border-image Emmet abbreviation: bdi */
.x-bdi-n { border-image: none !important; }

/** border-top-image Emmet abbreviation: bdti */
.x-bdti-n { border-top-image: none !important; }

/** border-right-image Emmet abbreviation: bdri */
.x-bdri-n { border-right-image: none !important; }

/** border-bottom-image Emmet abbreviation: bdbi */
.x-bdbi-n { border-bottom-image: none !important; }

/** border-left-image Emmet abbreviation: bdli */
.x-bdli-n { border-left-image: none !important; }

/** border-corner-image Emmet abbreviation: bdci */
.x-bdci-n { border-corner-image: none !important; }

.x-bdci-c { border-corner-image: continue !important; }

/** border-top-left-image Emmet abbreviation: bdtli */
.x-bdtli-n { border-top-left-image: none !important; }

.x-bdtli-c { border-top-left-image: continue !important; }

/** border-top-right-image Emmet abbreviation: bdtri */
.x-bdtri-n { border-top-right-image: none !important; }

.x-bdtri-c { border-top-right-image: continue !important; }

/** border-bottom-right-image Emmet abbreviation: bdbri */
.x-bdbri-n { border-bottom-right-image: none !important; }

.x-bdbri-c { border-bottom-right-image: continue !important; }

/** border-bottom-left-image Emmet abbreviation: bdbli */
.x-bdbli-n { border-bottom-left-image: none !important; }

.x-bdbli-c { border-bottom-left-image: continue !important; }

/** border-fit Emmet abbreviation: bdf */
.x-bdf-c { border-fit: clip !important; }

.x-bdf-r { border-fit: repeat !important; }

.x-bdf-sc { border-fit: scale !important; }

.x-bdf-st { border-fit: stretch !important; }

.x-bdf-ow { border-fit: overwrite !important; }

.x-bdf-of { border-fit: overflow !important; }

.x-bdf-sp { border-fit: space !important; }

/** border-length Emmet abbreviation: bdlen */
.x-bdlen-a { border-length: auto !important; }

/** border-spacing Emmet abbreviation: bdsp */
.x-bdsp-0 { border-spacing: 0px !important; }

.x-bdsp-1 { border-spacing: 1px !important; }

.x-bdsp-2 { border-spacing: 2px !important; }

.x-bdsp-3 { border-spacing: 3px !important; }

.x-bdsp-4 { border-spacing: 4px !important; }

.x-bdsp-5 { border-spacing: 5px !important; }

.x-bdsp-6 { border-spacing: 6px !important; }

.x-bdsp-7 { border-spacing: 7px !important; }

.x-bdsp-8 { border-spacing: 8px !important; }

.x-bdsp-9 { border-spacing: 9px !important; }

.x-bdsp-10 { border-spacing: 10px !important; }

.x-bdsp-11 { border-spacing: 11px !important; }

.x-bdsp-12 { border-spacing: 12px !important; }

.x-bdsp-13 { border-spacing: 13px !important; }

.x-bdsp-14 { border-spacing: 14px !important; }

.x-bdsp-15 { border-spacing: 15px !important; }

.x-bdsp-16 { border-spacing: 16px !important; }

.x-bdsp-17 { border-spacing: 17px !important; }

.x-bdsp-18 { border-spacing: 18px !important; }

.x-bdsp-19 { border-spacing: 19px !important; }

.x-bdsp-20 { border-spacing: 20px !important; }

.x-bdsp-21 { border-spacing: 21px !important; }

.x-bdsp-22 { border-spacing: 22px !important; }

.x-bdsp-23 { border-spacing: 23px !important; }

.x-bdsp-24 { border-spacing: 24px !important; }

.x-bdsp-25 { border-spacing: 25px !important; }

/** border-style Emmet abbreviation: bds */
.x-bds-n { border-style: none !important; }

.x-bds-h { border-style: hidden !important; }

.x-bds-dt { border-style: dotted !important; }

.x-bds-ds { border-style: dashed !important; }

.x-bds-s { border-style: solid !important; }

.x-bds-db { border-style: double !important; }

.x-bds-dtds { border-style: dot-dash !important; }

.x-bds-dtdtds { border-style: dot-dot-dash !important; }

.x-bds-w { border-style: wave !important; }

.x-bds-g { border-style: groove !important; }

.x-bds-r { border-style: ridge !important; }

.x-bds-i { border-style: inset !important; }

.x-bds-o { border-style: outset !important; }

/** border-width Emmet abbreviation: bdw */
.x-bdw-0 { border-width: 0px !important; }

.x-bdw-1 { border-width: 1px !important; }

.x-bdw-2 { border-width: 2px !important; }

.x-bdw-3 { border-width: 3px !important; }

.x-bdw-4 { border-width: 4px !important; }

.x-bdw-5 { border-width: 5px !important; }

.x-bdw-6 { border-width: 6px !important; }

.x-bdw-7 { border-width: 7px !important; }

.x-bdw-8 { border-width: 8px !important; }

.x-bdw-9 { border-width: 9px !important; }

.x-bdw-10 { border-width: 10px !important; }

.x-bdw-11 { border-width: 11px !important; }

.x-bdw-12 { border-width: 12px !important; }

.x-bdw-13 { border-width: 13px !important; }

.x-bdw-14 { border-width: 14px !important; }

.x-bdw-15 { border-width: 15px !important; }

.x-bdw-16 { border-width: 16px !important; }

.x-bdw-17 { border-width: 17px !important; }

.x-bdw-18 { border-width: 18px !important; }

.x-bdw-19 { border-width: 19px !important; }

.x-bdw-20 { border-width: 20px !important; }

.x-bdw-21 { border-width: 21px !important; }

.x-bdw-22 { border-width: 22px !important; }

.x-bdw-23 { border-width: 23px !important; }

.x-bdw-24 { border-width: 24px !important; }

.x-bdw-25 { border-width: 25px !important; }

/** border-top Emmet abbreviation: bdt */
.x-bdt-n { border-top: none !important; }

/** border-top-width Emmet abbreviation: bdtw */
.x-bdtw-0 { border-top-width: 0px !important; }

.x-bdtw-1 { border-top-width: 1px !important; }

.x-bdtw-2 { border-top-width: 2px !important; }

.x-bdtw-3 { border-top-width: 3px !important; }

.x-bdtw-4 { border-top-width: 4px !important; }

.x-bdtw-5 { border-top-width: 5px !important; }

.x-bdtw-6 { border-top-width: 6px !important; }

.x-bdtw-7 { border-top-width: 7px !important; }

.x-bdtw-8 { border-top-width: 8px !important; }

.x-bdtw-9 { border-top-width: 9px !important; }

.x-bdtw-10 { border-top-width: 10px !important; }

.x-bdtw-11 { border-top-width: 11px !important; }

.x-bdtw-12 { border-top-width: 12px !important; }

.x-bdtw-13 { border-top-width: 13px !important; }

.x-bdtw-14 { border-top-width: 14px !important; }

.x-bdtw-15 { border-top-width: 15px !important; }

.x-bdtw-16 { border-top-width: 16px !important; }

.x-bdtw-17 { border-top-width: 17px !important; }

.x-bdtw-18 { border-top-width: 18px !important; }

.x-bdtw-19 { border-top-width: 19px !important; }

.x-bdtw-20 { border-top-width: 20px !important; }

.x-bdtw-21 { border-top-width: 21px !important; }

.x-bdtw-22 { border-top-width: 22px !important; }

.x-bdtw-23 { border-top-width: 23px !important; }

.x-bdtw-24 { border-top-width: 24px !important; }

.x-bdtw-25 { border-top-width: 25px !important; }

/** border-top-style Emmet abbreviation: bdts */
.x-bdts-n { border-top-style: none !important; }

.x-bdts-h { border-top-style: hidden !important; }

.x-bdts-dt { border-top-style: dotted !important; }

.x-bdts-ds { border-top-style: dashed !important; }

.x-bdts-s { border-top-style: solid !important; }

.x-bdts-db { border-top-style: double !important; }

.x-bdts-dtds { border-top-style: dot-dash !important; }

.x-bdts-dtdtds { border-top-style: dot-dot-dash !important; }

.x-bdts-w { border-top-style: wave !important; }

.x-bdts-g { border-top-style: groove !important; }

.x-bdts-r { border-top-style: ridge !important; }

.x-bdts-i { border-top-style: inset !important; }

.x-bdts-o { border-top-style: outset !important; }

/** border-top-color Emmet abbreviation: bdtc */
.x-bdtc-000 { border-top-color: #000 !important; }

.x-bdtc-111 { border-top-color: #111 !important; }

.x-bdtc-222 { border-top-color: #222 !important; }

.x-bdtc-333 { border-top-color: #333 !important; }

.x-bdtc-444 { border-top-color: #444 !important; }

.x-bdtc-555 { border-top-color: #555 !important; }

.x-bdtc-666 { border-top-color: #666 !important; }

.x-bdtc-777 { border-top-color: #777 !important; }

.x-bdtc-888 { border-top-color: #888 !important; }

.x-bdtc-999 { border-top-color: #999 !important; }

.x-bdtc-aaa { border-top-color: #aaa !important; }

.x-bdtc-bbb { border-top-color: #bbb !important; }

.x-bdtc-ccc { border-top-color: #ccc !important; }

.x-bdtc-ddd { border-top-color: #ddd !important; }

.x-bdtc-eee { border-top-color: #eee !important; }

.x-bdtc-fff { border-top-color: #fff !important; }

.x-bdtc-t { border-top-color: transparent !important; }

.x-bdtc-transparent { border-top-color: transparent !important; }

.x-bdtc-aqua { border-top-color: aqua !important; }

.x-bdtc-black { border-top-color: black !important; }

.x-bdtc-blue { border-top-color: blue !important; }

.x-bdtc-brown { border-top-color: brown !important; }

.x-bdtc-crimson { border-top-color: crimson !important; }

.x-bdtc-cyan { border-top-color: cyan !important; }

.x-bdtc-darkblue { border-top-color: darkblue !important; }

.x-bdtc-darkgreen { border-top-color: darkgreen !important; }

.x-bdtc-darkorange { border-top-color: darkorange !important; }

.x-bdtc-darkred { border-top-color: darkred !important; }

.x-bdtc-fuchsia { border-top-color: fuchsia !important; }

.x-bdtc-gold { border-top-color: gold !important; }

.x-bdtc-gray { border-top-color: gray !important; }

.x-bdtc-green { border-top-color: green !important; }

.x-bdtc-lavender { border-top-color: lavender !important; }

.x-bdtc-magenta { border-top-color: magenta !important; }

.x-bdtc-maroon { border-top-color: maroon !important; }

.x-bdtc-navy { border-top-color: navy !important; }

.x-bdtc-olive { border-top-color: olive !important; }

.x-bdtc-orange { border-top-color: orange !important; }

.x-bdtc-pink { border-top-color: pink !important; }

.x-bdtc-purple { border-top-color: purple !important; }

.x-bdtc-red { border-top-color: red !important; }

.x-bdtc-silver { border-top-color: silver !important; }

.x-bdtc-turquoise { border-top-color: turquoise !important; }

.x-bdtc-white { border-top-color: white !important; }

.x-bdtc-yellow { border-top-color: yellow !important; }

/** border-right Emmet abbreviation: bdr */
.x-bdr-n { border-right: none !important; }

/** border-right-width Emmet abbreviation: bdrw */
.x-bdrw-0 { border-right-width: 0px !important; }

.x-bdrw-1 { border-right-width: 1px !important; }

.x-bdrw-2 { border-right-width: 2px !important; }

.x-bdrw-3 { border-right-width: 3px !important; }

.x-bdrw-4 { border-right-width: 4px !important; }

.x-bdrw-5 { border-right-width: 5px !important; }

.x-bdrw-6 { border-right-width: 6px !important; }

.x-bdrw-7 { border-right-width: 7px !important; }

.x-bdrw-8 { border-right-width: 8px !important; }

.x-bdrw-9 { border-right-width: 9px !important; }

.x-bdrw-10 { border-right-width: 10px !important; }

.x-bdrw-11 { border-right-width: 11px !important; }

.x-bdrw-12 { border-right-width: 12px !important; }

.x-bdrw-13 { border-right-width: 13px !important; }

.x-bdrw-14 { border-right-width: 14px !important; }

.x-bdrw-15 { border-right-width: 15px !important; }

.x-bdrw-16 { border-right-width: 16px !important; }

.x-bdrw-17 { border-right-width: 17px !important; }

.x-bdrw-18 { border-right-width: 18px !important; }

.x-bdrw-19 { border-right-width: 19px !important; }

.x-bdrw-20 { border-right-width: 20px !important; }

.x-bdrw-21 { border-right-width: 21px !important; }

.x-bdrw-22 { border-right-width: 22px !important; }

.x-bdrw-23 { border-right-width: 23px !important; }

.x-bdrw-24 { border-right-width: 24px !important; }

.x-bdrw-25 { border-right-width: 25px !important; }

/** border-right-style Emmet abbreviation: bdrst */
.x-bdrst-n { border-right-style: none !important; }

.x-bdrst-h { border-right-style: hidden !important; }

.x-bdrst-dt { border-right-style: dotted !important; }

.x-bdrst-ds { border-right-style: dashed !important; }

.x-bdrst-s { border-right-style: solid !important; }

.x-bdrst-db { border-right-style: double !important; }

.x-bdrst-dtds { border-right-style: dot-dash !important; }

.x-bdrst-dtdtds { border-right-style: dot-dot-dash !important; }

.x-bdrst-w { border-right-style: wave !important; }

.x-bdrst-g { border-right-style: groove !important; }

.x-bdrst-r { border-right-style: ridge !important; }

.x-bdrst-i { border-right-style: inset !important; }

.x-bdrst-o { border-right-style: outset !important; }

/** border-right-color Emmet abbreviation: bdrc */
.x-bdrc-000 { border-right-color: #000 !important; }

.x-bdrc-111 { border-right-color: #111 !important; }

.x-bdrc-222 { border-right-color: #222 !important; }

.x-bdrc-333 { border-right-color: #333 !important; }

.x-bdrc-444 { border-right-color: #444 !important; }

.x-bdrc-555 { border-right-color: #555 !important; }

.x-bdrc-666 { border-right-color: #666 !important; }

.x-bdrc-777 { border-right-color: #777 !important; }

.x-bdrc-888 { border-right-color: #888 !important; }

.x-bdrc-999 { border-right-color: #999 !important; }

.x-bdrc-aaa { border-right-color: #aaa !important; }

.x-bdrc-bbb { border-right-color: #bbb !important; }

.x-bdrc-ccc { border-right-color: #ccc !important; }

.x-bdrc-ddd { border-right-color: #ddd !important; }

.x-bdrc-eee { border-right-color: #eee !important; }

.x-bdrc-fff { border-right-color: #fff !important; }

.x-bdrc-t { border-right-color: transparent !important; }

.x-bdrc-transparent { border-right-color: transparent !important; }

.x-bdrc-aqua { border-right-color: aqua !important; }

.x-bdrc-black { border-right-color: black !important; }

.x-bdrc-blue { border-right-color: blue !important; }

.x-bdrc-brown { border-right-color: brown !important; }

.x-bdrc-crimson { border-right-color: crimson !important; }

.x-bdrc-cyan { border-right-color: cyan !important; }

.x-bdrc-darkblue { border-right-color: darkblue !important; }

.x-bdrc-darkgreen { border-right-color: darkgreen !important; }

.x-bdrc-darkorange { border-right-color: darkorange !important; }

.x-bdrc-darkred { border-right-color: darkred !important; }

.x-bdrc-fuchsia { border-right-color: fuchsia !important; }

.x-bdrc-gold { border-right-color: gold !important; }

.x-bdrc-gray { border-right-color: gray !important; }

.x-bdrc-green { border-right-color: green !important; }

.x-bdrc-lavender { border-right-color: lavender !important; }

.x-bdrc-magenta { border-right-color: magenta !important; }

.x-bdrc-maroon { border-right-color: maroon !important; }

.x-bdrc-navy { border-right-color: navy !important; }

.x-bdrc-olive { border-right-color: olive !important; }

.x-bdrc-orange { border-right-color: orange !important; }

.x-bdrc-pink { border-right-color: pink !important; }

.x-bdrc-purple { border-right-color: purple !important; }

.x-bdrc-red { border-right-color: red !important; }

.x-bdrc-silver { border-right-color: silver !important; }

.x-bdrc-turquoise { border-right-color: turquoise !important; }

.x-bdrc-white { border-right-color: white !important; }

.x-bdrc-yellow { border-right-color: yellow !important; }

/** border-bottom Emmet abbreviation: bdb */
.x-bdb-n { border-bottom: none !important; }

/** border-bottom-width Emmet abbreviation: bdbw */
.x-bdbw-0 { border-bottom-width: 0px !important; }

.x-bdbw-1 { border-bottom-width: 1px !important; }

.x-bdbw-2 { border-bottom-width: 2px !important; }

.x-bdbw-3 { border-bottom-width: 3px !important; }

.x-bdbw-4 { border-bottom-width: 4px !important; }

.x-bdbw-5 { border-bottom-width: 5px !important; }

.x-bdbw-6 { border-bottom-width: 6px !important; }

.x-bdbw-7 { border-bottom-width: 7px !important; }

.x-bdbw-8 { border-bottom-width: 8px !important; }

.x-bdbw-9 { border-bottom-width: 9px !important; }

.x-bdbw-10 { border-bottom-width: 10px !important; }

.x-bdbw-11 { border-bottom-width: 11px !important; }

.x-bdbw-12 { border-bottom-width: 12px !important; }

.x-bdbw-13 { border-bottom-width: 13px !important; }

.x-bdbw-14 { border-bottom-width: 14px !important; }

.x-bdbw-15 { border-bottom-width: 15px !important; }

.x-bdbw-16 { border-bottom-width: 16px !important; }

.x-bdbw-17 { border-bottom-width: 17px !important; }

.x-bdbw-18 { border-bottom-width: 18px !important; }

.x-bdbw-19 { border-bottom-width: 19px !important; }

.x-bdbw-20 { border-bottom-width: 20px !important; }

.x-bdbw-21 { border-bottom-width: 21px !important; }

.x-bdbw-22 { border-bottom-width: 22px !important; }

.x-bdbw-23 { border-bottom-width: 23px !important; }

.x-bdbw-24 { border-bottom-width: 24px !important; }

.x-bdbw-25 { border-bottom-width: 25px !important; }

/** border-bottom-style Emmet abbreviation: bdbs */
.x-bdbs-n { border-bottom-style: none !important; }

.x-bdbs-h { border-bottom-style: hidden !important; }

.x-bdbs-dt { border-bottom-style: dotted !important; }

.x-bdbs-ds { border-bottom-style: dashed !important; }

.x-bdbs-s { border-bottom-style: solid !important; }

.x-bdbs-db { border-bottom-style: double !important; }

.x-bdbs-dtds { border-bottom-style: dot-dash !important; }

.x-bdbs-dtdtds { border-bottom-style: dot-dot-dash !important; }

.x-bdbs-w { border-bottom-style: wave !important; }

.x-bdbs-g { border-bottom-style: groove !important; }

.x-bdbs-r { border-bottom-style: ridge !important; }

.x-bdbs-i { border-bottom-style: inset !important; }

.x-bdbs-o { border-bottom-style: outset !important; }

/** border-bottom-color Emmet abbreviation: bdbc */
.x-bdbc-000 { border-bottom-color: #000 !important; }

.x-bdbc-111 { border-bottom-color: #111 !important; }

.x-bdbc-222 { border-bottom-color: #222 !important; }

.x-bdbc-333 { border-bottom-color: #333 !important; }

.x-bdbc-444 { border-bottom-color: #444 !important; }

.x-bdbc-555 { border-bottom-color: #555 !important; }

.x-bdbc-666 { border-bottom-color: #666 !important; }

.x-bdbc-777 { border-bottom-color: #777 !important; }

.x-bdbc-888 { border-bottom-color: #888 !important; }

.x-bdbc-999 { border-bottom-color: #999 !important; }

.x-bdbc-aaa { border-bottom-color: #aaa !important; }

.x-bdbc-bbb { border-bottom-color: #bbb !important; }

.x-bdbc-ccc { border-bottom-color: #ccc !important; }

.x-bdbc-ddd { border-bottom-color: #ddd !important; }

.x-bdbc-eee { border-bottom-color: #eee !important; }

.x-bdbc-fff { border-bottom-color: #fff !important; }

.x-bdbc-t { border-bottom-color: transparent !important; }

.x-bdbc-transparent { border-bottom-color: transparent !important; }

.x-bdbc-aqua { border-bottom-color: aqua !important; }

.x-bdbc-black { border-bottom-color: black !important; }

.x-bdbc-blue { border-bottom-color: blue !important; }

.x-bdbc-brown { border-bottom-color: brown !important; }

.x-bdbc-crimson { border-bottom-color: crimson !important; }

.x-bdbc-cyan { border-bottom-color: cyan !important; }

.x-bdbc-darkblue { border-bottom-color: darkblue !important; }

.x-bdbc-darkgreen { border-bottom-color: darkgreen !important; }

.x-bdbc-darkorange { border-bottom-color: darkorange !important; }

.x-bdbc-darkred { border-bottom-color: darkred !important; }

.x-bdbc-fuchsia { border-bottom-color: fuchsia !important; }

.x-bdbc-gold { border-bottom-color: gold !important; }

.x-bdbc-gray { border-bottom-color: gray !important; }

.x-bdbc-green { border-bottom-color: green !important; }

.x-bdbc-lavender { border-bottom-color: lavender !important; }

.x-bdbc-magenta { border-bottom-color: magenta !important; }

.x-bdbc-maroon { border-bottom-color: maroon !important; }

.x-bdbc-navy { border-bottom-color: navy !important; }

.x-bdbc-olive { border-bottom-color: olive !important; }

.x-bdbc-orange { border-bottom-color: orange !important; }

.x-bdbc-pink { border-bottom-color: pink !important; }

.x-bdbc-purple { border-bottom-color: purple !important; }

.x-bdbc-red { border-bottom-color: red !important; }

.x-bdbc-silver { border-bottom-color: silver !important; }

.x-bdbc-turquoise { border-bottom-color: turquoise !important; }

.x-bdbc-white { border-bottom-color: white !important; }

.x-bdbc-yellow { border-bottom-color: yellow !important; }

/** border-left Emmet abbreviation: bdl */
.x-bdl-n { border-left: none !important; }

/** border-left-width Emmet abbreviation: bdlw */
.x-bdlw-0 { border-left-width: 0px !important; }

.x-bdlw-1 { border-left-width: 1px !important; }

.x-bdlw-2 { border-left-width: 2px !important; }

.x-bdlw-3 { border-left-width: 3px !important; }

.x-bdlw-4 { border-left-width: 4px !important; }

.x-bdlw-5 { border-left-width: 5px !important; }

.x-bdlw-6 { border-left-width: 6px !important; }

.x-bdlw-7 { border-left-width: 7px !important; }

.x-bdlw-8 { border-left-width: 8px !important; }

.x-bdlw-9 { border-left-width: 9px !important; }

.x-bdlw-10 { border-left-width: 10px !important; }

.x-bdlw-11 { border-left-width: 11px !important; }

.x-bdlw-12 { border-left-width: 12px !important; }

.x-bdlw-13 { border-left-width: 13px !important; }

.x-bdlw-14 { border-left-width: 14px !important; }

.x-bdlw-15 { border-left-width: 15px !important; }

.x-bdlw-16 { border-left-width: 16px !important; }

.x-bdlw-17 { border-left-width: 17px !important; }

.x-bdlw-18 { border-left-width: 18px !important; }

.x-bdlw-19 { border-left-width: 19px !important; }

.x-bdlw-20 { border-left-width: 20px !important; }

.x-bdlw-21 { border-left-width: 21px !important; }

.x-bdlw-22 { border-left-width: 22px !important; }

.x-bdlw-23 { border-left-width: 23px !important; }

.x-bdlw-24 { border-left-width: 24px !important; }

.x-bdlw-25 { border-left-width: 25px !important; }

/** border-left-style Emmet abbreviation: bdls */
.x-bdls-n { border-left-style: none !important; }

.x-bdls-h { border-left-style: hidden !important; }

.x-bdls-dt { border-left-style: dotted !important; }

.x-bdls-ds { border-left-style: dashed !important; }

.x-bdls-s { border-left-style: solid !important; }

.x-bdls-db { border-left-style: double !important; }

.x-bdls-dtds { border-left-style: dot-dash !important; }

.x-bdls-dtdtds { border-left-style: dot-dot-dash !important; }

.x-bdls-w { border-left-style: wave !important; }

.x-bdls-g { border-left-style: groove !important; }

.x-bdls-r { border-left-style: ridge !important; }

.x-bdls-i { border-left-style: inset !important; }

.x-bdls-o { border-left-style: outset !important; }

/** border-left-color Emmet abbreviation: bdlc */
.x-bdlc-000 { border-left-color: #000 !important; }

.x-bdlc-111 { border-left-color: #111 !important; }

.x-bdlc-222 { border-left-color: #222 !important; }

.x-bdlc-333 { border-left-color: #333 !important; }

.x-bdlc-444 { border-left-color: #444 !important; }

.x-bdlc-555 { border-left-color: #555 !important; }

.x-bdlc-666 { border-left-color: #666 !important; }

.x-bdlc-777 { border-left-color: #777 !important; }

.x-bdlc-888 { border-left-color: #888 !important; }

.x-bdlc-999 { border-left-color: #999 !important; }

.x-bdlc-aaa { border-left-color: #aaa !important; }

.x-bdlc-bbb { border-left-color: #bbb !important; }

.x-bdlc-ccc { border-left-color: #ccc !important; }

.x-bdlc-ddd { border-left-color: #ddd !important; }

.x-bdlc-eee { border-left-color: #eee !important; }

.x-bdlc-fff { border-left-color: #fff !important; }

.x-bdlc-t { border-left-color: transparent !important; }

.x-bdlc-transparent { border-left-color: transparent !important; }

.x-bdlc-aqua { border-left-color: aqua !important; }

.x-bdlc-black { border-left-color: black !important; }

.x-bdlc-blue { border-left-color: blue !important; }

.x-bdlc-brown { border-left-color: brown !important; }

.x-bdlc-crimson { border-left-color: crimson !important; }

.x-bdlc-cyan { border-left-color: cyan !important; }

.x-bdlc-darkblue { border-left-color: darkblue !important; }

.x-bdlc-darkgreen { border-left-color: darkgreen !important; }

.x-bdlc-darkorange { border-left-color: darkorange !important; }

.x-bdlc-darkred { border-left-color: darkred !important; }

.x-bdlc-fuchsia { border-left-color: fuchsia !important; }

.x-bdlc-gold { border-left-color: gold !important; }

.x-bdlc-gray { border-left-color: gray !important; }

.x-bdlc-green { border-left-color: green !important; }

.x-bdlc-lavender { border-left-color: lavender !important; }

.x-bdlc-magenta { border-left-color: magenta !important; }

.x-bdlc-maroon { border-left-color: maroon !important; }

.x-bdlc-navy { border-left-color: navy !important; }

.x-bdlc-olive { border-left-color: olive !important; }

.x-bdlc-orange { border-left-color: orange !important; }

.x-bdlc-pink { border-left-color: pink !important; }

.x-bdlc-purple { border-left-color: purple !important; }

.x-bdlc-red { border-left-color: red !important; }

.x-bdlc-silver { border-left-color: silver !important; }

.x-bdlc-turquoise { border-left-color: turquoise !important; }

.x-bdlc-white { border-left-color: white !important; }

.x-bdlc-yellow { border-left-color: yellow !important; }

/** border-radius Emmet abbreviation: bdrs */
.x-bdrs-0 { border-radius: 0px !important; }

.x-bdrs-1 { border-radius: 1px !important; }

.x-bdrs-2 { border-radius: 2px !important; }

.x-bdrs-3 { border-radius: 3px !important; }

.x-bdrs-4 { border-radius: 4px !important; }

.x-bdrs-5 { border-radius: 5px !important; }

.x-bdrs-6 { border-radius: 6px !important; }

.x-bdrs-7 { border-radius: 7px !important; }

.x-bdrs-8 { border-radius: 8px !important; }

.x-bdrs-9 { border-radius: 9px !important; }

.x-bdrs-10 { border-radius: 10px !important; }

.x-bdrs-11 { border-radius: 11px !important; }

.x-bdrs-12 { border-radius: 12px !important; }

.x-bdrs-13 { border-radius: 13px !important; }

.x-bdrs-14 { border-radius: 14px !important; }

.x-bdrs-15 { border-radius: 15px !important; }

.x-bdrs-16 { border-radius: 16px !important; }

.x-bdrs-17 { border-radius: 17px !important; }

.x-bdrs-18 { border-radius: 18px !important; }

.x-bdrs-19 { border-radius: 19px !important; }

.x-bdrs-20 { border-radius: 20px !important; }

.x-bdrs-21 { border-radius: 21px !important; }

.x-bdrs-22 { border-radius: 22px !important; }

.x-bdrs-23 { border-radius: 23px !important; }

.x-bdrs-24 { border-radius: 24px !important; }

.x-bdrs-25 { border-radius: 25px !important; }

.x-bdrs-26 { border-radius: 26px !important; }

.x-bdrs-27 { border-radius: 27px !important; }

.x-bdrs-28 { border-radius: 28px !important; }

.x-bdrs-29 { border-radius: 29px !important; }

.x-bdrs-30 { border-radius: 30px !important; }

.x-bdrs-31 { border-radius: 31px !important; }

.x-bdrs-32 { border-radius: 32px !important; }

.x-bdrs-33 { border-radius: 33px !important; }

.x-bdrs-34 { border-radius: 34px !important; }

.x-bdrs-35 { border-radius: 35px !important; }

.x-bdrs-36 { border-radius: 36px !important; }

.x-bdrs-37 { border-radius: 37px !important; }

.x-bdrs-38 { border-radius: 38px !important; }

.x-bdrs-39 { border-radius: 39px !important; }

.x-bdrs-40 { border-radius: 40px !important; }

.x-bdrs-41 { border-radius: 41px !important; }

.x-bdrs-42 { border-radius: 42px !important; }

.x-bdrs-43 { border-radius: 43px !important; }

.x-bdrs-44 { border-radius: 44px !important; }

.x-bdrs-45 { border-radius: 45px !important; }

.x-bdrs-46 { border-radius: 46px !important; }

.x-bdrs-47 { border-radius: 47px !important; }

.x-bdrs-48 { border-radius: 48px !important; }

.x-bdrs-49 { border-radius: 49px !important; }

.x-bdrs-50 { border-radius: 50px !important; }

.x-bdrs-55 { border-radius: 55px !important; }

.x-bdrs-60 { border-radius: 60px !important; }

.x-bdrs-65 { border-radius: 65px !important; }

.x-bdrs-70 { border-radius: 70px !important; }

.x-bdrs-75 { border-radius: 75px !important; }

.x-bdrs-80 { border-radius: 80px !important; }

.x-bdrs-85 { border-radius: 85px !important; }

.x-bdrs-90 { border-radius: 90px !important; }

.x-bdrs-95 { border-radius: 95px !important; }

.x-bdrs-100 { border-radius: 100px !important; }

/** border-top-right-radius Emmet abbreviation: bdtrrs */
.x-bdtrrs-0 { border-top-right-radius: 0px !important; }

.x-bdtrrs-1 { border-top-right-radius: 1px !important; }

.x-bdtrrs-2 { border-top-right-radius: 2px !important; }

.x-bdtrrs-3 { border-top-right-radius: 3px !important; }

.x-bdtrrs-4 { border-top-right-radius: 4px !important; }

.x-bdtrrs-5 { border-top-right-radius: 5px !important; }

.x-bdtrrs-6 { border-top-right-radius: 6px !important; }

.x-bdtrrs-7 { border-top-right-radius: 7px !important; }

.x-bdtrrs-8 { border-top-right-radius: 8px !important; }

.x-bdtrrs-9 { border-top-right-radius: 9px !important; }

.x-bdtrrs-10 { border-top-right-radius: 10px !important; }

.x-bdtrrs-11 { border-top-right-radius: 11px !important; }

.x-bdtrrs-12 { border-top-right-radius: 12px !important; }

.x-bdtrrs-13 { border-top-right-radius: 13px !important; }

.x-bdtrrs-14 { border-top-right-radius: 14px !important; }

.x-bdtrrs-15 { border-top-right-radius: 15px !important; }

.x-bdtrrs-16 { border-top-right-radius: 16px !important; }

.x-bdtrrs-17 { border-top-right-radius: 17px !important; }

.x-bdtrrs-18 { border-top-right-radius: 18px !important; }

.x-bdtrrs-19 { border-top-right-radius: 19px !important; }

.x-bdtrrs-20 { border-top-right-radius: 20px !important; }

.x-bdtrrs-21 { border-top-right-radius: 21px !important; }

.x-bdtrrs-22 { border-top-right-radius: 22px !important; }

.x-bdtrrs-23 { border-top-right-radius: 23px !important; }

.x-bdtrrs-24 { border-top-right-radius: 24px !important; }

.x-bdtrrs-25 { border-top-right-radius: 25px !important; }

.x-bdtrrs-26 { border-top-right-radius: 26px !important; }

.x-bdtrrs-27 { border-top-right-radius: 27px !important; }

.x-bdtrrs-28 { border-top-right-radius: 28px !important; }

.x-bdtrrs-29 { border-top-right-radius: 29px !important; }

.x-bdtrrs-30 { border-top-right-radius: 30px !important; }

.x-bdtrrs-31 { border-top-right-radius: 31px !important; }

.x-bdtrrs-32 { border-top-right-radius: 32px !important; }

.x-bdtrrs-33 { border-top-right-radius: 33px !important; }

.x-bdtrrs-34 { border-top-right-radius: 34px !important; }

.x-bdtrrs-35 { border-top-right-radius: 35px !important; }

.x-bdtrrs-36 { border-top-right-radius: 36px !important; }

.x-bdtrrs-37 { border-top-right-radius: 37px !important; }

.x-bdtrrs-38 { border-top-right-radius: 38px !important; }

.x-bdtrrs-39 { border-top-right-radius: 39px !important; }

.x-bdtrrs-40 { border-top-right-radius: 40px !important; }

.x-bdtrrs-41 { border-top-right-radius: 41px !important; }

.x-bdtrrs-42 { border-top-right-radius: 42px !important; }

.x-bdtrrs-43 { border-top-right-radius: 43px !important; }

.x-bdtrrs-44 { border-top-right-radius: 44px !important; }

.x-bdtrrs-45 { border-top-right-radius: 45px !important; }

.x-bdtrrs-46 { border-top-right-radius: 46px !important; }

.x-bdtrrs-47 { border-top-right-radius: 47px !important; }

.x-bdtrrs-48 { border-top-right-radius: 48px !important; }

.x-bdtrrs-49 { border-top-right-radius: 49px !important; }

.x-bdtrrs-50 { border-top-right-radius: 50px !important; }

.x-bdtrrs-55 { border-top-right-radius: 55px !important; }

.x-bdtrrs-60 { border-top-right-radius: 60px !important; }

.x-bdtrrs-65 { border-top-right-radius: 65px !important; }

.x-bdtrrs-70 { border-top-right-radius: 70px !important; }

.x-bdtrrs-75 { border-top-right-radius: 75px !important; }

.x-bdtrrs-80 { border-top-right-radius: 80px !important; }

.x-bdtrrs-85 { border-top-right-radius: 85px !important; }

.x-bdtrrs-90 { border-top-right-radius: 90px !important; }

.x-bdtrrs-95 { border-top-right-radius: 95px !important; }

.x-bdtrrs-100 { border-top-right-radius: 100px !important; }

/** border-top-left-radius Emmet abbreviation: bdtlrs */
.x-bdtlrs-0 { border-top-left-radius: 0px !important; }

.x-bdtlrs-1 { border-top-left-radius: 1px !important; }

.x-bdtlrs-2 { border-top-left-radius: 2px !important; }

.x-bdtlrs-3 { border-top-left-radius: 3px !important; }

.x-bdtlrs-4 { border-top-left-radius: 4px !important; }

.x-bdtlrs-5 { border-top-left-radius: 5px !important; }

.x-bdtlrs-6 { border-top-left-radius: 6px !important; }

.x-bdtlrs-7 { border-top-left-radius: 7px !important; }

.x-bdtlrs-8 { border-top-left-radius: 8px !important; }

.x-bdtlrs-9 { border-top-left-radius: 9px !important; }

.x-bdtlrs-10 { border-top-left-radius: 10px !important; }

.x-bdtlrs-11 { border-top-left-radius: 11px !important; }

.x-bdtlrs-12 { border-top-left-radius: 12px !important; }

.x-bdtlrs-13 { border-top-left-radius: 13px !important; }

.x-bdtlrs-14 { border-top-left-radius: 14px !important; }

.x-bdtlrs-15 { border-top-left-radius: 15px !important; }

.x-bdtlrs-16 { border-top-left-radius: 16px !important; }

.x-bdtlrs-17 { border-top-left-radius: 17px !important; }

.x-bdtlrs-18 { border-top-left-radius: 18px !important; }

.x-bdtlrs-19 { border-top-left-radius: 19px !important; }

.x-bdtlrs-20 { border-top-left-radius: 20px !important; }

.x-bdtlrs-21 { border-top-left-radius: 21px !important; }

.x-bdtlrs-22 { border-top-left-radius: 22px !important; }

.x-bdtlrs-23 { border-top-left-radius: 23px !important; }

.x-bdtlrs-24 { border-top-left-radius: 24px !important; }

.x-bdtlrs-25 { border-top-left-radius: 25px !important; }

.x-bdtlrs-26 { border-top-left-radius: 26px !important; }

.x-bdtlrs-27 { border-top-left-radius: 27px !important; }

.x-bdtlrs-28 { border-top-left-radius: 28px !important; }

.x-bdtlrs-29 { border-top-left-radius: 29px !important; }

.x-bdtlrs-30 { border-top-left-radius: 30px !important; }

.x-bdtlrs-31 { border-top-left-radius: 31px !important; }

.x-bdtlrs-32 { border-top-left-radius: 32px !important; }

.x-bdtlrs-33 { border-top-left-radius: 33px !important; }

.x-bdtlrs-34 { border-top-left-radius: 34px !important; }

.x-bdtlrs-35 { border-top-left-radius: 35px !important; }

.x-bdtlrs-36 { border-top-left-radius: 36px !important; }

.x-bdtlrs-37 { border-top-left-radius: 37px !important; }

.x-bdtlrs-38 { border-top-left-radius: 38px !important; }

.x-bdtlrs-39 { border-top-left-radius: 39px !important; }

.x-bdtlrs-40 { border-top-left-radius: 40px !important; }

.x-bdtlrs-41 { border-top-left-radius: 41px !important; }

.x-bdtlrs-42 { border-top-left-radius: 42px !important; }

.x-bdtlrs-43 { border-top-left-radius: 43px !important; }

.x-bdtlrs-44 { border-top-left-radius: 44px !important; }

.x-bdtlrs-45 { border-top-left-radius: 45px !important; }

.x-bdtlrs-46 { border-top-left-radius: 46px !important; }

.x-bdtlrs-47 { border-top-left-radius: 47px !important; }

.x-bdtlrs-48 { border-top-left-radius: 48px !important; }

.x-bdtlrs-49 { border-top-left-radius: 49px !important; }

.x-bdtlrs-50 { border-top-left-radius: 50px !important; }

.x-bdtlrs-55 { border-top-left-radius: 55px !important; }

.x-bdtlrs-60 { border-top-left-radius: 60px !important; }

.x-bdtlrs-65 { border-top-left-radius: 65px !important; }

.x-bdtlrs-70 { border-top-left-radius: 70px !important; }

.x-bdtlrs-75 { border-top-left-radius: 75px !important; }

.x-bdtlrs-80 { border-top-left-radius: 80px !important; }

.x-bdtlrs-85 { border-top-left-radius: 85px !important; }

.x-bdtlrs-90 { border-top-left-radius: 90px !important; }

.x-bdtlrs-95 { border-top-left-radius: 95px !important; }

.x-bdtlrs-100 { border-top-left-radius: 100px !important; }

/** border-bottom-right-radius Emmet abbreviation: bdbrrs */
.x-bdbrrs-0 { border-bottom-right-radius: 0px !important; }

.x-bdbrrs-1 { border-bottom-right-radius: 1px !important; }

.x-bdbrrs-2 { border-bottom-right-radius: 2px !important; }

.x-bdbrrs-3 { border-bottom-right-radius: 3px !important; }

.x-bdbrrs-4 { border-bottom-right-radius: 4px !important; }

.x-bdbrrs-5 { border-bottom-right-radius: 5px !important; }

.x-bdbrrs-6 { border-bottom-right-radius: 6px !important; }

.x-bdbrrs-7 { border-bottom-right-radius: 7px !important; }

.x-bdbrrs-8 { border-bottom-right-radius: 8px !important; }

.x-bdbrrs-9 { border-bottom-right-radius: 9px !important; }

.x-bdbrrs-10 { border-bottom-right-radius: 10px !important; }

.x-bdbrrs-11 { border-bottom-right-radius: 11px !important; }

.x-bdbrrs-12 { border-bottom-right-radius: 12px !important; }

.x-bdbrrs-13 { border-bottom-right-radius: 13px !important; }

.x-bdbrrs-14 { border-bottom-right-radius: 14px !important; }

.x-bdbrrs-15 { border-bottom-right-radius: 15px !important; }

.x-bdbrrs-16 { border-bottom-right-radius: 16px !important; }

.x-bdbrrs-17 { border-bottom-right-radius: 17px !important; }

.x-bdbrrs-18 { border-bottom-right-radius: 18px !important; }

.x-bdbrrs-19 { border-bottom-right-radius: 19px !important; }

.x-bdbrrs-20 { border-bottom-right-radius: 20px !important; }

.x-bdbrrs-21 { border-bottom-right-radius: 21px !important; }

.x-bdbrrs-22 { border-bottom-right-radius: 22px !important; }

.x-bdbrrs-23 { border-bottom-right-radius: 23px !important; }

.x-bdbrrs-24 { border-bottom-right-radius: 24px !important; }

.x-bdbrrs-25 { border-bottom-right-radius: 25px !important; }

.x-bdbrrs-26 { border-bottom-right-radius: 26px !important; }

.x-bdbrrs-27 { border-bottom-right-radius: 27px !important; }

.x-bdbrrs-28 { border-bottom-right-radius: 28px !important; }

.x-bdbrrs-29 { border-bottom-right-radius: 29px !important; }

.x-bdbrrs-30 { border-bottom-right-radius: 30px !important; }

.x-bdbrrs-31 { border-bottom-right-radius: 31px !important; }

.x-bdbrrs-32 { border-bottom-right-radius: 32px !important; }

.x-bdbrrs-33 { border-bottom-right-radius: 33px !important; }

.x-bdbrrs-34 { border-bottom-right-radius: 34px !important; }

.x-bdbrrs-35 { border-bottom-right-radius: 35px !important; }

.x-bdbrrs-36 { border-bottom-right-radius: 36px !important; }

.x-bdbrrs-37 { border-bottom-right-radius: 37px !important; }

.x-bdbrrs-38 { border-bottom-right-radius: 38px !important; }

.x-bdbrrs-39 { border-bottom-right-radius: 39px !important; }

.x-bdbrrs-40 { border-bottom-right-radius: 40px !important; }

.x-bdbrrs-41 { border-bottom-right-radius: 41px !important; }

.x-bdbrrs-42 { border-bottom-right-radius: 42px !important; }

.x-bdbrrs-43 { border-bottom-right-radius: 43px !important; }

.x-bdbrrs-44 { border-bottom-right-radius: 44px !important; }

.x-bdbrrs-45 { border-bottom-right-radius: 45px !important; }

.x-bdbrrs-46 { border-bottom-right-radius: 46px !important; }

.x-bdbrrs-47 { border-bottom-right-radius: 47px !important; }

.x-bdbrrs-48 { border-bottom-right-radius: 48px !important; }

.x-bdbrrs-49 { border-bottom-right-radius: 49px !important; }

.x-bdbrrs-50 { border-bottom-right-radius: 50px !important; }

.x-bdbrrs-55 { border-bottom-right-radius: 55px !important; }

.x-bdbrrs-60 { border-bottom-right-radius: 60px !important; }

.x-bdbrrs-65 { border-bottom-right-radius: 65px !important; }

.x-bdbrrs-70 { border-bottom-right-radius: 70px !important; }

.x-bdbrrs-75 { border-bottom-right-radius: 75px !important; }

.x-bdbrrs-80 { border-bottom-right-radius: 80px !important; }

.x-bdbrrs-85 { border-bottom-right-radius: 85px !important; }

.x-bdbrrs-90 { border-bottom-right-radius: 90px !important; }

.x-bdbrrs-95 { border-bottom-right-radius: 95px !important; }

.x-bdbrrs-100 { border-bottom-right-radius: 100px !important; }

/** border-bottom-left-radius Emmet abbreviation: bdblrs */
.x-bdblrs-0 { border-bottom-left-radius: 0px !important; }

.x-bdblrs-1 { border-bottom-left-radius: 1px !important; }

.x-bdblrs-2 { border-bottom-left-radius: 2px !important; }

.x-bdblrs-3 { border-bottom-left-radius: 3px !important; }

.x-bdblrs-4 { border-bottom-left-radius: 4px !important; }

.x-bdblrs-5 { border-bottom-left-radius: 5px !important; }

.x-bdblrs-6 { border-bottom-left-radius: 6px !important; }

.x-bdblrs-7 { border-bottom-left-radius: 7px !important; }

.x-bdblrs-8 { border-bottom-left-radius: 8px !important; }

.x-bdblrs-9 { border-bottom-left-radius: 9px !important; }

.x-bdblrs-10 { border-bottom-left-radius: 10px !important; }

.x-bdblrs-11 { border-bottom-left-radius: 11px !important; }

.x-bdblrs-12 { border-bottom-left-radius: 12px !important; }

.x-bdblrs-13 { border-bottom-left-radius: 13px !important; }

.x-bdblrs-14 { border-bottom-left-radius: 14px !important; }

.x-bdblrs-15 { border-bottom-left-radius: 15px !important; }

.x-bdblrs-16 { border-bottom-left-radius: 16px !important; }

.x-bdblrs-17 { border-bottom-left-radius: 17px !important; }

.x-bdblrs-18 { border-bottom-left-radius: 18px !important; }

.x-bdblrs-19 { border-bottom-left-radius: 19px !important; }

.x-bdblrs-20 { border-bottom-left-radius: 20px !important; }

.x-bdblrs-21 { border-bottom-left-radius: 21px !important; }

.x-bdblrs-22 { border-bottom-left-radius: 22px !important; }

.x-bdblrs-23 { border-bottom-left-radius: 23px !important; }

.x-bdblrs-24 { border-bottom-left-radius: 24px !important; }

.x-bdblrs-25 { border-bottom-left-radius: 25px !important; }

.x-bdblrs-26 { border-bottom-left-radius: 26px !important; }

.x-bdblrs-27 { border-bottom-left-radius: 27px !important; }

.x-bdblrs-28 { border-bottom-left-radius: 28px !important; }

.x-bdblrs-29 { border-bottom-left-radius: 29px !important; }

.x-bdblrs-30 { border-bottom-left-radius: 30px !important; }

.x-bdblrs-31 { border-bottom-left-radius: 31px !important; }

.x-bdblrs-32 { border-bottom-left-radius: 32px !important; }

.x-bdblrs-33 { border-bottom-left-radius: 33px !important; }

.x-bdblrs-34 { border-bottom-left-radius: 34px !important; }

.x-bdblrs-35 { border-bottom-left-radius: 35px !important; }

.x-bdblrs-36 { border-bottom-left-radius: 36px !important; }

.x-bdblrs-37 { border-bottom-left-radius: 37px !important; }

.x-bdblrs-38 { border-bottom-left-radius: 38px !important; }

.x-bdblrs-39 { border-bottom-left-radius: 39px !important; }

.x-bdblrs-40 { border-bottom-left-radius: 40px !important; }

.x-bdblrs-41 { border-bottom-left-radius: 41px !important; }

.x-bdblrs-42 { border-bottom-left-radius: 42px !important; }

.x-bdblrs-43 { border-bottom-left-radius: 43px !important; }

.x-bdblrs-44 { border-bottom-left-radius: 44px !important; }

.x-bdblrs-45 { border-bottom-left-radius: 45px !important; }

.x-bdblrs-46 { border-bottom-left-radius: 46px !important; }

.x-bdblrs-47 { border-bottom-left-radius: 47px !important; }

.x-bdblrs-48 { border-bottom-left-radius: 48px !important; }

.x-bdblrs-49 { border-bottom-left-radius: 49px !important; }

.x-bdblrs-50 { border-bottom-left-radius: 50px !important; }

.x-bdblrs-55 { border-bottom-left-radius: 55px !important; }

.x-bdblrs-60 { border-bottom-left-radius: 60px !important; }

.x-bdblrs-65 { border-bottom-left-radius: 65px !important; }

.x-bdblrs-70 { border-bottom-left-radius: 70px !important; }

.x-bdblrs-75 { border-bottom-left-radius: 75px !important; }

.x-bdblrs-80 { border-bottom-left-radius: 80px !important; }

.x-bdblrs-85 { border-bottom-left-radius: 85px !important; }

.x-bdblrs-90 { border-bottom-left-radius: 90px !important; }

.x-bdblrs-95 { border-bottom-left-radius: 95px !important; }

.x-bdblrs-100 { border-bottom-left-radius: 100px !important; }

/**********************************************************/
/*	Lists                                                 */
/**********************************************************/
/** list-style Emmet abbreviation: lis */
.x-lis-n { list-style: none !important; }

/** list-style-position Emmet abbreviation: lisp */
.x-lisp-i { list-style-position: inside !important; }

.x-lisp-o { list-style-position: outside !important; }

/** list-style-type Emmet abbreviation: list */
.x-list-n { list-style-type: none !important; }

.x-list-d { list-style-type: disc !important; }

.x-list-c { list-style-type: circle !important; }

.x-list-s { list-style-type: square !important; }

.x-list-dc { list-style-type: decimal !important; }

.x-list-dclz { list-style-type: decimal-leading-zero !important; }

.x-list-lr { list-style-type: lower-roman !important; }

.x-list-ur { list-style-type: upper-roman !important; }

/** list-style-image Emmet abbreviation: lisi */
.x-lisi-n { list-style-image: none !important; }

/**********************************************************/
/*	Print                                                 */
/**********************************************************/
/** page-break-before Emmet abbreviation: pgbb */
.x-pgbb-au { page-break-before: auto !important; }

.x-pgbb-al { page-break-before: always !important; }

.x-pgbb-l { page-break-before: left !important; }

.x-pgbb-r { page-break-before: right !important; }

/** page-break-inside Emmet abbreviation: pgbi */
.x-pgbi-au { page-break-inside: auto !important; }

.x-pgbi-av { page-break-inside: avoid !important; }

/** page-break-after Emmet abbreviation: pgba */
.x-pgba-au { page-break-after: auto !important; }

.x-pgba-al { page-break-after: always !important; }

.x-pgba-l { page-break-after: left !important; }

.x-pgba-r { page-break-after: right !important; }

/** orphans Emmet abbreviation: orp */
.x-orp-0 { orphans: 0 !important; }

.x-orp-1 { orphans: 1 !important; }

.x-orp-2 { orphans: 2 !important; }

.x-orp-3 { orphans: 3 !important; }

.x-orp-4 { orphans: 4 !important; }

.x-orp-5 { orphans: 5 !important; }

.x-orp-6 { orphans: 6 !important; }

.x-orp-7 { orphans: 7 !important; }

.x-orp-8 { orphans: 8 !important; }

.x-orp-9 { orphans: 9 !important; }

.x-orp-10 { orphans: 10 !important; }

/** widows Emmet abbreviation: wid */
.x-wid-0 { widows: 0 !important; }

.x-wid-1 { widows: 1 !important; }

.x-wid-2 { widows: 2 !important; }

.x-wid-3 { widows: 3 !important; }

.x-wid-4 { widows: 4 !important; }

.x-wid-5 { widows: 5 !important; }

.x-wid-6 { widows: 6 !important; }

.x-wid-7 { widows: 7 !important; }

.x-wid-8 { widows: 8 !important; }

.x-wid-9 { widows: 9 !important; }

.x-wid-10 { widows: 10 !important; }

/**********************************************************/
/*	Others                                                */
/**********************************************************/
/** align-content Emmet abbreviation: ac */
.x-ac-c { align-content: center !important; }

.x-ac-fe { align-content: flex-end !important; }

.x-ac-fs { align-content: flex-start !important; }

.x-ac-s { align-content: stretch !important; }

.x-ac-sa { align-content: space-around !important; }

.x-ac-sb { align-content: space-between !important; }

/** align-items Emmet abbreviation: ai */
.x-ai-b { align-items: baseline !important; }

.x-ai-c { align-items: center !important; }

.x-ai-fe { align-items: flex-end !important; }

.x-ai-fs { align-items: flex-start !important; }

.x-ai-s { align-items: stretch !important; }

/** align-self Emmet abbreviation: as */
.x-as-a { align-self: auto !important; }

.x-as-b { align-self: baseline !important; }

.x-as-c { align-self: center !important; }

.x-as-fe { align-self: flex-end !important; }

.x-as-fs { align-self: flex-start !important; }

.x-as-s { align-self: stretch !important; }

/** animation-delay Emmet abbreviation: animdel */
/** animation-direction Emmet abbreviation: animdir */
.x-animdir-a { animation-direction: alternate !important; }

.x-animdir-ar { animation-direction: alternate-reverse !important; }

.x-animdir-n { animation-direction: normal !important; }

.x-animdir-r { animation-direction: reverse !important; }

/** animation-duration Emmet abbreviation: animdur */
/** animation-fill-mode Emmet abbreviation: animfm */
.x-animfm-b { animation-fill-mode: backwards !important; }

.x-animfm-bt { animation-fill-mode: both !important; }

.x-animfm-bh { animation-fill-mode: both !important; }

.x-animfm-f { animation-fill-mode: forwards !important; }

/** animation-iteration-count Emmet abbreviation: animic */
.x-animic-i { animation-iteration-count: infinite !important; }

.x-animic-0 { animation-iteration-count: 0 !important; }

.x-animic-1 { animation-iteration-count: 1 !important; }

.x-animic-2 { animation-iteration-count: 2 !important; }

.x-animic-3 { animation-iteration-count: 3 !important; }

.x-animic-4 { animation-iteration-count: 4 !important; }

.x-animic-5 { animation-iteration-count: 5 !important; }

.x-animic-6 { animation-iteration-count: 6 !important; }

.x-animic-7 { animation-iteration-count: 7 !important; }

.x-animic-8 { animation-iteration-count: 8 !important; }

.x-animic-9 { animation-iteration-count: 9 !important; }

.x-animic-10 { animation-iteration-count: 10 !important; }

/** animation-play-state Emmet abbreviation: animps */
.x-animps-p { animation-play-state: paused !important; }

.x-animps-r { animation-play-state: running !important; }

/** animation-timing-function Emmet abbreviation: animtf */
.x-animtf-e { animation-timing-function: ease !important; }

.x-animtf-ei { animation-timing-function: ease-in !important; }

.x-animtf-eio { animation-timing-function: ease-in-out !important; }

.x-animtf-eo { animation-timing-function: ease-out !important; }

.x-animtf-l { animation-timing-function: linear !important; }

/** backface-visibility Emmet abbreviation: bfv */
.x-bfv-h { backface-visibility: hidden !important; }

.x-bfv-v { backface-visibility: visible !important; }

/** column-count Emmet abbreviation: colmc */
.x-colmc-0 { column-count: 0 !important; }

.x-colmc-1 { column-count: 1 !important; }

.x-colmc-2 { column-count: 2 !important; }

.x-colmc-3 { column-count: 3 !important; }

.x-colmc-4 { column-count: 4 !important; }

.x-colmc-5 { column-count: 5 !important; }

.x-colmc-6 { column-count: 6 !important; }

.x-colmc-7 { column-count: 7 !important; }

.x-colmc-8 { column-count: 8 !important; }

.x-colmc-9 { column-count: 9 !important; }

.x-colmc-10 { column-count: 10 !important; }

.x-colmc-11 { column-count: 11 !important; }

.x-colmc-12 { column-count: 12 !important; }

.x-colmc-13 { column-count: 13 !important; }

.x-colmc-14 { column-count: 14 !important; }

.x-colmc-15 { column-count: 15 !important; }

.x-colmc-16 { column-count: 16 !important; }

.x-colmc-17 { column-count: 17 !important; }

.x-colmc-18 { column-count: 18 !important; }

.x-colmc-19 { column-count: 19 !important; }

.x-colmc-20 { column-count: 20 !important; }

.x-colmc-21 { column-count: 21 !important; }

.x-colmc-22 { column-count: 22 !important; }

.x-colmc-23 { column-count: 23 !important; }

.x-colmc-24 { column-count: 24 !important; }

.x-colmc-25 { column-count: 25 !important; }

/** column-fill Emmet abbreviation: colmf */
.x-colmf-a { column-fill: auto !important; }

.x-colmf-b { column-fill: balance !important; }

/** column-gap Emmet abbreviation: colmg */
.x-colmg-0 { column-gap: 0px !important; }

.x-colmg-5 { column-gap: 5px !important; }

.x-colmg-10 { column-gap: 10px !important; }

.x-colmg-15 { column-gap: 15px !important; }

.x-colmg-20 { column-gap: 20px !important; }

.x-colmg-25 { column-gap: 25px !important; }

.x-colmg-30 { column-gap: 30px !important; }

.x-colmg-35 { column-gap: 35px !important; }

.x-colmg-40 { column-gap: 40px !important; }

.x-colmg-45 { column-gap: 45px !important; }

.x-colmg-50 { column-gap: 50px !important; }

.x-colmg-55 { column-gap: 55px !important; }

.x-colmg-60 { column-gap: 60px !important; }

.x-colmg-65 { column-gap: 65px !important; }

.x-colmg-70 { column-gap: 70px !important; }

.x-colmg-75 { column-gap: 75px !important; }

.x-colmg-80 { column-gap: 80px !important; }

.x-colmg-85 { column-gap: 85px !important; }

.x-colmg-90 { column-gap: 90px !important; }

.x-colmg-95 { column-gap: 95px !important; }

.x-colmg-100 { column-gap: 100px !important; }

/** column-rule-color Emmet abbreviation: colmrc */
.x-colmrc-000 { column-rule-color: #000 !important; }

.x-colmrc-111 { column-rule-color: #111 !important; }

.x-colmrc-222 { column-rule-color: #222 !important; }

.x-colmrc-333 { column-rule-color: #333 !important; }

.x-colmrc-444 { column-rule-color: #444 !important; }

.x-colmrc-555 { column-rule-color: #555 !important; }

.x-colmrc-666 { column-rule-color: #666 !important; }

.x-colmrc-777 { column-rule-color: #777 !important; }

.x-colmrc-888 { column-rule-color: #888 !important; }

.x-colmrc-999 { column-rule-color: #999 !important; }

.x-colmrc-aaa { column-rule-color: #aaa !important; }

.x-colmrc-bbb { column-rule-color: #bbb !important; }

.x-colmrc-ccc { column-rule-color: #ccc !important; }

.x-colmrc-ddd { column-rule-color: #ddd !important; }

.x-colmrc-eee { column-rule-color: #eee !important; }

.x-colmrc-fff { column-rule-color: #fff !important; }

.x-colmrc-t { column-rule-color: transparent !important; }

.x-colmrc-transparent { column-rule-color: transparent !important; }

.x-colmrc-aqua { column-rule-color: aqua !important; }

.x-colmrc-black { column-rule-color: black !important; }

.x-colmrc-blue { column-rule-color: blue !important; }

.x-colmrc-brown { column-rule-color: brown !important; }

.x-colmrc-crimson { column-rule-color: crimson !important; }

.x-colmrc-cyan { column-rule-color: cyan !important; }

.x-colmrc-darkblue { column-rule-color: darkblue !important; }

.x-colmrc-darkgreen { column-rule-color: darkgreen !important; }

.x-colmrc-darkorange { column-rule-color: darkorange !important; }

.x-colmrc-darkred { column-rule-color: darkred !important; }

.x-colmrc-fuchsia { column-rule-color: fuchsia !important; }

.x-colmrc-gold { column-rule-color: gold !important; }

.x-colmrc-gray { column-rule-color: gray !important; }

.x-colmrc-green { column-rule-color: green !important; }

.x-colmrc-lavender { column-rule-color: lavender !important; }

.x-colmrc-magenta { column-rule-color: magenta !important; }

.x-colmrc-maroon { column-rule-color: maroon !important; }

.x-colmrc-navy { column-rule-color: navy !important; }

.x-colmrc-olive { column-rule-color: olive !important; }

.x-colmrc-orange { column-rule-color: orange !important; }

.x-colmrc-pink { column-rule-color: pink !important; }

.x-colmrc-purple { column-rule-color: purple !important; }

.x-colmrc-red { column-rule-color: red !important; }

.x-colmrc-silver { column-rule-color: silver !important; }

.x-colmrc-turquoise { column-rule-color: turquoise !important; }

.x-colmrc-white { column-rule-color: white !important; }

.x-colmrc-yellow { column-rule-color: yellow !important; }

/** column-rule-style Emmet abbreviation: colmrs */
.x-colmrs-h { column-rule-style: hidden !important; }

.x-colmrs-n { column-rule-style: none !important; }

.x-colmrs-dt { column-rule-style: dotted !important; }

.x-colmrs-ds { column-rule-style: dashed !important; }

.x-colmrs-s { column-rule-style: solid !important; }

.x-colmrs-db { column-rule-style: double !important; }

.x-colmrs-g { column-rule-style: groove !important; }

.x-colmrs-r { column-rule-style: ridge !important; }

.x-colmrs-i { column-rule-style: inset !important; }

.x-colmrs-o { column-rule-style: outset !important; }

/** column-rule-width Emmet abbreviation: colmrw */
.x-colmrw-0 { column-rule-width: 0px !important; }

.x-colmrw-1 { column-rule-width: 1px !important; }

.x-colmrw-2 { column-rule-width: 2px !important; }

.x-colmrw-3 { column-rule-width: 3px !important; }

.x-colmrw-4 { column-rule-width: 4px !important; }

.x-colmrw-5 { column-rule-width: 5px !important; }

.x-colmrw-6 { column-rule-width: 6px !important; }

.x-colmrw-7 { column-rule-width: 7px !important; }

.x-colmrw-8 { column-rule-width: 8px !important; }

.x-colmrw-9 { column-rule-width: 9px !important; }

.x-colmrw-10 { column-rule-width: 10px !important; }

/** column-span Emmet abbreviation: colms */
.x-colms-1 { column-span: 1 !important; }

.x-colms-a { column-span: all !important; }

/** column-width Emmet abbreviation: colmw */
.x-colmw-0 { column-width: 0px !important; }

.x-colmw-10 { column-width: 10px !important; }

.x-colmw-20 { column-width: 20px !important; }

.x-colmw-30 { column-width: 30px !important; }

.x-colmw-40 { column-width: 40px !important; }

.x-colmw-50 { column-width: 50px !important; }

.x-colmw-60 { column-width: 60px !important; }

.x-colmw-70 { column-width: 70px !important; }

.x-colmw-80 { column-width: 80px !important; }

.x-colmw-90 { column-width: 90px !important; }

.x-colmw-100 { column-width: 100px !important; }

.x-colmw-110 { column-width: 110px !important; }

.x-colmw-120 { column-width: 120px !important; }

.x-colmw-130 { column-width: 130px !important; }

.x-colmw-140 { column-width: 140px !important; }

.x-colmw-150 { column-width: 150px !important; }

.x-colmw-160 { column-width: 160px !important; }

.x-colmw-170 { column-width: 170px !important; }

.x-colmw-180 { column-width: 180px !important; }

.x-colmw-190 { column-width: 190px !important; }

.x-colmw-200 { column-width: 200px !important; }

.x-colmw-210 { column-width: 210px !important; }

.x-colmw-220 { column-width: 220px !important; }

.x-colmw-230 { column-width: 230px !important; }

.x-colmw-240 { column-width: 240px !important; }

.x-colmw-250 { column-width: 250px !important; }

/** flex Emmet abbreviation: fx */
.x-fx-a { flex: auto !important; }

.x-fx-0 { flex: 0 !important; }

.x-fx-1 { flex: 1 !important; }

/** flex-basis Emmet abbreviation: fxb */
.x-fxb-a { flex-basis: auto !important; }

.x-fxb-0 { flex-basis: 0px !important; }

.x-fxb-10 { flex-basis: 10px !important; }

.x-fxb-20 { flex-basis: 20px !important; }

.x-fxb-30 { flex-basis: 30px !important; }

.x-fxb-40 { flex-basis: 40px !important; }

.x-fxb-50 { flex-basis: 50px !important; }

.x-fxb-60 { flex-basis: 60px !important; }

.x-fxb-70 { flex-basis: 70px !important; }

.x-fxb-80 { flex-basis: 80px !important; }

.x-fxb-90 { flex-basis: 90px !important; }

.x-fxb-100 { flex-basis: 100px !important; }

.x-fxb-110 { flex-basis: 110px !important; }

.x-fxb-120 { flex-basis: 120px !important; }

.x-fxb-130 { flex-basis: 130px !important; }

.x-fxb-140 { flex-basis: 140px !important; }

.x-fxb-150 { flex-basis: 150px !important; }

.x-fxb-160 { flex-basis: 160px !important; }

.x-fxb-170 { flex-basis: 170px !important; }

.x-fxb-180 { flex-basis: 180px !important; }

.x-fxb-190 { flex-basis: 190px !important; }

.x-fxb-200 { flex-basis: 200px !important; }

.x-fxb-210 { flex-basis: 210px !important; }

.x-fxb-220 { flex-basis: 220px !important; }

.x-fxb-230 { flex-basis: 230px !important; }

.x-fxb-240 { flex-basis: 240px !important; }

.x-fxb-250 { flex-basis: 250px !important; }

/** flex-direction Emmet abbreviation: fxd */
.x-fxd-r { flex-direction: row !important; }

.x-fxd-rr { flex-direction: row-reverse !important; }

.x-fxd-c { flex-direction: column !important; }

.x-fxd-cr { flex-direction: column-reverse !important; }

/** flex-grow Emmet abbreviation: fxg */
.x-fxg-0 { flex-grow: 0 !important; }

.x-fxg-1 { flex-grow: 1 !important; }

.x-fxg-2 { flex-grow: 2 !important; }

.x-fxg-3 { flex-grow: 3 !important; }

.x-fxg-4 { flex-grow: 4 !important; }

.x-fxg-5 { flex-grow: 5 !important; }

.x-fxg-6 { flex-grow: 6 !important; }

.x-fxg-7 { flex-grow: 7 !important; }

.x-fxg-8 { flex-grow: 8 !important; }

.x-fxg-9 { flex-grow: 9 !important; }

.x-fxg-10 { flex-grow: 10 !important; }

/** flex-shrink Emmet abbreviation: fxsh */
.x-fxsh-0 { flex-shrink: 0 !important; }

.x-fxsh-1 { flex-shrink: 1 !important; }

.x-fxsh-2 { flex-shrink: 2 !important; }

.x-fxsh-3 { flex-shrink: 3 !important; }

.x-fxsh-4 { flex-shrink: 4 !important; }

.x-fxsh-5 { flex-shrink: 5 !important; }

.x-fxsh-6 { flex-shrink: 6 !important; }

.x-fxsh-7 { flex-shrink: 7 !important; }

.x-fxsh-8 { flex-shrink: 8 !important; }

.x-fxsh-9 { flex-shrink: 9 !important; }

.x-fxsh-10 { flex-shrink: 10 !important; }

/** flex-wrap Emmet abbreviation: fxw */
.x-fxw-n { flex-wrap: nowrap !important; }

.x-fxw-w { flex-wrap: wrap !important; }

.x-fxw-wr { flex-wrap: wrap-reverse !important; }

/** justify-content Emmet abbreviation: jc */
.x-jc-c { justify-content: center !important; }

.x-jc-fe { justify-content: flex-end !important; }

.x-jc-fs { justify-content: flex-start !important; }

.x-jc-sa { justify-content: space-around !important; }

.x-jc-sb { justify-content: space-between !important; }

/** order Emmet abbreviation: ord */
.x-ord-0 { order: 0 !important; }

.x-ord-1 { order: 1 !important; }

.x-ord-2 { order: 2 !important; }

.x-ord-3 { order: 3 !important; }

.x-ord-4 { order: 4 !important; }

.x-ord-5 { order: 5 !important; }

.x-ord-6 { order: 6 !important; }

.x-ord-7 { order: 7 !important; }

.x-ord-8 { order: 8 !important; }

.x-ord-9 { order: 9 !important; }

.x-ord-10 { order: 10 !important; }

/** orientation Emmet abbreviation: ori */
.x-ori-l { orientation: landscape !important; }

.x-ori-p { orientation: portrait !important; }

/** writing-mode Emmet abbreviation: wm */
.x-wm-btl { writing-mode: bt-lr !important; }

.x-wm-btr { writing-mode: bt-rl !important; }

.x-wm-lrb { writing-mode: lr-bt !important; }

.x-wm-lrt { writing-mode: lr-tb !important; }

.x-wm-rlb { writing-mode: rl-bt !important; }

.x-wm-rlt { writing-mode: rl-tb !important; }

.x-wm-tbl { writing-mode: tb-lr !important; }

.x-wm-tbr { writing-mode: tb-rl !important; }

/** direction Emmet abbreviation: dr */
.x-dr-ltr { direction: ltr !important; }

.x-dr-rtl { direction: rtl !important; }

/*# sourceMappingURL=x-framework.css.map */
